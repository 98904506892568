@import url(https://fonts.googleapis.com/css?family=Poppins:400,400i,600,600i|Roboto:400,400i,700,700i);
:root {
  --blue: #06f;
  --indigo: #6610f2;
  --purple: #6438DE;
  --pink: #e83e8c;
  --red: #F43946;
  --orange: #fd7e14;
  --yellow: #FCA104;
  --green: #2BCA50;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #76818C;
  --gray-dark: #2D3748;
  --blue-faded: #CCE0FF;
  --blue-2: #1045DB;
  --blue-2-faded: #CFDAF8;
  --blue-3: #051594;
  --blue-3-faded: #CDD0EA;
  --blue-4: #0C095E;
  --blue-4-faded: #CECEDF;
  --purple-faded: #E0D7F8;
  --red-faded: #FDD7DA;
  --yellow-faded: #FEECCD;
  --green-faded: #D5F4DC;
  --gray-faded: #E4E6E8;
  --primary: #06f;
  --secondary: #76818C;
  --success: #2BCA50;
  --info: #06f;
  --warning: #FCA104;
  --danger: #F43946;
  --light: #EDF2F7;
  --dark: #2D3748;
  --primary-opacity-25: rgba(0,102,255,0.25);
  --primary-opacity-50: rgba(0,102,255,0.5);
  --primary-opacity-75: rgba(0,102,255,0.75);
  --primary-faded: #CCE0FF;
  --secondary-opacity-25: rgba(118,129,140,0.25);
  --secondary-opacity-50: rgba(118,129,140,0.5);
  --secondary-opacity-75: rgba(118,129,140,0.75);
  --success-opacity-25: rgba(43,202,80,0.25);
  --success-opacity-50: rgba(43,202,80,0.5);
  --success-opacity-75: rgba(43,202,80,0.75);
  --success-faded: #D5F4DC;
  --info-opacity-25: rgba(0,102,255,0.25);
  --info-opacity-50: rgba(0,102,255,0.5);
  --info-opacity-75: rgba(0,102,255,0.75);
  --info-faded: #CCE0FF;
  --warning-opacity-25: rgba(252,161,4,0.25);
  --warning-opacity-50: rgba(252,161,4,0.5);
  --warning-opacity-75: rgba(252,161,4,0.75);
  --warning-faded: #FEECCD;
  --danger-opacity-25: rgba(244,57,70,0.25);
  --danger-opacity-50: rgba(244,57,70,0.5);
  --danger-opacity-75: rgba(244,57,70,0.75);
  --danger-faded: #FDD7DA;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: "Roboto", sans-serif;
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

body {
  margin: 0;
  font-family: "Roboto",sans-serif;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.556;
  color: #4A5568;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: .5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
          text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: #06f;
  text-decoration: none;
  background-color: transparent;
}

a:hover {
  color: #0047b3;
  text-decoration: underline;
}

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: .75rem;
  padding-bottom: .75rem;
  color: #718096;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

label {
  display: inline-block;
  margin-bottom: .5rem;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

@media (max-width: 1200px) {
  legend {
    font-size: calc(1.275rem + .3vw);
  }
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: .5rem;
  font-family: "Poppins",sans-serif;
  font-weight: 600;
  line-height: 1.2;
  color: #1A202C;
}

h1,
.h1 {
  font-size: 3.00038rem;
}

@media (max-width: 1200px) {
  h1,
  .h1 {
    font-size: calc(1.42504rem + 2.10045vw);
  }
}

h2,
.h2 {
  font-size: 2.00025rem;
}

@media (max-width: 1200px) {
  h2,
  .h2 {
    font-size: calc(1.32502rem + .9003vw);
  }
}

h3,
.h3 {
  font-size: 1.7505rem;
}

@media (max-width: 1200px) {
  h3,
  .h3 {
    font-size: calc(1.30005rem + .6006vw);
  }
}

h4,
.h4 {
  font-size: 1.49625rem;
}

@media (max-width: 1200px) {
  h4,
  .h4 {
    font-size: calc(1.27462rem + .2955vw);
  }
}

h5,
.h5 {
  font-size: 1.24875rem;
}

h6,
.h6 {
  font-size: 1.125rem;
}

.lead {
  font-size: 1.40625rem;
  font-weight: 300;
}

@media (max-width: 1200px) {
  .lead {
    font-size: calc(1.26562rem + .1875vw);
  }
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

@media (max-width: 1200px) {
  .display-1 {
    font-size: calc(1.725rem + 5.7vw);
  }
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

@media (max-width: 1200px) {
  .display-2 {
    font-size: calc(1.675rem + 5.1vw);
  }
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

@media (max-width: 1200px) {
  .display-3 {
    font-size: calc(1.575rem + 3.9vw);
  }
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

@media (max-width: 1200px) {
  .display-4 {
    font-size: calc(1.475rem + 2.7vw);
  }
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0,0,0,0.1);
}

small,
.small {
  font-size: 80%;
  font-weight: 400;
}

mark,
.mark {
  padding: .2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: .5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.40625rem;
}

@media (max-width: 1200px) {
  .blockquote {
    font-size: calc(1.26562rem + .1875vw);
  }
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #718096;
}

.blockquote-footer::before {
  content: "\2014\A0";
}

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container,
  .container-sm {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container,
  .container-sm,
  .container-md {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    max-width: 1140px;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters>.col,
.no-gutters>[class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col,
.col-auto,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-sm-auto,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md,
.col-md-auto,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg,
.col-lg-auto,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1>* {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2>* {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3>* {
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

.row-cols-4>* {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5>* {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6>* {
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%;
}

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%;
}

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%;
}

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.66667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333%;
}

.offset-5 {
  margin-left: 41.66667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333%;
}

.offset-8 {
  margin-left: 66.66667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333%;
}

.offset-11 {
  margin-left: 91.66667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-sm-1>* {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-sm-2>* {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-sm-3>* {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .row-cols-sm-4>* {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-sm-5>* {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-sm-6>* {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }

  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }

  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }

  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }

  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }

  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-sm-first {
    order: -1;
  }

  .order-sm-last {
    order: 13;
  }

  .order-sm-0 {
    order: 0;
  }

  .order-sm-1 {
    order: 1;
  }

  .order-sm-2 {
    order: 2;
  }

  .order-sm-3 {
    order: 3;
  }

  .order-sm-4 {
    order: 4;
  }

  .order-sm-5 {
    order: 5;
  }

  .order-sm-6 {
    order: 6;
  }

  .order-sm-7 {
    order: 7;
  }

  .order-sm-8 {
    order: 8;
  }

  .order-sm-9 {
    order: 9;
  }

  .order-sm-10 {
    order: 10;
  }

  .order-sm-11 {
    order: 11;
  }

  .order-sm-12 {
    order: 12;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333%;
  }

  .offset-sm-2 {
    margin-left: 16.66667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.33333%;
  }

  .offset-sm-5 {
    margin-left: 41.66667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.33333%;
  }

  .offset-sm-8 {
    margin-left: 66.66667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.33333%;
  }

  .offset-sm-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-md-1>* {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-md-2>* {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-md-3>* {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .row-cols-md-4>* {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-md-5>* {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-md-6>* {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }

  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }

  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }

  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }

  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }

  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-md-first {
    order: -1;
  }

  .order-md-last {
    order: 13;
  }

  .order-md-0 {
    order: 0;
  }

  .order-md-1 {
    order: 1;
  }

  .order-md-2 {
    order: 2;
  }

  .order-md-3 {
    order: 3;
  }

  .order-md-4 {
    order: 4;
  }

  .order-md-5 {
    order: 5;
  }

  .order-md-6 {
    order: 6;
  }

  .order-md-7 {
    order: 7;
  }

  .order-md-8 {
    order: 8;
  }

  .order-md-9 {
    order: 9;
  }

  .order-md-10 {
    order: 10;
  }

  .order-md-11 {
    order: 11;
  }

  .order-md-12 {
    order: 12;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333%;
  }

  .offset-md-2 {
    margin-left: 16.66667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.33333%;
  }

  .offset-md-5 {
    margin-left: 41.66667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.33333%;
  }

  .offset-md-8 {
    margin-left: 66.66667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.33333%;
  }

  .offset-md-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-lg-1>* {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-lg-2>* {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-lg-3>* {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .row-cols-lg-4>* {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-lg-5>* {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-lg-6>* {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }

  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }

  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }

  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }

  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }

  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-lg-first {
    order: -1;
  }

  .order-lg-last {
    order: 13;
  }

  .order-lg-0 {
    order: 0;
  }

  .order-lg-1 {
    order: 1;
  }

  .order-lg-2 {
    order: 2;
  }

  .order-lg-3 {
    order: 3;
  }

  .order-lg-4 {
    order: 4;
  }

  .order-lg-5 {
    order: 5;
  }

  .order-lg-6 {
    order: 6;
  }

  .order-lg-7 {
    order: 7;
  }

  .order-lg-8 {
    order: 8;
  }

  .order-lg-9 {
    order: 9;
  }

  .order-lg-10 {
    order: 10;
  }

  .order-lg-11 {
    order: 11;
  }

  .order-lg-12 {
    order: 12;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333%;
  }

  .offset-lg-2 {
    margin-left: 16.66667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.33333%;
  }

  .offset-lg-5 {
    margin-left: 41.66667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.33333%;
  }

  .offset-lg-8 {
    margin-left: 66.66667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.33333%;
  }

  .offset-lg-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-xl-1>* {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-xl-2>* {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-xl-3>* {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .row-cols-xl-4>* {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-xl-5>* {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-xl-6>* {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }

  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }

  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }

  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }

  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }

  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-xl-first {
    order: -1;
  }

  .order-xl-last {
    order: 13;
  }

  .order-xl-0 {
    order: 0;
  }

  .order-xl-1 {
    order: 1;
  }

  .order-xl-2 {
    order: 2;
  }

  .order-xl-3 {
    order: 3;
  }

  .order-xl-4 {
    order: 4;
  }

  .order-xl-5 {
    order: 5;
  }

  .order-xl-6 {
    order: 6;
  }

  .order-xl-7 {
    order: 7;
  }

  .order-xl-8 {
    order: 8;
  }

  .order-xl-9 {
    order: 9;
  }

  .order-xl-10 {
    order: 10;
  }

  .order-xl-11 {
    order: 11;
  }

  .order-xl-12 {
    order: 12;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333%;
  }

  .offset-xl-2 {
    margin-left: 16.66667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.33333%;
  }

  .offset-xl-5 {
    margin-left: 41.66667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.33333%;
  }

  .offset-xl-8 {
    margin-left: 66.66667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.33333%;
  }

  .offset-xl-11 {
    margin-left: 91.66667%;
  }
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.556em + .75rem + 2px);
  padding: .375rem .75rem;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.556;
  color: #4A5568;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #CBD5E0;
  border-radius: .375rem;
  box-shadow: inset 0 1px 1px rgba(0,0,0,0.075);
  transition: border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}

.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #4A5568;
}

.form-control:focus {
  color: #4A5568;
  background-color: #fff;
  border-color: #80b3ff;
  outline: 0;
  box-shadow: inset 0 1px 1px rgba(0,0,0,0.075),0 0 0 0 rgba(0,102,255,0.25);
}

.form-control:-ms-input-placeholder {
  color: #718096;
  opacity: 1;
}

.form-control::placeholder {
  color: #718096;
  opacity: 1;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #EDF2F7;
  opacity: 1;
}

input[type="date"].form-control,
input[type="time"].form-control,
input[type="datetime-local"].form-control,
input[type="month"].form-control {
  -webkit-appearance: none;
          appearance: none;
}

select.form-control:focus::-ms-value {
  color: #4A5568;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(.375rem + 1px);
  padding-bottom: calc(.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.556;
}

.col-form-label-lg {
  padding-top: calc(.5rem + 1px);
  padding-bottom: calc(.5rem + 1px);
  font-size: 1.24988rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(.25rem + 1px);
  padding-bottom: calc(.25rem + 1px);
  font-size: 1.00012rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: .375rem 0;
  margin-bottom: 0;
  font-size: 1.125rem;
  line-height: 1.556;
  color: #4A5568;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}

.form-control-plaintext.form-control-sm,
.form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.5em + .5rem + 2px);
  padding: .25rem .5rem;
  font-size: 1.00012rem;
  line-height: 1.5;
  border-radius: .1875rem;
}

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: .5rem 1rem;
  font-size: 1.24988rem;
  line-height: 1.5;
  border-radius: .75rem;
}

select.form-control[size],
select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: .25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.form-row>.col,
.form-row>[class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: .3rem;
  margin-left: -1.25rem;
}

.form-check-input[disabled] ~ .form-check-label,
.form-check-input:disabled ~ .form-check-label {
  color: #718096;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: .75rem;
}

.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: .3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: .25rem;
  font-size: 80%;
  color: #2BCA50;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: .25rem .5rem;
  margin-top: .1rem;
  font-size: 1.00012rem;
  line-height: 1.556;
  color: #fff;
  background-color: rgba(43,202,80,0.9);
  border-radius: .375rem;
}

.form-row>.col>.valid-tooltip,
.form-row>[class*="col-"]>.valid-tooltip {
  left: 5px;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid,
.form-control.is-valid {
  border-color: #2BCA50;
}

.was-validated .form-control:valid:focus,
.form-control.is-valid:focus {
  border-color: #2BCA50;
  box-shadow: 0 0 0 0 rgba(43,202,80,0.25);
}

.was-validated .custom-select:valid,
.custom-select.is-valid {
  border-color: #2BCA50;
}

.was-validated .custom-select:valid:focus,
.custom-select.is-valid:focus {
  border-color: #2BCA50;
  box-shadow: 0 0 0 0 rgba(43,202,80,0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label,
.form-check-input.is-valid ~ .form-check-label {
  color: #2BCA50;
}

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip,
.form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label,
.custom-control-input.is-valid ~ .custom-control-label {
  color: #2BCA50;
}

.was-validated .custom-control-input:valid ~ .custom-control-label::before,
.custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #2BCA50;
}

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before,
.custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #4fd96f;
  background-color: #4fd96f;
}

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before,
.custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0 rgba(43,202,80,0.25);
}

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before,
.custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #2BCA50;
}

.was-validated .custom-file-input:valid ~ .custom-file-label,
.custom-file-input.is-valid ~ .custom-file-label {
  border-color: #2BCA50;
}

.was-validated .custom-file-input:valid:focus ~ .custom-file-label,
.custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #2BCA50;
  box-shadow: 0 0 0 0 rgba(43,202,80,0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: .25rem;
  font-size: 80%;
  color: #F43946;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: .25rem .5rem;
  margin-top: .1rem;
  font-size: 1.00012rem;
  line-height: 1.556;
  color: #fff;
  background-color: rgba(244,57,70,0.9);
  border-radius: .375rem;
}

.form-row>.col>.invalid-tooltip,
.form-row>[class*="col-"]>.invalid-tooltip {
  left: 5px;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
  border-color: #F43946;
}

.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus {
  border-color: #F43946;
  box-shadow: 0 0 0 0 rgba(244,57,70,0.25);
}

.was-validated .custom-select:invalid,
.custom-select.is-invalid {
  border-color: #F43946;
}

.was-validated .custom-select:invalid:focus,
.custom-select.is-invalid:focus {
  border-color: #F43946;
  box-shadow: 0 0 0 0 rgba(244,57,70,0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label,
.form-check-input.is-invalid ~ .form-check-label {
  color: #F43946;
}

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip,
.form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label,
.custom-control-input.is-invalid ~ .custom-control-label {
  color: #F43946;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label::before,
.custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #F43946;
}

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before,
.custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #f76973;
  background-color: #f76973;
}

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before,
.custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0 rgba(244,57,70,0.25);
}

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before,
.custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #F43946;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label,
.custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #F43946;
}

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label,
.custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #F43946;
  box-shadow: 0 0 0 0 rgba(244,57,70,0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.form-inline .form-check {
  width: 100%;
}

@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }

  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }

  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }

  .form-inline .form-control-plaintext {
    display: inline-block;
  }

  .form-inline .input-group,
  .form-inline .custom-select {
    width: auto;
  }

  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }

  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: .25rem;
    margin-left: 0;
  }

  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }

  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-weight: 400;
  color: #4A5568;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: .375rem .75rem;
  font-size: 1.125rem;
  line-height: 1.556;
  border-radius: .25rem;
  transition: color 0.15s ease-in-out,background-color 0.15s ease-in-out,border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:hover {
  color: #4A5568;
  text-decoration: none;
}

.btn:focus,
.btn.focus {
  outline: 0;
  box-shadow: 0 5px 8px rgba(74,85,104,0.11),0 2px 4px rgba(0,0,0,0.08);
}

.btn.disabled,
.btn:disabled {
  opacity: .65;
  box-shadow: none;
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.btn:not(:disabled):not(.disabled):active,
.btn:not(:disabled):not(.disabled).active {
  box-shadow: 0 2px 3px rgba(74,85,104,0.11);
}

.btn:not(:disabled):not(.disabled):active:focus,
.btn:not(:disabled):not(.disabled).active:focus {
  box-shadow: 0 5px 8px rgba(74,85,104,0.11),0 2px 4px rgba(0,0,0,0.08),0 2px 3px rgba(74,85,104,0.11);
}

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #06f;
  border-color: #06f;
  box-shadow: 0 4px 6px rgba(74,85,104,0.11),0 1px 3px rgba(0,0,0,0.08);
}

.btn-primary:hover {
  color: #fff;
  background-color: #0057d9;
  box-shadow: 0 7px 14px rgba(74,85,104,0.1),0 3px 6px rgba(0,0,0,0.08);
  border-color: #0052cc;
}

.btn-primary:focus,
.btn-primary.focus {
  box-shadow: 0 5px 8px rgba(74,85,104,0.11),0 2px 4px rgba(0,0,0,0.08);
}

.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: #06f;
  border-color: #06f;
}

.btn-primary.disabled:hover,
.btn-primary:disabled:hover {
  box-shadow: none;
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show>.btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0052cc;
  border-color: #004dbf;
}

.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show>.btn-primary.dropdown-toggle:focus {
  box-shadow: 0 2px 3px rgba(74,85,104,0.11);
}

.btn-secondary {
  color: #fff;
  background-color: #76818C;
  border-color: #76818C;
  box-shadow: 0 4px 6px rgba(74,85,104,0.11),0 1px 3px rgba(0,0,0,0.08);
}

.btn-secondary:hover {
  color: #fff;
  background-color: #646e77;
  box-shadow: 0 7px 14px rgba(74,85,104,0.1),0 3px 6px rgba(0,0,0,0.08);
  border-color: #5e6871;
}

.btn-secondary:focus,
.btn-secondary.focus {
  box-shadow: 0 5px 8px rgba(74,85,104,0.11),0 2px 4px rgba(0,0,0,0.08);
}

.btn-secondary.disabled,
.btn-secondary:disabled {
  color: #fff;
  background-color: #76818C;
  border-color: #76818C;
}

.btn-secondary.disabled:hover,
.btn-secondary:disabled:hover {
  box-shadow: none;
}

.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show>.btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #5e6871;
  border-color: #59616a;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.show>.btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 2px 3px rgba(74,85,104,0.11);
}

.btn-success {
  color: #fff;
  background-color: #2BCA50;
  border-color: #2BCA50;
  box-shadow: 0 4px 6px rgba(74,85,104,0.11),0 1px 3px rgba(0,0,0,0.08);
}

.btn-success:hover {
  color: #fff;
  background-color: #24aa44;
  box-shadow: 0 7px 14px rgba(74,85,104,0.1),0 3px 6px rgba(0,0,0,0.08);
  border-color: #22a03f;
}

.btn-success:focus,
.btn-success.focus {
  box-shadow: 0 5px 8px rgba(74,85,104,0.11),0 2px 4px rgba(0,0,0,0.08);
}

.btn-success.disabled,
.btn-success:disabled {
  color: #fff;
  background-color: #2BCA50;
  border-color: #2BCA50;
}

.btn-success.disabled:hover,
.btn-success:disabled:hover {
  box-shadow: none;
}

.btn-success:not(:disabled):not(.disabled):active,
.btn-success:not(:disabled):not(.disabled).active,
.show>.btn-success.dropdown-toggle {
  color: #fff;
  background-color: #22a03f;
  border-color: #20953b;
}

.btn-success:not(:disabled):not(.disabled):active:focus,
.btn-success:not(:disabled):not(.disabled).active:focus,
.show>.btn-success.dropdown-toggle:focus {
  box-shadow: 0 2px 3px rgba(74,85,104,0.11);
}

.btn-info {
  color: #fff;
  background-color: #06f;
  border-color: #06f;
  box-shadow: 0 4px 6px rgba(74,85,104,0.11),0 1px 3px rgba(0,0,0,0.08);
}

.btn-info:hover {
  color: #fff;
  background-color: #0057d9;
  box-shadow: 0 7px 14px rgba(74,85,104,0.1),0 3px 6px rgba(0,0,0,0.08);
  border-color: #0052cc;
}

.btn-info:focus,
.btn-info.focus {
  box-shadow: 0 5px 8px rgba(74,85,104,0.11),0 2px 4px rgba(0,0,0,0.08);
}

.btn-info.disabled,
.btn-info:disabled {
  color: #fff;
  background-color: #06f;
  border-color: #06f;
}

.btn-info.disabled:hover,
.btn-info:disabled:hover {
  box-shadow: none;
}

.btn-info:not(:disabled):not(.disabled):active,
.btn-info:not(:disabled):not(.disabled).active,
.show>.btn-info.dropdown-toggle {
  color: #fff;
  background-color: #0052cc;
  border-color: #004dbf;
}

.btn-info:not(:disabled):not(.disabled):active:focus,
.btn-info:not(:disabled):not(.disabled).active:focus,
.show>.btn-info.dropdown-toggle:focus {
  box-shadow: 0 2px 3px rgba(74,85,104,0.11);
}

.btn-warning {
  color: #fff;
  background-color: #FCA104;
  border-color: #FCA104;
  box-shadow: 0 4px 6px rgba(74,85,104,0.11),0 1px 3px rgba(0,0,0,0.08);
}

.btn-warning:hover {
  color: #fff;
  background-color: #d78903;
  box-shadow: 0 7px 14px rgba(74,85,104,0.1),0 3px 6px rgba(0,0,0,0.08);
  border-color: #cb8102;
}

.btn-warning:focus,
.btn-warning.focus {
  box-shadow: 0 5px 8px rgba(74,85,104,0.11),0 2px 4px rgba(0,0,0,0.08);
}

.btn-warning.disabled,
.btn-warning:disabled {
  color: #fff;
  background-color: #FCA104;
  border-color: #FCA104;
}

.btn-warning.disabled:hover,
.btn-warning:disabled:hover {
  box-shadow: none;
}

.btn-warning:not(:disabled):not(.disabled):active,
.btn-warning:not(:disabled):not(.disabled).active,
.show>.btn-warning.dropdown-toggle {
  color: #fff;
  background-color: #cb8102;
  border-color: #be7902;
}

.btn-warning:not(:disabled):not(.disabled):active:focus,
.btn-warning:not(:disabled):not(.disabled).active:focus,
.show>.btn-warning.dropdown-toggle:focus {
  box-shadow: 0 2px 3px rgba(74,85,104,0.11);
}

.btn-danger {
  color: #fff;
  background-color: #F43946;
  border-color: #F43946;
  box-shadow: 0 4px 6px rgba(74,85,104,0.11),0 1px 3px rgba(0,0,0,0.08);
}

.btn-danger:hover {
  color: #fff;
  background-color: #f21524;
  box-shadow: 0 7px 14px rgba(74,85,104,0.1),0 3px 6px rgba(0,0,0,0.08);
  border-color: #ed0d1d;
}

.btn-danger:focus,
.btn-danger.focus {
  box-shadow: 0 5px 8px rgba(74,85,104,0.11),0 2px 4px rgba(0,0,0,0.08);
}

.btn-danger.disabled,
.btn-danger:disabled {
  color: #fff;
  background-color: #F43946;
  border-color: #F43946;
}

.btn-danger.disabled:hover,
.btn-danger:disabled:hover {
  box-shadow: none;
}

.btn-danger:not(:disabled):not(.disabled):active,
.btn-danger:not(:disabled):not(.disabled).active,
.show>.btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #ed0d1d;
  border-color: #e10c1b;
}

.btn-danger:not(:disabled):not(.disabled):active:focus,
.btn-danger:not(:disabled):not(.disabled).active:focus,
.show>.btn-danger.dropdown-toggle:focus {
  box-shadow: 0 2px 3px rgba(74,85,104,0.11);
}

.btn-light {
  color: #1A202C;
  background-color: #EDF2F7;
  border-color: #EDF2F7;
  box-shadow: 0 4px 6px rgba(74,85,104,0.11),0 1px 3px rgba(0,0,0,0.08);
}

.btn-light:hover {
  color: #1A202C;
  background-color: #d3dfeb;
  box-shadow: 0 7px 14px rgba(74,85,104,0.1),0 3px 6px rgba(0,0,0,0.08);
  border-color: #cad9e7;
}

.btn-light:focus,
.btn-light.focus {
  box-shadow: 0 5px 8px rgba(74,85,104,0.11),0 2px 4px rgba(0,0,0,0.08);
}

.btn-light.disabled,
.btn-light:disabled {
  color: #1A202C;
  background-color: #EDF2F7;
  border-color: #EDF2F7;
}

.btn-light.disabled:hover,
.btn-light:disabled:hover {
  box-shadow: none;
}

.btn-light:not(:disabled):not(.disabled):active,
.btn-light:not(:disabled):not(.disabled).active,
.show>.btn-light.dropdown-toggle {
  color: #1A202C;
  background-color: #cad9e7;
  border-color: #c1d2e3;
}

.btn-light:not(:disabled):not(.disabled):active:focus,
.btn-light:not(:disabled):not(.disabled).active:focus,
.show>.btn-light.dropdown-toggle:focus {
  box-shadow: 0 2px 3px rgba(74,85,104,0.11);
}

.btn-dark {
  color: #fff;
  background-color: #2D3748;
  border-color: #2D3748;
  box-shadow: 0 4px 6px rgba(74,85,104,0.11),0 1px 3px rgba(0,0,0,0.08);
}

.btn-dark:hover {
  color: #fff;
  background-color: #1e2530;
  box-shadow: 0 7px 14px rgba(74,85,104,0.1),0 3px 6px rgba(0,0,0,0.08);
  border-color: #191f29;
}

.btn-dark:focus,
.btn-dark.focus {
  box-shadow: 0 5px 8px rgba(74,85,104,0.11),0 2px 4px rgba(0,0,0,0.08);
}

.btn-dark.disabled,
.btn-dark:disabled {
  color: #fff;
  background-color: #2D3748;
  border-color: #2D3748;
}

.btn-dark.disabled:hover,
.btn-dark:disabled:hover {
  box-shadow: none;
}

.btn-dark:not(:disabled):not(.disabled):active,
.btn-dark:not(:disabled):not(.disabled).active,
.show>.btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #191f29;
  border-color: #141921;
}

.btn-dark:not(:disabled):not(.disabled):active:focus,
.btn-dark:not(:disabled):not(.disabled).active:focus,
.show>.btn-dark.dropdown-toggle:focus {
  box-shadow: 0 2px 3px rgba(74,85,104,0.11);
}

.btn-primary-opacity-25 {
  color: #fff;
  background-color: rgba(0,102,255,0.25);
  border-color: rgba(0,102,255,0.25);
  box-shadow: 0 4px 6px rgba(74,85,104,0.11),0 1px 3px rgba(0,0,0,0.08);
}

.btn-primary-opacity-25:hover {
  color: #fff;
  background-color: rgba(0,87,217,0.25);
  box-shadow: 0 7px 14px rgba(74,85,104,0.1),0 3px 6px rgba(0,0,0,0.08);
  border-color: rgba(0,82,204,0.25);
}

.btn-primary-opacity-25:focus,
.btn-primary-opacity-25.focus {
  box-shadow: 0 5px 8px rgba(74,85,104,0.11),0 2px 4px rgba(0,0,0,0.08);
}

.btn-primary-opacity-25.disabled,
.btn-primary-opacity-25:disabled {
  color: #fff;
  background-color: rgba(0,102,255,0.25);
  border-color: rgba(0,102,255,0.25);
}

.btn-primary-opacity-25.disabled:hover,
.btn-primary-opacity-25:disabled:hover {
  box-shadow: none;
}

.btn-primary-opacity-25:not(:disabled):not(.disabled):active,
.btn-primary-opacity-25:not(:disabled):not(.disabled).active,
.show>.btn-primary-opacity-25.dropdown-toggle {
  color: #fff;
  background-color: rgba(0,82,204,0.25);
  border-color: rgba(0,77,191,0.25);
}

.btn-primary-opacity-25:not(:disabled):not(.disabled):active:focus,
.btn-primary-opacity-25:not(:disabled):not(.disabled).active:focus,
.show>.btn-primary-opacity-25.dropdown-toggle:focus {
  box-shadow: 0 2px 3px rgba(74,85,104,0.11);
}

.btn-primary-opacity-50 {
  color: #fff;
  background-color: rgba(0,102,255,0.5);
  border-color: rgba(0,102,255,0.5);
  box-shadow: 0 4px 6px rgba(74,85,104,0.11),0 1px 3px rgba(0,0,0,0.08);
}

.btn-primary-opacity-50:hover {
  color: #fff;
  background-color: rgba(0,87,217,0.5);
  box-shadow: 0 7px 14px rgba(74,85,104,0.1),0 3px 6px rgba(0,0,0,0.08);
  border-color: rgba(0,82,204,0.5);
}

.btn-primary-opacity-50:focus,
.btn-primary-opacity-50.focus {
  box-shadow: 0 5px 8px rgba(74,85,104,0.11),0 2px 4px rgba(0,0,0,0.08);
}

.btn-primary-opacity-50.disabled,
.btn-primary-opacity-50:disabled {
  color: #fff;
  background-color: rgba(0,102,255,0.5);
  border-color: rgba(0,102,255,0.5);
}

.btn-primary-opacity-50.disabled:hover,
.btn-primary-opacity-50:disabled:hover {
  box-shadow: none;
}

.btn-primary-opacity-50:not(:disabled):not(.disabled):active,
.btn-primary-opacity-50:not(:disabled):not(.disabled).active,
.show>.btn-primary-opacity-50.dropdown-toggle {
  color: #fff;
  background-color: rgba(0,82,204,0.5);
  border-color: rgba(0,77,191,0.5);
}

.btn-primary-opacity-50:not(:disabled):not(.disabled):active:focus,
.btn-primary-opacity-50:not(:disabled):not(.disabled).active:focus,
.show>.btn-primary-opacity-50.dropdown-toggle:focus {
  box-shadow: 0 2px 3px rgba(74,85,104,0.11);
}

.btn-primary-opacity-75 {
  color: #fff;
  background-color: rgba(0,102,255,0.75);
  border-color: rgba(0,102,255,0.75);
  box-shadow: 0 4px 6px rgba(74,85,104,0.11),0 1px 3px rgba(0,0,0,0.08);
}

.btn-primary-opacity-75:hover {
  color: #fff;
  background-color: rgba(0,87,217,0.75);
  box-shadow: 0 7px 14px rgba(74,85,104,0.1),0 3px 6px rgba(0,0,0,0.08);
  border-color: rgba(0,82,204,0.75);
}

.btn-primary-opacity-75:focus,
.btn-primary-opacity-75.focus {
  box-shadow: 0 5px 8px rgba(74,85,104,0.11),0 2px 4px rgba(0,0,0,0.08);
}

.btn-primary-opacity-75.disabled,
.btn-primary-opacity-75:disabled {
  color: #fff;
  background-color: rgba(0,102,255,0.75);
  border-color: rgba(0,102,255,0.75);
}

.btn-primary-opacity-75.disabled:hover,
.btn-primary-opacity-75:disabled:hover {
  box-shadow: none;
}

.btn-primary-opacity-75:not(:disabled):not(.disabled):active,
.btn-primary-opacity-75:not(:disabled):not(.disabled).active,
.show>.btn-primary-opacity-75.dropdown-toggle {
  color: #fff;
  background-color: rgba(0,82,204,0.75);
  border-color: rgba(0,77,191,0.75);
}

.btn-primary-opacity-75:not(:disabled):not(.disabled):active:focus,
.btn-primary-opacity-75:not(:disabled):not(.disabled).active:focus,
.show>.btn-primary-opacity-75.dropdown-toggle:focus {
  box-shadow: 0 2px 3px rgba(74,85,104,0.11);
}

.btn-primary-faded {
  color: #1A202C;
  background-color: #CCE0FF;
  border-color: #CCE0FF;
  box-shadow: 0 4px 6px rgba(74,85,104,0.11),0 1px 3px rgba(0,0,0,0.08);
}

.btn-primary-faded:hover {
  color: #1A202C;
  background-color: #a6c9ff;
  box-shadow: 0 7px 14px rgba(74,85,104,0.1),0 3px 6px rgba(0,0,0,0.08);
  border-color: #99c1ff;
}

.btn-primary-faded:focus,
.btn-primary-faded.focus {
  box-shadow: 0 5px 8px rgba(74,85,104,0.11),0 2px 4px rgba(0,0,0,0.08);
}

.btn-primary-faded.disabled,
.btn-primary-faded:disabled {
  color: #1A202C;
  background-color: #CCE0FF;
  border-color: #CCE0FF;
}

.btn-primary-faded.disabled:hover,
.btn-primary-faded:disabled:hover {
  box-shadow: none;
}

.btn-primary-faded:not(:disabled):not(.disabled):active,
.btn-primary-faded:not(:disabled):not(.disabled).active,
.show>.btn-primary-faded.dropdown-toggle {
  color: #1A202C;
  background-color: #99c1ff;
  border-color: #8cb9ff;
}

.btn-primary-faded:not(:disabled):not(.disabled):active:focus,
.btn-primary-faded:not(:disabled):not(.disabled).active:focus,
.show>.btn-primary-faded.dropdown-toggle:focus {
  box-shadow: 0 2px 3px rgba(74,85,104,0.11);
}

.btn-secondary-opacity-25 {
  color: #fff;
  background-color: rgba(118,129,140,0.25);
  border-color: rgba(118,129,140,0.25);
  box-shadow: 0 4px 6px rgba(74,85,104,0.11),0 1px 3px rgba(0,0,0,0.08);
}

.btn-secondary-opacity-25:hover {
  color: #fff;
  background-color: rgba(100,110,119,0.25);
  box-shadow: 0 7px 14px rgba(74,85,104,0.1),0 3px 6px rgba(0,0,0,0.08);
  border-color: rgba(94,104,113,0.25);
}

.btn-secondary-opacity-25:focus,
.btn-secondary-opacity-25.focus {
  box-shadow: 0 5px 8px rgba(74,85,104,0.11),0 2px 4px rgba(0,0,0,0.08);
}

.btn-secondary-opacity-25.disabled,
.btn-secondary-opacity-25:disabled {
  color: #fff;
  background-color: rgba(118,129,140,0.25);
  border-color: rgba(118,129,140,0.25);
}

.btn-secondary-opacity-25.disabled:hover,
.btn-secondary-opacity-25:disabled:hover {
  box-shadow: none;
}

.btn-secondary-opacity-25:not(:disabled):not(.disabled):active,
.btn-secondary-opacity-25:not(:disabled):not(.disabled).active,
.show>.btn-secondary-opacity-25.dropdown-toggle {
  color: #fff;
  background-color: rgba(94,104,113,0.25);
  border-color: rgba(89,97,106,0.25);
}

.btn-secondary-opacity-25:not(:disabled):not(.disabled):active:focus,
.btn-secondary-opacity-25:not(:disabled):not(.disabled).active:focus,
.show>.btn-secondary-opacity-25.dropdown-toggle:focus {
  box-shadow: 0 2px 3px rgba(74,85,104,0.11);
}

.btn-secondary-opacity-50 {
  color: #fff;
  background-color: rgba(118,129,140,0.5);
  border-color: rgba(118,129,140,0.5);
  box-shadow: 0 4px 6px rgba(74,85,104,0.11),0 1px 3px rgba(0,0,0,0.08);
}

.btn-secondary-opacity-50:hover {
  color: #fff;
  background-color: rgba(100,110,119,0.5);
  box-shadow: 0 7px 14px rgba(74,85,104,0.1),0 3px 6px rgba(0,0,0,0.08);
  border-color: rgba(94,104,113,0.5);
}

.btn-secondary-opacity-50:focus,
.btn-secondary-opacity-50.focus {
  box-shadow: 0 5px 8px rgba(74,85,104,0.11),0 2px 4px rgba(0,0,0,0.08);
}

.btn-secondary-opacity-50.disabled,
.btn-secondary-opacity-50:disabled {
  color: #fff;
  background-color: rgba(118,129,140,0.5);
  border-color: rgba(118,129,140,0.5);
}

.btn-secondary-opacity-50.disabled:hover,
.btn-secondary-opacity-50:disabled:hover {
  box-shadow: none;
}

.btn-secondary-opacity-50:not(:disabled):not(.disabled):active,
.btn-secondary-opacity-50:not(:disabled):not(.disabled).active,
.show>.btn-secondary-opacity-50.dropdown-toggle {
  color: #fff;
  background-color: rgba(94,104,113,0.5);
  border-color: rgba(89,97,106,0.5);
}

.btn-secondary-opacity-50:not(:disabled):not(.disabled):active:focus,
.btn-secondary-opacity-50:not(:disabled):not(.disabled).active:focus,
.show>.btn-secondary-opacity-50.dropdown-toggle:focus {
  box-shadow: 0 2px 3px rgba(74,85,104,0.11);
}

.btn-secondary-opacity-75 {
  color: #fff;
  background-color: rgba(118,129,140,0.75);
  border-color: rgba(118,129,140,0.75);
  box-shadow: 0 4px 6px rgba(74,85,104,0.11),0 1px 3px rgba(0,0,0,0.08);
}

.btn-secondary-opacity-75:hover {
  color: #fff;
  background-color: rgba(100,110,119,0.75);
  box-shadow: 0 7px 14px rgba(74,85,104,0.1),0 3px 6px rgba(0,0,0,0.08);
  border-color: rgba(94,104,113,0.75);
}

.btn-secondary-opacity-75:focus,
.btn-secondary-opacity-75.focus {
  box-shadow: 0 5px 8px rgba(74,85,104,0.11),0 2px 4px rgba(0,0,0,0.08);
}

.btn-secondary-opacity-75.disabled,
.btn-secondary-opacity-75:disabled {
  color: #fff;
  background-color: rgba(118,129,140,0.75);
  border-color: rgba(118,129,140,0.75);
}

.btn-secondary-opacity-75.disabled:hover,
.btn-secondary-opacity-75:disabled:hover {
  box-shadow: none;
}

.btn-secondary-opacity-75:not(:disabled):not(.disabled):active,
.btn-secondary-opacity-75:not(:disabled):not(.disabled).active,
.show>.btn-secondary-opacity-75.dropdown-toggle {
  color: #fff;
  background-color: rgba(94,104,113,0.75);
  border-color: rgba(89,97,106,0.75);
}

.btn-secondary-opacity-75:not(:disabled):not(.disabled):active:focus,
.btn-secondary-opacity-75:not(:disabled):not(.disabled).active:focus,
.show>.btn-secondary-opacity-75.dropdown-toggle:focus {
  box-shadow: 0 2px 3px rgba(74,85,104,0.11);
}

.btn-success-opacity-25 {
  color: #fff;
  background-color: rgba(43,202,80,0.25);
  border-color: rgba(43,202,80,0.25);
  box-shadow: 0 4px 6px rgba(74,85,104,0.11),0 1px 3px rgba(0,0,0,0.08);
}

.btn-success-opacity-25:hover {
  color: #fff;
  background-color: rgba(36,170,68,0.25);
  box-shadow: 0 7px 14px rgba(74,85,104,0.1),0 3px 6px rgba(0,0,0,0.08);
  border-color: rgba(34,160,63,0.25);
}

.btn-success-opacity-25:focus,
.btn-success-opacity-25.focus {
  box-shadow: 0 5px 8px rgba(74,85,104,0.11),0 2px 4px rgba(0,0,0,0.08);
}

.btn-success-opacity-25.disabled,
.btn-success-opacity-25:disabled {
  color: #fff;
  background-color: rgba(43,202,80,0.25);
  border-color: rgba(43,202,80,0.25);
}

.btn-success-opacity-25.disabled:hover,
.btn-success-opacity-25:disabled:hover {
  box-shadow: none;
}

.btn-success-opacity-25:not(:disabled):not(.disabled):active,
.btn-success-opacity-25:not(:disabled):not(.disabled).active,
.show>.btn-success-opacity-25.dropdown-toggle {
  color: #fff;
  background-color: rgba(34,160,63,0.25);
  border-color: rgba(32,149,59,0.25);
}

.btn-success-opacity-25:not(:disabled):not(.disabled):active:focus,
.btn-success-opacity-25:not(:disabled):not(.disabled).active:focus,
.show>.btn-success-opacity-25.dropdown-toggle:focus {
  box-shadow: 0 2px 3px rgba(74,85,104,0.11);
}

.btn-success-opacity-50 {
  color: #fff;
  background-color: rgba(43,202,80,0.5);
  border-color: rgba(43,202,80,0.5);
  box-shadow: 0 4px 6px rgba(74,85,104,0.11),0 1px 3px rgba(0,0,0,0.08);
}

.btn-success-opacity-50:hover {
  color: #fff;
  background-color: rgba(36,170,68,0.5);
  box-shadow: 0 7px 14px rgba(74,85,104,0.1),0 3px 6px rgba(0,0,0,0.08);
  border-color: rgba(34,160,63,0.5);
}

.btn-success-opacity-50:focus,
.btn-success-opacity-50.focus {
  box-shadow: 0 5px 8px rgba(74,85,104,0.11),0 2px 4px rgba(0,0,0,0.08);
}

.btn-success-opacity-50.disabled,
.btn-success-opacity-50:disabled {
  color: #fff;
  background-color: rgba(43,202,80,0.5);
  border-color: rgba(43,202,80,0.5);
}

.btn-success-opacity-50.disabled:hover,
.btn-success-opacity-50:disabled:hover {
  box-shadow: none;
}

.btn-success-opacity-50:not(:disabled):not(.disabled):active,
.btn-success-opacity-50:not(:disabled):not(.disabled).active,
.show>.btn-success-opacity-50.dropdown-toggle {
  color: #fff;
  background-color: rgba(34,160,63,0.5);
  border-color: rgba(32,149,59,0.5);
}

.btn-success-opacity-50:not(:disabled):not(.disabled):active:focus,
.btn-success-opacity-50:not(:disabled):not(.disabled).active:focus,
.show>.btn-success-opacity-50.dropdown-toggle:focus {
  box-shadow: 0 2px 3px rgba(74,85,104,0.11);
}

.btn-success-opacity-75 {
  color: #fff;
  background-color: rgba(43,202,80,0.75);
  border-color: rgba(43,202,80,0.75);
  box-shadow: 0 4px 6px rgba(74,85,104,0.11),0 1px 3px rgba(0,0,0,0.08);
}

.btn-success-opacity-75:hover {
  color: #fff;
  background-color: rgba(36,170,68,0.75);
  box-shadow: 0 7px 14px rgba(74,85,104,0.1),0 3px 6px rgba(0,0,0,0.08);
  border-color: rgba(34,160,63,0.75);
}

.btn-success-opacity-75:focus,
.btn-success-opacity-75.focus {
  box-shadow: 0 5px 8px rgba(74,85,104,0.11),0 2px 4px rgba(0,0,0,0.08);
}

.btn-success-opacity-75.disabled,
.btn-success-opacity-75:disabled {
  color: #fff;
  background-color: rgba(43,202,80,0.75);
  border-color: rgba(43,202,80,0.75);
}

.btn-success-opacity-75.disabled:hover,
.btn-success-opacity-75:disabled:hover {
  box-shadow: none;
}

.btn-success-opacity-75:not(:disabled):not(.disabled):active,
.btn-success-opacity-75:not(:disabled):not(.disabled).active,
.show>.btn-success-opacity-75.dropdown-toggle {
  color: #fff;
  background-color: rgba(34,160,63,0.75);
  border-color: rgba(32,149,59,0.75);
}

.btn-success-opacity-75:not(:disabled):not(.disabled):active:focus,
.btn-success-opacity-75:not(:disabled):not(.disabled).active:focus,
.show>.btn-success-opacity-75.dropdown-toggle:focus {
  box-shadow: 0 2px 3px rgba(74,85,104,0.11);
}

.btn-success-faded {
  color: #1A202C;
  background-color: #D5F4DC;
  border-color: #D5F4DC;
  box-shadow: 0 4px 6px rgba(74,85,104,0.11),0 1px 3px rgba(0,0,0,0.08);
}

.btn-success-faded:hover {
  color: #1A202C;
  background-color: #b7ecc3;
  box-shadow: 0 7px 14px rgba(74,85,104,0.1),0 3px 6px rgba(0,0,0,0.08);
  border-color: #ade9ba;
}

.btn-success-faded:focus,
.btn-success-faded.focus {
  box-shadow: 0 5px 8px rgba(74,85,104,0.11),0 2px 4px rgba(0,0,0,0.08);
}

.btn-success-faded.disabled,
.btn-success-faded:disabled {
  color: #1A202C;
  background-color: #D5F4DC;
  border-color: #D5F4DC;
}

.btn-success-faded.disabled:hover,
.btn-success-faded:disabled:hover {
  box-shadow: none;
}

.btn-success-faded:not(:disabled):not(.disabled):active,
.btn-success-faded:not(:disabled):not(.disabled).active,
.show>.btn-success-faded.dropdown-toggle {
  color: #1A202C;
  background-color: #ade9ba;
  border-color: #a2e7b2;
}

.btn-success-faded:not(:disabled):not(.disabled):active:focus,
.btn-success-faded:not(:disabled):not(.disabled).active:focus,
.show>.btn-success-faded.dropdown-toggle:focus {
  box-shadow: 0 2px 3px rgba(74,85,104,0.11);
}

.btn-info-opacity-25 {
  color: #fff;
  background-color: rgba(0,102,255,0.25);
  border-color: rgba(0,102,255,0.25);
  box-shadow: 0 4px 6px rgba(74,85,104,0.11),0 1px 3px rgba(0,0,0,0.08);
}

.btn-info-opacity-25:hover {
  color: #fff;
  background-color: rgba(0,87,217,0.25);
  box-shadow: 0 7px 14px rgba(74,85,104,0.1),0 3px 6px rgba(0,0,0,0.08);
  border-color: rgba(0,82,204,0.25);
}

.btn-info-opacity-25:focus,
.btn-info-opacity-25.focus {
  box-shadow: 0 5px 8px rgba(74,85,104,0.11),0 2px 4px rgba(0,0,0,0.08);
}

.btn-info-opacity-25.disabled,
.btn-info-opacity-25:disabled {
  color: #fff;
  background-color: rgba(0,102,255,0.25);
  border-color: rgba(0,102,255,0.25);
}

.btn-info-opacity-25.disabled:hover,
.btn-info-opacity-25:disabled:hover {
  box-shadow: none;
}

.btn-info-opacity-25:not(:disabled):not(.disabled):active,
.btn-info-opacity-25:not(:disabled):not(.disabled).active,
.show>.btn-info-opacity-25.dropdown-toggle {
  color: #fff;
  background-color: rgba(0,82,204,0.25);
  border-color: rgba(0,77,191,0.25);
}

.btn-info-opacity-25:not(:disabled):not(.disabled):active:focus,
.btn-info-opacity-25:not(:disabled):not(.disabled).active:focus,
.show>.btn-info-opacity-25.dropdown-toggle:focus {
  box-shadow: 0 2px 3px rgba(74,85,104,0.11);
}

.btn-info-opacity-50 {
  color: #fff;
  background-color: rgba(0,102,255,0.5);
  border-color: rgba(0,102,255,0.5);
  box-shadow: 0 4px 6px rgba(74,85,104,0.11),0 1px 3px rgba(0,0,0,0.08);
}

.btn-info-opacity-50:hover {
  color: #fff;
  background-color: rgba(0,87,217,0.5);
  box-shadow: 0 7px 14px rgba(74,85,104,0.1),0 3px 6px rgba(0,0,0,0.08);
  border-color: rgba(0,82,204,0.5);
}

.btn-info-opacity-50:focus,
.btn-info-opacity-50.focus {
  box-shadow: 0 5px 8px rgba(74,85,104,0.11),0 2px 4px rgba(0,0,0,0.08);
}

.btn-info-opacity-50.disabled,
.btn-info-opacity-50:disabled {
  color: #fff;
  background-color: rgba(0,102,255,0.5);
  border-color: rgba(0,102,255,0.5);
}

.btn-info-opacity-50.disabled:hover,
.btn-info-opacity-50:disabled:hover {
  box-shadow: none;
}

.btn-info-opacity-50:not(:disabled):not(.disabled):active,
.btn-info-opacity-50:not(:disabled):not(.disabled).active,
.show>.btn-info-opacity-50.dropdown-toggle {
  color: #fff;
  background-color: rgba(0,82,204,0.5);
  border-color: rgba(0,77,191,0.5);
}

.btn-info-opacity-50:not(:disabled):not(.disabled):active:focus,
.btn-info-opacity-50:not(:disabled):not(.disabled).active:focus,
.show>.btn-info-opacity-50.dropdown-toggle:focus {
  box-shadow: 0 2px 3px rgba(74,85,104,0.11);
}

.btn-info-opacity-75 {
  color: #fff;
  background-color: rgba(0,102,255,0.75);
  border-color: rgba(0,102,255,0.75);
  box-shadow: 0 4px 6px rgba(74,85,104,0.11),0 1px 3px rgba(0,0,0,0.08);
}

.btn-info-opacity-75:hover {
  color: #fff;
  background-color: rgba(0,87,217,0.75);
  box-shadow: 0 7px 14px rgba(74,85,104,0.1),0 3px 6px rgba(0,0,0,0.08);
  border-color: rgba(0,82,204,0.75);
}

.btn-info-opacity-75:focus,
.btn-info-opacity-75.focus {
  box-shadow: 0 5px 8px rgba(74,85,104,0.11),0 2px 4px rgba(0,0,0,0.08);
}

.btn-info-opacity-75.disabled,
.btn-info-opacity-75:disabled {
  color: #fff;
  background-color: rgba(0,102,255,0.75);
  border-color: rgba(0,102,255,0.75);
}

.btn-info-opacity-75.disabled:hover,
.btn-info-opacity-75:disabled:hover {
  box-shadow: none;
}

.btn-info-opacity-75:not(:disabled):not(.disabled):active,
.btn-info-opacity-75:not(:disabled):not(.disabled).active,
.show>.btn-info-opacity-75.dropdown-toggle {
  color: #fff;
  background-color: rgba(0,82,204,0.75);
  border-color: rgba(0,77,191,0.75);
}

.btn-info-opacity-75:not(:disabled):not(.disabled):active:focus,
.btn-info-opacity-75:not(:disabled):not(.disabled).active:focus,
.show>.btn-info-opacity-75.dropdown-toggle:focus {
  box-shadow: 0 2px 3px rgba(74,85,104,0.11);
}

.btn-info-faded {
  color: #1A202C;
  background-color: #CCE0FF;
  border-color: #CCE0FF;
  box-shadow: 0 4px 6px rgba(74,85,104,0.11),0 1px 3px rgba(0,0,0,0.08);
}

.btn-info-faded:hover {
  color: #1A202C;
  background-color: #a6c9ff;
  box-shadow: 0 7px 14px rgba(74,85,104,0.1),0 3px 6px rgba(0,0,0,0.08);
  border-color: #99c1ff;
}

.btn-info-faded:focus,
.btn-info-faded.focus {
  box-shadow: 0 5px 8px rgba(74,85,104,0.11),0 2px 4px rgba(0,0,0,0.08);
}

.btn-info-faded.disabled,
.btn-info-faded:disabled {
  color: #1A202C;
  background-color: #CCE0FF;
  border-color: #CCE0FF;
}

.btn-info-faded.disabled:hover,
.btn-info-faded:disabled:hover {
  box-shadow: none;
}

.btn-info-faded:not(:disabled):not(.disabled):active,
.btn-info-faded:not(:disabled):not(.disabled).active,
.show>.btn-info-faded.dropdown-toggle {
  color: #1A202C;
  background-color: #99c1ff;
  border-color: #8cb9ff;
}

.btn-info-faded:not(:disabled):not(.disabled):active:focus,
.btn-info-faded:not(:disabled):not(.disabled).active:focus,
.show>.btn-info-faded.dropdown-toggle:focus {
  box-shadow: 0 2px 3px rgba(74,85,104,0.11);
}

.btn-warning-opacity-25 {
  color: #fff;
  background-color: rgba(252,161,4,0.25);
  border-color: rgba(252,161,4,0.25);
  box-shadow: 0 4px 6px rgba(74,85,104,0.11),0 1px 3px rgba(0,0,0,0.08);
}

.btn-warning-opacity-25:hover {
  color: #fff;
  background-color: rgba(215,137,3,0.25);
  box-shadow: 0 7px 14px rgba(74,85,104,0.1),0 3px 6px rgba(0,0,0,0.08);
  border-color: rgba(203,129,2,0.25);
}

.btn-warning-opacity-25:focus,
.btn-warning-opacity-25.focus {
  box-shadow: 0 5px 8px rgba(74,85,104,0.11),0 2px 4px rgba(0,0,0,0.08);
}

.btn-warning-opacity-25.disabled,
.btn-warning-opacity-25:disabled {
  color: #fff;
  background-color: rgba(252,161,4,0.25);
  border-color: rgba(252,161,4,0.25);
}

.btn-warning-opacity-25.disabled:hover,
.btn-warning-opacity-25:disabled:hover {
  box-shadow: none;
}

.btn-warning-opacity-25:not(:disabled):not(.disabled):active,
.btn-warning-opacity-25:not(:disabled):not(.disabled).active,
.show>.btn-warning-opacity-25.dropdown-toggle {
  color: #fff;
  background-color: rgba(203,129,2,0.25);
  border-color: rgba(190,121,2,0.25);
}

.btn-warning-opacity-25:not(:disabled):not(.disabled):active:focus,
.btn-warning-opacity-25:not(:disabled):not(.disabled).active:focus,
.show>.btn-warning-opacity-25.dropdown-toggle:focus {
  box-shadow: 0 2px 3px rgba(74,85,104,0.11);
}

.btn-warning-opacity-50 {
  color: #fff;
  background-color: rgba(252,161,4,0.5);
  border-color: rgba(252,161,4,0.5);
  box-shadow: 0 4px 6px rgba(74,85,104,0.11),0 1px 3px rgba(0,0,0,0.08);
}

.btn-warning-opacity-50:hover {
  color: #fff;
  background-color: rgba(215,137,3,0.5);
  box-shadow: 0 7px 14px rgba(74,85,104,0.1),0 3px 6px rgba(0,0,0,0.08);
  border-color: rgba(203,129,2,0.5);
}

.btn-warning-opacity-50:focus,
.btn-warning-opacity-50.focus {
  box-shadow: 0 5px 8px rgba(74,85,104,0.11),0 2px 4px rgba(0,0,0,0.08);
}

.btn-warning-opacity-50.disabled,
.btn-warning-opacity-50:disabled {
  color: #fff;
  background-color: rgba(252,161,4,0.5);
  border-color: rgba(252,161,4,0.5);
}

.btn-warning-opacity-50.disabled:hover,
.btn-warning-opacity-50:disabled:hover {
  box-shadow: none;
}

.btn-warning-opacity-50:not(:disabled):not(.disabled):active,
.btn-warning-opacity-50:not(:disabled):not(.disabled).active,
.show>.btn-warning-opacity-50.dropdown-toggle {
  color: #fff;
  background-color: rgba(203,129,2,0.5);
  border-color: rgba(190,121,2,0.5);
}

.btn-warning-opacity-50:not(:disabled):not(.disabled):active:focus,
.btn-warning-opacity-50:not(:disabled):not(.disabled).active:focus,
.show>.btn-warning-opacity-50.dropdown-toggle:focus {
  box-shadow: 0 2px 3px rgba(74,85,104,0.11);
}

.btn-warning-opacity-75 {
  color: #fff;
  background-color: rgba(252,161,4,0.75);
  border-color: rgba(252,161,4,0.75);
  box-shadow: 0 4px 6px rgba(74,85,104,0.11),0 1px 3px rgba(0,0,0,0.08);
}

.btn-warning-opacity-75:hover {
  color: #fff;
  background-color: rgba(215,137,3,0.75);
  box-shadow: 0 7px 14px rgba(74,85,104,0.1),0 3px 6px rgba(0,0,0,0.08);
  border-color: rgba(203,129,2,0.75);
}

.btn-warning-opacity-75:focus,
.btn-warning-opacity-75.focus {
  box-shadow: 0 5px 8px rgba(74,85,104,0.11),0 2px 4px rgba(0,0,0,0.08);
}

.btn-warning-opacity-75.disabled,
.btn-warning-opacity-75:disabled {
  color: #fff;
  background-color: rgba(252,161,4,0.75);
  border-color: rgba(252,161,4,0.75);
}

.btn-warning-opacity-75.disabled:hover,
.btn-warning-opacity-75:disabled:hover {
  box-shadow: none;
}

.btn-warning-opacity-75:not(:disabled):not(.disabled):active,
.btn-warning-opacity-75:not(:disabled):not(.disabled).active,
.show>.btn-warning-opacity-75.dropdown-toggle {
  color: #fff;
  background-color: rgba(203,129,2,0.75);
  border-color: rgba(190,121,2,0.75);
}

.btn-warning-opacity-75:not(:disabled):not(.disabled):active:focus,
.btn-warning-opacity-75:not(:disabled):not(.disabled).active:focus,
.show>.btn-warning-opacity-75.dropdown-toggle:focus {
  box-shadow: 0 2px 3px rgba(74,85,104,0.11);
}

.btn-warning-faded {
  color: #1A202C;
  background-color: #FEECCD;
  border-color: #FEECCD;
  box-shadow: 0 4px 6px rgba(74,85,104,0.11),0 1px 3px rgba(0,0,0,0.08);
}

.btn-warning-faded:hover {
  color: #1A202C;
  background-color: #fddea8;
  box-shadow: 0 7px 14px rgba(74,85,104,0.1),0 3px 6px rgba(0,0,0,0.08);
  border-color: #fdd99b;
}

.btn-warning-faded:focus,
.btn-warning-faded.focus {
  box-shadow: 0 5px 8px rgba(74,85,104,0.11),0 2px 4px rgba(0,0,0,0.08);
}

.btn-warning-faded.disabled,
.btn-warning-faded:disabled {
  color: #1A202C;
  background-color: #FEECCD;
  border-color: #FEECCD;
}

.btn-warning-faded.disabled:hover,
.btn-warning-faded:disabled:hover {
  box-shadow: none;
}

.btn-warning-faded:not(:disabled):not(.disabled):active,
.btn-warning-faded:not(:disabled):not(.disabled).active,
.show>.btn-warning-faded.dropdown-toggle {
  color: #1A202C;
  background-color: #fdd99b;
  border-color: #fdd48f;
}

.btn-warning-faded:not(:disabled):not(.disabled):active:focus,
.btn-warning-faded:not(:disabled):not(.disabled).active:focus,
.show>.btn-warning-faded.dropdown-toggle:focus {
  box-shadow: 0 2px 3px rgba(74,85,104,0.11);
}

.btn-danger-opacity-25 {
  color: #fff;
  background-color: rgba(244,57,70,0.25);
  border-color: rgba(244,57,70,0.25);
  box-shadow: 0 4px 6px rgba(74,85,104,0.11),0 1px 3px rgba(0,0,0,0.08);
}

.btn-danger-opacity-25:hover {
  color: #fff;
  background-color: rgba(242,21,36,0.25);
  box-shadow: 0 7px 14px rgba(74,85,104,0.1),0 3px 6px rgba(0,0,0,0.08);
  border-color: rgba(237,13,29,0.25);
}

.btn-danger-opacity-25:focus,
.btn-danger-opacity-25.focus {
  box-shadow: 0 5px 8px rgba(74,85,104,0.11),0 2px 4px rgba(0,0,0,0.08);
}

.btn-danger-opacity-25.disabled,
.btn-danger-opacity-25:disabled {
  color: #fff;
  background-color: rgba(244,57,70,0.25);
  border-color: rgba(244,57,70,0.25);
}

.btn-danger-opacity-25.disabled:hover,
.btn-danger-opacity-25:disabled:hover {
  box-shadow: none;
}

.btn-danger-opacity-25:not(:disabled):not(.disabled):active,
.btn-danger-opacity-25:not(:disabled):not(.disabled).active,
.show>.btn-danger-opacity-25.dropdown-toggle {
  color: #fff;
  background-color: rgba(237,13,29,0.25);
  border-color: rgba(225,12,27,0.25);
}

.btn-danger-opacity-25:not(:disabled):not(.disabled):active:focus,
.btn-danger-opacity-25:not(:disabled):not(.disabled).active:focus,
.show>.btn-danger-opacity-25.dropdown-toggle:focus {
  box-shadow: 0 2px 3px rgba(74,85,104,0.11);
}

.btn-danger-opacity-50 {
  color: #fff;
  background-color: rgba(244,57,70,0.5);
  border-color: rgba(244,57,70,0.5);
  box-shadow: 0 4px 6px rgba(74,85,104,0.11),0 1px 3px rgba(0,0,0,0.08);
}

.btn-danger-opacity-50:hover {
  color: #fff;
  background-color: rgba(242,21,36,0.5);
  box-shadow: 0 7px 14px rgba(74,85,104,0.1),0 3px 6px rgba(0,0,0,0.08);
  border-color: rgba(237,13,29,0.5);
}

.btn-danger-opacity-50:focus,
.btn-danger-opacity-50.focus {
  box-shadow: 0 5px 8px rgba(74,85,104,0.11),0 2px 4px rgba(0,0,0,0.08);
}

.btn-danger-opacity-50.disabled,
.btn-danger-opacity-50:disabled {
  color: #fff;
  background-color: rgba(244,57,70,0.5);
  border-color: rgba(244,57,70,0.5);
}

.btn-danger-opacity-50.disabled:hover,
.btn-danger-opacity-50:disabled:hover {
  box-shadow: none;
}

.btn-danger-opacity-50:not(:disabled):not(.disabled):active,
.btn-danger-opacity-50:not(:disabled):not(.disabled).active,
.show>.btn-danger-opacity-50.dropdown-toggle {
  color: #fff;
  background-color: rgba(237,13,29,0.5);
  border-color: rgba(225,12,27,0.5);
}

.btn-danger-opacity-50:not(:disabled):not(.disabled):active:focus,
.btn-danger-opacity-50:not(:disabled):not(.disabled).active:focus,
.show>.btn-danger-opacity-50.dropdown-toggle:focus {
  box-shadow: 0 2px 3px rgba(74,85,104,0.11);
}

.btn-danger-opacity-75 {
  color: #fff;
  background-color: rgba(244,57,70,0.75);
  border-color: rgba(244,57,70,0.75);
  box-shadow: 0 4px 6px rgba(74,85,104,0.11),0 1px 3px rgba(0,0,0,0.08);
}

.btn-danger-opacity-75:hover {
  color: #fff;
  background-color: rgba(242,21,36,0.75);
  box-shadow: 0 7px 14px rgba(74,85,104,0.1),0 3px 6px rgba(0,0,0,0.08);
  border-color: rgba(237,13,29,0.75);
}

.btn-danger-opacity-75:focus,
.btn-danger-opacity-75.focus {
  box-shadow: 0 5px 8px rgba(74,85,104,0.11),0 2px 4px rgba(0,0,0,0.08);
}

.btn-danger-opacity-75.disabled,
.btn-danger-opacity-75:disabled {
  color: #fff;
  background-color: rgba(244,57,70,0.75);
  border-color: rgba(244,57,70,0.75);
}

.btn-danger-opacity-75.disabled:hover,
.btn-danger-opacity-75:disabled:hover {
  box-shadow: none;
}

.btn-danger-opacity-75:not(:disabled):not(.disabled):active,
.btn-danger-opacity-75:not(:disabled):not(.disabled).active,
.show>.btn-danger-opacity-75.dropdown-toggle {
  color: #fff;
  background-color: rgba(237,13,29,0.75);
  border-color: rgba(225,12,27,0.75);
}

.btn-danger-opacity-75:not(:disabled):not(.disabled):active:focus,
.btn-danger-opacity-75:not(:disabled):not(.disabled).active:focus,
.show>.btn-danger-opacity-75.dropdown-toggle:focus {
  box-shadow: 0 2px 3px rgba(74,85,104,0.11);
}

.btn-danger-faded {
  color: #1A202C;
  background-color: #FDD7DA;
  border-color: #FDD7DA;
  box-shadow: 0 4px 6px rgba(74,85,104,0.11),0 1px 3px rgba(0,0,0,0.08);
}

.btn-danger-faded:hover {
  color: #1A202C;
  background-color: #fbb3b8;
  box-shadow: 0 7px 14px rgba(74,85,104,0.1),0 3px 6px rgba(0,0,0,0.08);
  border-color: #fba6ad;
}

.btn-danger-faded:focus,
.btn-danger-faded.focus {
  box-shadow: 0 5px 8px rgba(74,85,104,0.11),0 2px 4px rgba(0,0,0,0.08);
}

.btn-danger-faded.disabled,
.btn-danger-faded:disabled {
  color: #1A202C;
  background-color: #FDD7DA;
  border-color: #FDD7DA;
}

.btn-danger-faded.disabled:hover,
.btn-danger-faded:disabled:hover {
  box-shadow: none;
}

.btn-danger-faded:not(:disabled):not(.disabled):active,
.btn-danger-faded:not(:disabled):not(.disabled).active,
.show>.btn-danger-faded.dropdown-toggle {
  color: #1A202C;
  background-color: #fba6ad;
  border-color: #fa9aa2;
}

.btn-danger-faded:not(:disabled):not(.disabled):active:focus,
.btn-danger-faded:not(:disabled):not(.disabled).active:focus,
.show>.btn-danger-faded.dropdown-toggle:focus {
  box-shadow: 0 2px 3px rgba(74,85,104,0.11);
}

.btn-outline-primary {
  color: #06f;
  border-color: #06f;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #06f;
  border-color: #06f;
  box-shadow: 0 7px 14px rgba(74,85,104,0.1),0 3px 6px rgba(0,0,0,0.08);
}

.btn-outline-primary:focus,
.btn-outline-primary.focus {
  box-shadow: 0 2px 3px rgba(74,85,104,0.11);
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: #06f;
  background-color: transparent;
}

.btn-outline-primary.disabled:hover,
.btn-outline-primary:disabled:hover {
  box-shadow: none;
}

.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show>.btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #06f;
  border-color: #06f;
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 2px 3px rgba(74,85,104,0.11);
}

.btn-outline-secondary {
  color: #76818C;
  border-color: #76818C;
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #76818C;
  border-color: #76818C;
  box-shadow: 0 7px 14px rgba(74,85,104,0.1),0 3px 6px rgba(0,0,0,0.08);
}

.btn-outline-secondary:focus,
.btn-outline-secondary.focus {
  box-shadow: 0 2px 3px rgba(74,85,104,0.11);
}

.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
  color: #76818C;
  background-color: transparent;
}

.btn-outline-secondary.disabled:hover,
.btn-outline-secondary:disabled:hover {
  box-shadow: none;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active,
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.show>.btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #76818C;
  border-color: #76818C;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 2px 3px rgba(74,85,104,0.11);
}

.btn-outline-success {
  color: #2BCA50;
  border-color: #2BCA50;
}

.btn-outline-success:hover {
  color: #fff;
  background-color: #2BCA50;
  border-color: #2BCA50;
  box-shadow: 0 7px 14px rgba(74,85,104,0.1),0 3px 6px rgba(0,0,0,0.08);
}

.btn-outline-success:focus,
.btn-outline-success.focus {
  box-shadow: 0 2px 3px rgba(74,85,104,0.11);
}

.btn-outline-success.disabled,
.btn-outline-success:disabled {
  color: #2BCA50;
  background-color: transparent;
}

.btn-outline-success.disabled:hover,
.btn-outline-success:disabled:hover {
  box-shadow: none;
}

.btn-outline-success:not(:disabled):not(.disabled):active,
.btn-outline-success:not(:disabled):not(.disabled).active,
.show>.btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #2BCA50;
  border-color: #2BCA50;
}

.btn-outline-success:not(:disabled):not(.disabled):active:focus,
.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 2px 3px rgba(74,85,104,0.11);
}

.btn-outline-info {
  color: #06f;
  border-color: #06f;
}

.btn-outline-info:hover {
  color: #fff;
  background-color: #06f;
  border-color: #06f;
  box-shadow: 0 7px 14px rgba(74,85,104,0.1),0 3px 6px rgba(0,0,0,0.08);
}

.btn-outline-info:focus,
.btn-outline-info.focus {
  box-shadow: 0 2px 3px rgba(74,85,104,0.11);
}

.btn-outline-info.disabled,
.btn-outline-info:disabled {
  color: #06f;
  background-color: transparent;
}

.btn-outline-info.disabled:hover,
.btn-outline-info:disabled:hover {
  box-shadow: none;
}

.btn-outline-info:not(:disabled):not(.disabled):active,
.btn-outline-info:not(:disabled):not(.disabled).active,
.show>.btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #06f;
  border-color: #06f;
}

.btn-outline-info:not(:disabled):not(.disabled):active:focus,
.btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 2px 3px rgba(74,85,104,0.11);
}

.btn-outline-warning {
  color: #FCA104;
  border-color: #FCA104;
}

.btn-outline-warning:hover {
  color: #fff;
  background-color: #FCA104;
  border-color: #FCA104;
  box-shadow: 0 7px 14px rgba(74,85,104,0.1),0 3px 6px rgba(0,0,0,0.08);
}

.btn-outline-warning:focus,
.btn-outline-warning.focus {
  box-shadow: 0 2px 3px rgba(74,85,104,0.11);
}

.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
  color: #FCA104;
  background-color: transparent;
}

.btn-outline-warning.disabled:hover,
.btn-outline-warning:disabled:hover {
  box-shadow: none;
}

.btn-outline-warning:not(:disabled):not(.disabled):active,
.btn-outline-warning:not(:disabled):not(.disabled).active,
.show>.btn-outline-warning.dropdown-toggle {
  color: #fff;
  background-color: #FCA104;
  border-color: #FCA104;
}

.btn-outline-warning:not(:disabled):not(.disabled):active:focus,
.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 2px 3px rgba(74,85,104,0.11);
}

.btn-outline-danger {
  color: #F43946;
  border-color: #F43946;
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #F43946;
  border-color: #F43946;
  box-shadow: 0 7px 14px rgba(74,85,104,0.1),0 3px 6px rgba(0,0,0,0.08);
}

.btn-outline-danger:focus,
.btn-outline-danger.focus {
  box-shadow: 0 2px 3px rgba(74,85,104,0.11);
}

.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
  color: #F43946;
  background-color: transparent;
}

.btn-outline-danger.disabled:hover,
.btn-outline-danger:disabled:hover {
  box-shadow: none;
}

.btn-outline-danger:not(:disabled):not(.disabled):active,
.btn-outline-danger:not(:disabled):not(.disabled).active,
.show>.btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #F43946;
  border-color: #F43946;
}

.btn-outline-danger:not(:disabled):not(.disabled):active:focus,
.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 2px 3px rgba(74,85,104,0.11);
}

.btn-outline-light {
  color: #EDF2F7;
  border-color: #EDF2F7;
}

.btn-outline-light:hover {
  color: #1A202C;
  background-color: #EDF2F7;
  border-color: #EDF2F7;
  box-shadow: 0 7px 14px rgba(74,85,104,0.1),0 3px 6px rgba(0,0,0,0.08);
}

.btn-outline-light:focus,
.btn-outline-light.focus {
  box-shadow: 0 2px 3px rgba(74,85,104,0.11);
}

.btn-outline-light.disabled,
.btn-outline-light:disabled {
  color: #EDF2F7;
  background-color: transparent;
}

.btn-outline-light.disabled:hover,
.btn-outline-light:disabled:hover {
  box-shadow: none;
}

.btn-outline-light:not(:disabled):not(.disabled):active,
.btn-outline-light:not(:disabled):not(.disabled).active,
.show>.btn-outline-light.dropdown-toggle {
  color: #1A202C;
  background-color: #EDF2F7;
  border-color: #EDF2F7;
}

.btn-outline-light:not(:disabled):not(.disabled):active:focus,
.btn-outline-light:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 2px 3px rgba(74,85,104,0.11);
}

.btn-outline-dark {
  color: #2D3748;
  border-color: #2D3748;
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #2D3748;
  border-color: #2D3748;
  box-shadow: 0 7px 14px rgba(74,85,104,0.1),0 3px 6px rgba(0,0,0,0.08);
}

.btn-outline-dark:focus,
.btn-outline-dark.focus {
  box-shadow: 0 2px 3px rgba(74,85,104,0.11);
}

.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
  color: #2D3748;
  background-color: transparent;
}

.btn-outline-dark.disabled:hover,
.btn-outline-dark:disabled:hover {
  box-shadow: none;
}

.btn-outline-dark:not(:disabled):not(.disabled):active,
.btn-outline-dark:not(:disabled):not(.disabled).active,
.show>.btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #2D3748;
  border-color: #2D3748;
}

.btn-outline-dark:not(:disabled):not(.disabled):active:focus,
.btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 2px 3px rgba(74,85,104,0.11);
}

.btn-outline-primary-opacity-25 {
  color: rgba(0,102,255,0.25);
  border-color: rgba(0,102,255,0.25);
}

.btn-outline-primary-opacity-25:hover {
  color: #fff;
  background-color: rgba(0,102,255,0.25);
  border-color: rgba(0,102,255,0.25);
  box-shadow: 0 7px 14px rgba(74,85,104,0.1),0 3px 6px rgba(0,0,0,0.08);
}

.btn-outline-primary-opacity-25:focus,
.btn-outline-primary-opacity-25.focus {
  box-shadow: 0 2px 3px rgba(74,85,104,0.11);
}

.btn-outline-primary-opacity-25.disabled,
.btn-outline-primary-opacity-25:disabled {
  color: rgba(0,102,255,0.25);
  background-color: transparent;
}

.btn-outline-primary-opacity-25.disabled:hover,
.btn-outline-primary-opacity-25:disabled:hover {
  box-shadow: none;
}

.btn-outline-primary-opacity-25:not(:disabled):not(.disabled):active,
.btn-outline-primary-opacity-25:not(:disabled):not(.disabled).active,
.show>.btn-outline-primary-opacity-25.dropdown-toggle {
  color: #fff;
  background-color: rgba(0,102,255,0.25);
  border-color: rgba(0,102,255,0.25);
}

.btn-outline-primary-opacity-25:not(:disabled):not(.disabled):active:focus,
.btn-outline-primary-opacity-25:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-primary-opacity-25.dropdown-toggle:focus {
  box-shadow: 0 2px 3px rgba(74,85,104,0.11);
}

.btn-outline-primary-opacity-50 {
  color: rgba(0,102,255,0.5);
  border-color: rgba(0,102,255,0.5);
}

.btn-outline-primary-opacity-50:hover {
  color: #fff;
  background-color: rgba(0,102,255,0.5);
  border-color: rgba(0,102,255,0.5);
  box-shadow: 0 7px 14px rgba(74,85,104,0.1),0 3px 6px rgba(0,0,0,0.08);
}

.btn-outline-primary-opacity-50:focus,
.btn-outline-primary-opacity-50.focus {
  box-shadow: 0 2px 3px rgba(74,85,104,0.11);
}

.btn-outline-primary-opacity-50.disabled,
.btn-outline-primary-opacity-50:disabled {
  color: rgba(0,102,255,0.5);
  background-color: transparent;
}

.btn-outline-primary-opacity-50.disabled:hover,
.btn-outline-primary-opacity-50:disabled:hover {
  box-shadow: none;
}

.btn-outline-primary-opacity-50:not(:disabled):not(.disabled):active,
.btn-outline-primary-opacity-50:not(:disabled):not(.disabled).active,
.show>.btn-outline-primary-opacity-50.dropdown-toggle {
  color: #fff;
  background-color: rgba(0,102,255,0.5);
  border-color: rgba(0,102,255,0.5);
}

.btn-outline-primary-opacity-50:not(:disabled):not(.disabled):active:focus,
.btn-outline-primary-opacity-50:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-primary-opacity-50.dropdown-toggle:focus {
  box-shadow: 0 2px 3px rgba(74,85,104,0.11);
}

.btn-outline-primary-opacity-75 {
  color: rgba(0,102,255,0.75);
  border-color: rgba(0,102,255,0.75);
}

.btn-outline-primary-opacity-75:hover {
  color: #fff;
  background-color: rgba(0,102,255,0.75);
  border-color: rgba(0,102,255,0.75);
  box-shadow: 0 7px 14px rgba(74,85,104,0.1),0 3px 6px rgba(0,0,0,0.08);
}

.btn-outline-primary-opacity-75:focus,
.btn-outline-primary-opacity-75.focus {
  box-shadow: 0 2px 3px rgba(74,85,104,0.11);
}

.btn-outline-primary-opacity-75.disabled,
.btn-outline-primary-opacity-75:disabled {
  color: rgba(0,102,255,0.75);
  background-color: transparent;
}

.btn-outline-primary-opacity-75.disabled:hover,
.btn-outline-primary-opacity-75:disabled:hover {
  box-shadow: none;
}

.btn-outline-primary-opacity-75:not(:disabled):not(.disabled):active,
.btn-outline-primary-opacity-75:not(:disabled):not(.disabled).active,
.show>.btn-outline-primary-opacity-75.dropdown-toggle {
  color: #fff;
  background-color: rgba(0,102,255,0.75);
  border-color: rgba(0,102,255,0.75);
}

.btn-outline-primary-opacity-75:not(:disabled):not(.disabled):active:focus,
.btn-outline-primary-opacity-75:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-primary-opacity-75.dropdown-toggle:focus {
  box-shadow: 0 2px 3px rgba(74,85,104,0.11);
}

.btn-outline-primary-faded {
  color: #CCE0FF;
  border-color: #CCE0FF;
}

.btn-outline-primary-faded:hover {
  color: #1A202C;
  background-color: #CCE0FF;
  border-color: #CCE0FF;
  box-shadow: 0 7px 14px rgba(74,85,104,0.1),0 3px 6px rgba(0,0,0,0.08);
}

.btn-outline-primary-faded:focus,
.btn-outline-primary-faded.focus {
  box-shadow: 0 2px 3px rgba(74,85,104,0.11);
}

.btn-outline-primary-faded.disabled,
.btn-outline-primary-faded:disabled {
  color: #CCE0FF;
  background-color: transparent;
}

.btn-outline-primary-faded.disabled:hover,
.btn-outline-primary-faded:disabled:hover {
  box-shadow: none;
}

.btn-outline-primary-faded:not(:disabled):not(.disabled):active,
.btn-outline-primary-faded:not(:disabled):not(.disabled).active,
.show>.btn-outline-primary-faded.dropdown-toggle {
  color: #1A202C;
  background-color: #CCE0FF;
  border-color: #CCE0FF;
}

.btn-outline-primary-faded:not(:disabled):not(.disabled):active:focus,
.btn-outline-primary-faded:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-primary-faded.dropdown-toggle:focus {
  box-shadow: 0 2px 3px rgba(74,85,104,0.11);
}

.btn-outline-secondary-opacity-25 {
  color: rgba(118,129,140,0.25);
  border-color: rgba(118,129,140,0.25);
}

.btn-outline-secondary-opacity-25:hover {
  color: #fff;
  background-color: rgba(118,129,140,0.25);
  border-color: rgba(118,129,140,0.25);
  box-shadow: 0 7px 14px rgba(74,85,104,0.1),0 3px 6px rgba(0,0,0,0.08);
}

.btn-outline-secondary-opacity-25:focus,
.btn-outline-secondary-opacity-25.focus {
  box-shadow: 0 2px 3px rgba(74,85,104,0.11);
}

.btn-outline-secondary-opacity-25.disabled,
.btn-outline-secondary-opacity-25:disabled {
  color: rgba(118,129,140,0.25);
  background-color: transparent;
}

.btn-outline-secondary-opacity-25.disabled:hover,
.btn-outline-secondary-opacity-25:disabled:hover {
  box-shadow: none;
}

.btn-outline-secondary-opacity-25:not(:disabled):not(.disabled):active,
.btn-outline-secondary-opacity-25:not(:disabled):not(.disabled).active,
.show>.btn-outline-secondary-opacity-25.dropdown-toggle {
  color: #fff;
  background-color: rgba(118,129,140,0.25);
  border-color: rgba(118,129,140,0.25);
}

.btn-outline-secondary-opacity-25:not(:disabled):not(.disabled):active:focus,
.btn-outline-secondary-opacity-25:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-secondary-opacity-25.dropdown-toggle:focus {
  box-shadow: 0 2px 3px rgba(74,85,104,0.11);
}

.btn-outline-secondary-opacity-50 {
  color: rgba(118,129,140,0.5);
  border-color: rgba(118,129,140,0.5);
}

.btn-outline-secondary-opacity-50:hover {
  color: #fff;
  background-color: rgba(118,129,140,0.5);
  border-color: rgba(118,129,140,0.5);
  box-shadow: 0 7px 14px rgba(74,85,104,0.1),0 3px 6px rgba(0,0,0,0.08);
}

.btn-outline-secondary-opacity-50:focus,
.btn-outline-secondary-opacity-50.focus {
  box-shadow: 0 2px 3px rgba(74,85,104,0.11);
}

.btn-outline-secondary-opacity-50.disabled,
.btn-outline-secondary-opacity-50:disabled {
  color: rgba(118,129,140,0.5);
  background-color: transparent;
}

.btn-outline-secondary-opacity-50.disabled:hover,
.btn-outline-secondary-opacity-50:disabled:hover {
  box-shadow: none;
}

.btn-outline-secondary-opacity-50:not(:disabled):not(.disabled):active,
.btn-outline-secondary-opacity-50:not(:disabled):not(.disabled).active,
.show>.btn-outline-secondary-opacity-50.dropdown-toggle {
  color: #fff;
  background-color: rgba(118,129,140,0.5);
  border-color: rgba(118,129,140,0.5);
}

.btn-outline-secondary-opacity-50:not(:disabled):not(.disabled):active:focus,
.btn-outline-secondary-opacity-50:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-secondary-opacity-50.dropdown-toggle:focus {
  box-shadow: 0 2px 3px rgba(74,85,104,0.11);
}

.btn-outline-secondary-opacity-75 {
  color: rgba(118,129,140,0.75);
  border-color: rgba(118,129,140,0.75);
}

.btn-outline-secondary-opacity-75:hover {
  color: #fff;
  background-color: rgba(118,129,140,0.75);
  border-color: rgba(118,129,140,0.75);
  box-shadow: 0 7px 14px rgba(74,85,104,0.1),0 3px 6px rgba(0,0,0,0.08);
}

.btn-outline-secondary-opacity-75:focus,
.btn-outline-secondary-opacity-75.focus {
  box-shadow: 0 2px 3px rgba(74,85,104,0.11);
}

.btn-outline-secondary-opacity-75.disabled,
.btn-outline-secondary-opacity-75:disabled {
  color: rgba(118,129,140,0.75);
  background-color: transparent;
}

.btn-outline-secondary-opacity-75.disabled:hover,
.btn-outline-secondary-opacity-75:disabled:hover {
  box-shadow: none;
}

.btn-outline-secondary-opacity-75:not(:disabled):not(.disabled):active,
.btn-outline-secondary-opacity-75:not(:disabled):not(.disabled).active,
.show>.btn-outline-secondary-opacity-75.dropdown-toggle {
  color: #fff;
  background-color: rgba(118,129,140,0.75);
  border-color: rgba(118,129,140,0.75);
}

.btn-outline-secondary-opacity-75:not(:disabled):not(.disabled):active:focus,
.btn-outline-secondary-opacity-75:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-secondary-opacity-75.dropdown-toggle:focus {
  box-shadow: 0 2px 3px rgba(74,85,104,0.11);
}

.btn-outline-success-opacity-25 {
  color: rgba(43,202,80,0.25);
  border-color: rgba(43,202,80,0.25);
}

.btn-outline-success-opacity-25:hover {
  color: #fff;
  background-color: rgba(43,202,80,0.25);
  border-color: rgba(43,202,80,0.25);
  box-shadow: 0 7px 14px rgba(74,85,104,0.1),0 3px 6px rgba(0,0,0,0.08);
}

.btn-outline-success-opacity-25:focus,
.btn-outline-success-opacity-25.focus {
  box-shadow: 0 2px 3px rgba(74,85,104,0.11);
}

.btn-outline-success-opacity-25.disabled,
.btn-outline-success-opacity-25:disabled {
  color: rgba(43,202,80,0.25);
  background-color: transparent;
}

.btn-outline-success-opacity-25.disabled:hover,
.btn-outline-success-opacity-25:disabled:hover {
  box-shadow: none;
}

.btn-outline-success-opacity-25:not(:disabled):not(.disabled):active,
.btn-outline-success-opacity-25:not(:disabled):not(.disabled).active,
.show>.btn-outline-success-opacity-25.dropdown-toggle {
  color: #fff;
  background-color: rgba(43,202,80,0.25);
  border-color: rgba(43,202,80,0.25);
}

.btn-outline-success-opacity-25:not(:disabled):not(.disabled):active:focus,
.btn-outline-success-opacity-25:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-success-opacity-25.dropdown-toggle:focus {
  box-shadow: 0 2px 3px rgba(74,85,104,0.11);
}

.btn-outline-success-opacity-50 {
  color: rgba(43,202,80,0.5);
  border-color: rgba(43,202,80,0.5);
}

.btn-outline-success-opacity-50:hover {
  color: #fff;
  background-color: rgba(43,202,80,0.5);
  border-color: rgba(43,202,80,0.5);
  box-shadow: 0 7px 14px rgba(74,85,104,0.1),0 3px 6px rgba(0,0,0,0.08);
}

.btn-outline-success-opacity-50:focus,
.btn-outline-success-opacity-50.focus {
  box-shadow: 0 2px 3px rgba(74,85,104,0.11);
}

.btn-outline-success-opacity-50.disabled,
.btn-outline-success-opacity-50:disabled {
  color: rgba(43,202,80,0.5);
  background-color: transparent;
}

.btn-outline-success-opacity-50.disabled:hover,
.btn-outline-success-opacity-50:disabled:hover {
  box-shadow: none;
}

.btn-outline-success-opacity-50:not(:disabled):not(.disabled):active,
.btn-outline-success-opacity-50:not(:disabled):not(.disabled).active,
.show>.btn-outline-success-opacity-50.dropdown-toggle {
  color: #fff;
  background-color: rgba(43,202,80,0.5);
  border-color: rgba(43,202,80,0.5);
}

.btn-outline-success-opacity-50:not(:disabled):not(.disabled):active:focus,
.btn-outline-success-opacity-50:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-success-opacity-50.dropdown-toggle:focus {
  box-shadow: 0 2px 3px rgba(74,85,104,0.11);
}

.btn-outline-success-opacity-75 {
  color: rgba(43,202,80,0.75);
  border-color: rgba(43,202,80,0.75);
}

.btn-outline-success-opacity-75:hover {
  color: #fff;
  background-color: rgba(43,202,80,0.75);
  border-color: rgba(43,202,80,0.75);
  box-shadow: 0 7px 14px rgba(74,85,104,0.1),0 3px 6px rgba(0,0,0,0.08);
}

.btn-outline-success-opacity-75:focus,
.btn-outline-success-opacity-75.focus {
  box-shadow: 0 2px 3px rgba(74,85,104,0.11);
}

.btn-outline-success-opacity-75.disabled,
.btn-outline-success-opacity-75:disabled {
  color: rgba(43,202,80,0.75);
  background-color: transparent;
}

.btn-outline-success-opacity-75.disabled:hover,
.btn-outline-success-opacity-75:disabled:hover {
  box-shadow: none;
}

.btn-outline-success-opacity-75:not(:disabled):not(.disabled):active,
.btn-outline-success-opacity-75:not(:disabled):not(.disabled).active,
.show>.btn-outline-success-opacity-75.dropdown-toggle {
  color: #fff;
  background-color: rgba(43,202,80,0.75);
  border-color: rgba(43,202,80,0.75);
}

.btn-outline-success-opacity-75:not(:disabled):not(.disabled):active:focus,
.btn-outline-success-opacity-75:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-success-opacity-75.dropdown-toggle:focus {
  box-shadow: 0 2px 3px rgba(74,85,104,0.11);
}

.btn-outline-success-faded {
  color: #D5F4DC;
  border-color: #D5F4DC;
}

.btn-outline-success-faded:hover {
  color: #1A202C;
  background-color: #D5F4DC;
  border-color: #D5F4DC;
  box-shadow: 0 7px 14px rgba(74,85,104,0.1),0 3px 6px rgba(0,0,0,0.08);
}

.btn-outline-success-faded:focus,
.btn-outline-success-faded.focus {
  box-shadow: 0 2px 3px rgba(74,85,104,0.11);
}

.btn-outline-success-faded.disabled,
.btn-outline-success-faded:disabled {
  color: #D5F4DC;
  background-color: transparent;
}

.btn-outline-success-faded.disabled:hover,
.btn-outline-success-faded:disabled:hover {
  box-shadow: none;
}

.btn-outline-success-faded:not(:disabled):not(.disabled):active,
.btn-outline-success-faded:not(:disabled):not(.disabled).active,
.show>.btn-outline-success-faded.dropdown-toggle {
  color: #1A202C;
  background-color: #D5F4DC;
  border-color: #D5F4DC;
}

.btn-outline-success-faded:not(:disabled):not(.disabled):active:focus,
.btn-outline-success-faded:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-success-faded.dropdown-toggle:focus {
  box-shadow: 0 2px 3px rgba(74,85,104,0.11);
}

.btn-outline-info-opacity-25 {
  color: rgba(0,102,255,0.25);
  border-color: rgba(0,102,255,0.25);
}

.btn-outline-info-opacity-25:hover {
  color: #fff;
  background-color: rgba(0,102,255,0.25);
  border-color: rgba(0,102,255,0.25);
  box-shadow: 0 7px 14px rgba(74,85,104,0.1),0 3px 6px rgba(0,0,0,0.08);
}

.btn-outline-info-opacity-25:focus,
.btn-outline-info-opacity-25.focus {
  box-shadow: 0 2px 3px rgba(74,85,104,0.11);
}

.btn-outline-info-opacity-25.disabled,
.btn-outline-info-opacity-25:disabled {
  color: rgba(0,102,255,0.25);
  background-color: transparent;
}

.btn-outline-info-opacity-25.disabled:hover,
.btn-outline-info-opacity-25:disabled:hover {
  box-shadow: none;
}

.btn-outline-info-opacity-25:not(:disabled):not(.disabled):active,
.btn-outline-info-opacity-25:not(:disabled):not(.disabled).active,
.show>.btn-outline-info-opacity-25.dropdown-toggle {
  color: #fff;
  background-color: rgba(0,102,255,0.25);
  border-color: rgba(0,102,255,0.25);
}

.btn-outline-info-opacity-25:not(:disabled):not(.disabled):active:focus,
.btn-outline-info-opacity-25:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-info-opacity-25.dropdown-toggle:focus {
  box-shadow: 0 2px 3px rgba(74,85,104,0.11);
}

.btn-outline-info-opacity-50 {
  color: rgba(0,102,255,0.5);
  border-color: rgba(0,102,255,0.5);
}

.btn-outline-info-opacity-50:hover {
  color: #fff;
  background-color: rgba(0,102,255,0.5);
  border-color: rgba(0,102,255,0.5);
  box-shadow: 0 7px 14px rgba(74,85,104,0.1),0 3px 6px rgba(0,0,0,0.08);
}

.btn-outline-info-opacity-50:focus,
.btn-outline-info-opacity-50.focus {
  box-shadow: 0 2px 3px rgba(74,85,104,0.11);
}

.btn-outline-info-opacity-50.disabled,
.btn-outline-info-opacity-50:disabled {
  color: rgba(0,102,255,0.5);
  background-color: transparent;
}

.btn-outline-info-opacity-50.disabled:hover,
.btn-outline-info-opacity-50:disabled:hover {
  box-shadow: none;
}

.btn-outline-info-opacity-50:not(:disabled):not(.disabled):active,
.btn-outline-info-opacity-50:not(:disabled):not(.disabled).active,
.show>.btn-outline-info-opacity-50.dropdown-toggle {
  color: #fff;
  background-color: rgba(0,102,255,0.5);
  border-color: rgba(0,102,255,0.5);
}

.btn-outline-info-opacity-50:not(:disabled):not(.disabled):active:focus,
.btn-outline-info-opacity-50:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-info-opacity-50.dropdown-toggle:focus {
  box-shadow: 0 2px 3px rgba(74,85,104,0.11);
}

.btn-outline-info-opacity-75 {
  color: rgba(0,102,255,0.75);
  border-color: rgba(0,102,255,0.75);
}

.btn-outline-info-opacity-75:hover {
  color: #fff;
  background-color: rgba(0,102,255,0.75);
  border-color: rgba(0,102,255,0.75);
  box-shadow: 0 7px 14px rgba(74,85,104,0.1),0 3px 6px rgba(0,0,0,0.08);
}

.btn-outline-info-opacity-75:focus,
.btn-outline-info-opacity-75.focus {
  box-shadow: 0 2px 3px rgba(74,85,104,0.11);
}

.btn-outline-info-opacity-75.disabled,
.btn-outline-info-opacity-75:disabled {
  color: rgba(0,102,255,0.75);
  background-color: transparent;
}

.btn-outline-info-opacity-75.disabled:hover,
.btn-outline-info-opacity-75:disabled:hover {
  box-shadow: none;
}

.btn-outline-info-opacity-75:not(:disabled):not(.disabled):active,
.btn-outline-info-opacity-75:not(:disabled):not(.disabled).active,
.show>.btn-outline-info-opacity-75.dropdown-toggle {
  color: #fff;
  background-color: rgba(0,102,255,0.75);
  border-color: rgba(0,102,255,0.75);
}

.btn-outline-info-opacity-75:not(:disabled):not(.disabled):active:focus,
.btn-outline-info-opacity-75:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-info-opacity-75.dropdown-toggle:focus {
  box-shadow: 0 2px 3px rgba(74,85,104,0.11);
}

.btn-outline-info-faded {
  color: #CCE0FF;
  border-color: #CCE0FF;
}

.btn-outline-info-faded:hover {
  color: #1A202C;
  background-color: #CCE0FF;
  border-color: #CCE0FF;
  box-shadow: 0 7px 14px rgba(74,85,104,0.1),0 3px 6px rgba(0,0,0,0.08);
}

.btn-outline-info-faded:focus,
.btn-outline-info-faded.focus {
  box-shadow: 0 2px 3px rgba(74,85,104,0.11);
}

.btn-outline-info-faded.disabled,
.btn-outline-info-faded:disabled {
  color: #CCE0FF;
  background-color: transparent;
}

.btn-outline-info-faded.disabled:hover,
.btn-outline-info-faded:disabled:hover {
  box-shadow: none;
}

.btn-outline-info-faded:not(:disabled):not(.disabled):active,
.btn-outline-info-faded:not(:disabled):not(.disabled).active,
.show>.btn-outline-info-faded.dropdown-toggle {
  color: #1A202C;
  background-color: #CCE0FF;
  border-color: #CCE0FF;
}

.btn-outline-info-faded:not(:disabled):not(.disabled):active:focus,
.btn-outline-info-faded:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-info-faded.dropdown-toggle:focus {
  box-shadow: 0 2px 3px rgba(74,85,104,0.11);
}

.btn-outline-warning-opacity-25 {
  color: rgba(252,161,4,0.25);
  border-color: rgba(252,161,4,0.25);
}

.btn-outline-warning-opacity-25:hover {
  color: #fff;
  background-color: rgba(252,161,4,0.25);
  border-color: rgba(252,161,4,0.25);
  box-shadow: 0 7px 14px rgba(74,85,104,0.1),0 3px 6px rgba(0,0,0,0.08);
}

.btn-outline-warning-opacity-25:focus,
.btn-outline-warning-opacity-25.focus {
  box-shadow: 0 2px 3px rgba(74,85,104,0.11);
}

.btn-outline-warning-opacity-25.disabled,
.btn-outline-warning-opacity-25:disabled {
  color: rgba(252,161,4,0.25);
  background-color: transparent;
}

.btn-outline-warning-opacity-25.disabled:hover,
.btn-outline-warning-opacity-25:disabled:hover {
  box-shadow: none;
}

.btn-outline-warning-opacity-25:not(:disabled):not(.disabled):active,
.btn-outline-warning-opacity-25:not(:disabled):not(.disabled).active,
.show>.btn-outline-warning-opacity-25.dropdown-toggle {
  color: #fff;
  background-color: rgba(252,161,4,0.25);
  border-color: rgba(252,161,4,0.25);
}

.btn-outline-warning-opacity-25:not(:disabled):not(.disabled):active:focus,
.btn-outline-warning-opacity-25:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-warning-opacity-25.dropdown-toggle:focus {
  box-shadow: 0 2px 3px rgba(74,85,104,0.11);
}

.btn-outline-warning-opacity-50 {
  color: rgba(252,161,4,0.5);
  border-color: rgba(252,161,4,0.5);
}

.btn-outline-warning-opacity-50:hover {
  color: #fff;
  background-color: rgba(252,161,4,0.5);
  border-color: rgba(252,161,4,0.5);
  box-shadow: 0 7px 14px rgba(74,85,104,0.1),0 3px 6px rgba(0,0,0,0.08);
}

.btn-outline-warning-opacity-50:focus,
.btn-outline-warning-opacity-50.focus {
  box-shadow: 0 2px 3px rgba(74,85,104,0.11);
}

.btn-outline-warning-opacity-50.disabled,
.btn-outline-warning-opacity-50:disabled {
  color: rgba(252,161,4,0.5);
  background-color: transparent;
}

.btn-outline-warning-opacity-50.disabled:hover,
.btn-outline-warning-opacity-50:disabled:hover {
  box-shadow: none;
}

.btn-outline-warning-opacity-50:not(:disabled):not(.disabled):active,
.btn-outline-warning-opacity-50:not(:disabled):not(.disabled).active,
.show>.btn-outline-warning-opacity-50.dropdown-toggle {
  color: #fff;
  background-color: rgba(252,161,4,0.5);
  border-color: rgba(252,161,4,0.5);
}

.btn-outline-warning-opacity-50:not(:disabled):not(.disabled):active:focus,
.btn-outline-warning-opacity-50:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-warning-opacity-50.dropdown-toggle:focus {
  box-shadow: 0 2px 3px rgba(74,85,104,0.11);
}

.btn-outline-warning-opacity-75 {
  color: rgba(252,161,4,0.75);
  border-color: rgba(252,161,4,0.75);
}

.btn-outline-warning-opacity-75:hover {
  color: #fff;
  background-color: rgba(252,161,4,0.75);
  border-color: rgba(252,161,4,0.75);
  box-shadow: 0 7px 14px rgba(74,85,104,0.1),0 3px 6px rgba(0,0,0,0.08);
}

.btn-outline-warning-opacity-75:focus,
.btn-outline-warning-opacity-75.focus {
  box-shadow: 0 2px 3px rgba(74,85,104,0.11);
}

.btn-outline-warning-opacity-75.disabled,
.btn-outline-warning-opacity-75:disabled {
  color: rgba(252,161,4,0.75);
  background-color: transparent;
}

.btn-outline-warning-opacity-75.disabled:hover,
.btn-outline-warning-opacity-75:disabled:hover {
  box-shadow: none;
}

.btn-outline-warning-opacity-75:not(:disabled):not(.disabled):active,
.btn-outline-warning-opacity-75:not(:disabled):not(.disabled).active,
.show>.btn-outline-warning-opacity-75.dropdown-toggle {
  color: #fff;
  background-color: rgba(252,161,4,0.75);
  border-color: rgba(252,161,4,0.75);
}

.btn-outline-warning-opacity-75:not(:disabled):not(.disabled):active:focus,
.btn-outline-warning-opacity-75:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-warning-opacity-75.dropdown-toggle:focus {
  box-shadow: 0 2px 3px rgba(74,85,104,0.11);
}

.btn-outline-warning-faded {
  color: #FEECCD;
  border-color: #FEECCD;
}

.btn-outline-warning-faded:hover {
  color: #1A202C;
  background-color: #FEECCD;
  border-color: #FEECCD;
  box-shadow: 0 7px 14px rgba(74,85,104,0.1),0 3px 6px rgba(0,0,0,0.08);
}

.btn-outline-warning-faded:focus,
.btn-outline-warning-faded.focus {
  box-shadow: 0 2px 3px rgba(74,85,104,0.11);
}

.btn-outline-warning-faded.disabled,
.btn-outline-warning-faded:disabled {
  color: #FEECCD;
  background-color: transparent;
}

.btn-outline-warning-faded.disabled:hover,
.btn-outline-warning-faded:disabled:hover {
  box-shadow: none;
}

.btn-outline-warning-faded:not(:disabled):not(.disabled):active,
.btn-outline-warning-faded:not(:disabled):not(.disabled).active,
.show>.btn-outline-warning-faded.dropdown-toggle {
  color: #1A202C;
  background-color: #FEECCD;
  border-color: #FEECCD;
}

.btn-outline-warning-faded:not(:disabled):not(.disabled):active:focus,
.btn-outline-warning-faded:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-warning-faded.dropdown-toggle:focus {
  box-shadow: 0 2px 3px rgba(74,85,104,0.11);
}

.btn-outline-danger-opacity-25 {
  color: rgba(244,57,70,0.25);
  border-color: rgba(244,57,70,0.25);
}

.btn-outline-danger-opacity-25:hover {
  color: #fff;
  background-color: rgba(244,57,70,0.25);
  border-color: rgba(244,57,70,0.25);
  box-shadow: 0 7px 14px rgba(74,85,104,0.1),0 3px 6px rgba(0,0,0,0.08);
}

.btn-outline-danger-opacity-25:focus,
.btn-outline-danger-opacity-25.focus {
  box-shadow: 0 2px 3px rgba(74,85,104,0.11);
}

.btn-outline-danger-opacity-25.disabled,
.btn-outline-danger-opacity-25:disabled {
  color: rgba(244,57,70,0.25);
  background-color: transparent;
}

.btn-outline-danger-opacity-25.disabled:hover,
.btn-outline-danger-opacity-25:disabled:hover {
  box-shadow: none;
}

.btn-outline-danger-opacity-25:not(:disabled):not(.disabled):active,
.btn-outline-danger-opacity-25:not(:disabled):not(.disabled).active,
.show>.btn-outline-danger-opacity-25.dropdown-toggle {
  color: #fff;
  background-color: rgba(244,57,70,0.25);
  border-color: rgba(244,57,70,0.25);
}

.btn-outline-danger-opacity-25:not(:disabled):not(.disabled):active:focus,
.btn-outline-danger-opacity-25:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-danger-opacity-25.dropdown-toggle:focus {
  box-shadow: 0 2px 3px rgba(74,85,104,0.11);
}

.btn-outline-danger-opacity-50 {
  color: rgba(244,57,70,0.5);
  border-color: rgba(244,57,70,0.5);
}

.btn-outline-danger-opacity-50:hover {
  color: #fff;
  background-color: rgba(244,57,70,0.5);
  border-color: rgba(244,57,70,0.5);
  box-shadow: 0 7px 14px rgba(74,85,104,0.1),0 3px 6px rgba(0,0,0,0.08);
}

.btn-outline-danger-opacity-50:focus,
.btn-outline-danger-opacity-50.focus {
  box-shadow: 0 2px 3px rgba(74,85,104,0.11);
}

.btn-outline-danger-opacity-50.disabled,
.btn-outline-danger-opacity-50:disabled {
  color: rgba(244,57,70,0.5);
  background-color: transparent;
}

.btn-outline-danger-opacity-50.disabled:hover,
.btn-outline-danger-opacity-50:disabled:hover {
  box-shadow: none;
}

.btn-outline-danger-opacity-50:not(:disabled):not(.disabled):active,
.btn-outline-danger-opacity-50:not(:disabled):not(.disabled).active,
.show>.btn-outline-danger-opacity-50.dropdown-toggle {
  color: #fff;
  background-color: rgba(244,57,70,0.5);
  border-color: rgba(244,57,70,0.5);
}

.btn-outline-danger-opacity-50:not(:disabled):not(.disabled):active:focus,
.btn-outline-danger-opacity-50:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-danger-opacity-50.dropdown-toggle:focus {
  box-shadow: 0 2px 3px rgba(74,85,104,0.11);
}

.btn-outline-danger-opacity-75 {
  color: rgba(244,57,70,0.75);
  border-color: rgba(244,57,70,0.75);
}

.btn-outline-danger-opacity-75:hover {
  color: #fff;
  background-color: rgba(244,57,70,0.75);
  border-color: rgba(244,57,70,0.75);
  box-shadow: 0 7px 14px rgba(74,85,104,0.1),0 3px 6px rgba(0,0,0,0.08);
}

.btn-outline-danger-opacity-75:focus,
.btn-outline-danger-opacity-75.focus {
  box-shadow: 0 2px 3px rgba(74,85,104,0.11);
}

.btn-outline-danger-opacity-75.disabled,
.btn-outline-danger-opacity-75:disabled {
  color: rgba(244,57,70,0.75);
  background-color: transparent;
}

.btn-outline-danger-opacity-75.disabled:hover,
.btn-outline-danger-opacity-75:disabled:hover {
  box-shadow: none;
}

.btn-outline-danger-opacity-75:not(:disabled):not(.disabled):active,
.btn-outline-danger-opacity-75:not(:disabled):not(.disabled).active,
.show>.btn-outline-danger-opacity-75.dropdown-toggle {
  color: #fff;
  background-color: rgba(244,57,70,0.75);
  border-color: rgba(244,57,70,0.75);
}

.btn-outline-danger-opacity-75:not(:disabled):not(.disabled):active:focus,
.btn-outline-danger-opacity-75:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-danger-opacity-75.dropdown-toggle:focus {
  box-shadow: 0 2px 3px rgba(74,85,104,0.11);
}

.btn-outline-danger-faded {
  color: #FDD7DA;
  border-color: #FDD7DA;
}

.btn-outline-danger-faded:hover {
  color: #1A202C;
  background-color: #FDD7DA;
  border-color: #FDD7DA;
  box-shadow: 0 7px 14px rgba(74,85,104,0.1),0 3px 6px rgba(0,0,0,0.08);
}

.btn-outline-danger-faded:focus,
.btn-outline-danger-faded.focus {
  box-shadow: 0 2px 3px rgba(74,85,104,0.11);
}

.btn-outline-danger-faded.disabled,
.btn-outline-danger-faded:disabled {
  color: #FDD7DA;
  background-color: transparent;
}

.btn-outline-danger-faded.disabled:hover,
.btn-outline-danger-faded:disabled:hover {
  box-shadow: none;
}

.btn-outline-danger-faded:not(:disabled):not(.disabled):active,
.btn-outline-danger-faded:not(:disabled):not(.disabled).active,
.show>.btn-outline-danger-faded.dropdown-toggle {
  color: #1A202C;
  background-color: #FDD7DA;
  border-color: #FDD7DA;
}

.btn-outline-danger-faded:not(:disabled):not(.disabled):active:focus,
.btn-outline-danger-faded:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-danger-faded.dropdown-toggle:focus {
  box-shadow: 0 2px 3px rgba(74,85,104,0.11);
}

.btn-link {
  font-weight: 400;
  color: #06f;
  text-decoration: none;
}

.btn-link:hover {
  color: #0047b3;
  text-decoration: underline;
}

.btn-link:focus,
.btn-link.focus {
  text-decoration: underline;
}

.btn-link:disabled,
.btn-link.disabled {
  color: #718096;
  pointer-events: none;
}

.btn-lg {
  padding: .5rem 1rem;
  font-size: 1.24988rem;
  line-height: 1.5;
  border-radius: .375rem;
}

.btn-sm {
  padding: .25rem .5rem;
  font-size: 1.00012rem;
  line-height: 1;
  border-radius: .125rem;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-block+.btn-block {
  margin-top: .5rem;
}

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: .255em;
  vertical-align: .255em;
  content: "";
  border-top: .3em solid;
  border-right: .3em solid transparent;
  border-bottom: 0;
  border-left: .3em solid transparent;
}

.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: .5rem 0;
  margin: .125rem 0 0;
  font-size: 1.125rem;
  color: #4A5568;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0,0,0,0.15);
  border-radius: .375rem;
  box-shadow: 0 0.5rem 1rem rgba(0,0,0,0.175);
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: .125rem;
}

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: .255em;
  vertical-align: .255em;
  content: "";
  border-top: 0;
  border-right: .3em solid transparent;
  border-bottom: .3em solid;
  border-left: .3em solid transparent;
}

.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: .125rem;
}

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: .255em;
  vertical-align: .255em;
  content: "";
  border-top: .3em solid transparent;
  border-right: 0;
  border-bottom: .3em solid transparent;
  border-left: .3em solid;
}

.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: .125rem;
}

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: .255em;
  vertical-align: .255em;
  content: "";
}

.dropleft .dropdown-toggle::after {
  display: none;
}

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: .255em;
  vertical-align: .255em;
  content: "";
  border-top: .3em solid transparent;
  border-right: .3em solid;
  border-bottom: .3em solid transparent;
}

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^="top"],
.dropdown-menu[x-placement^="right"],
.dropdown-menu[x-placement^="bottom"],
.dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: .5rem 0;
  overflow: hidden;
  border-top: 1px solid #EDF2F7;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: .25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #1A202C;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.dropdown-item:hover,
.dropdown-item:focus {
  color: #11141c;
  text-decoration: none;
  background-color: #EDF2F7;
}

.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #06f;
}

.dropdown-item.disabled,
.dropdown-item:disabled {
  color: #A0AEC0;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: .5rem 1.5rem;
  margin-bottom: 0;
  font-size: 1.00012rem;
  color: #718096;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: .25rem 1.5rem;
  color: #1A202C;
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: .5rem 1.25rem;
}

.nav-link:hover,
.nav-link:focus {
  text-decoration: none;
}

.nav-link.disabled {
  color: #718096;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #E2E8F0;
}

.nav-tabs .nav-link {
  margin-bottom: -1px;
  border: 1px solid transparent;
  border-top-left-radius: .375rem;
  border-top-right-radius: .375rem;
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  border-color: #EDF2F7 #EDF2F7 #E2E8F0;
}

.nav-tabs .nav-link.disabled {
  color: #718096;
  background-color: transparent;
  border-color: transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #4A5568;
  background-color: #fff;
  border-color: #E2E8F0 #E2E8F0 #fff;
}

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: .375rem;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  color: #fff;
  background-color: #06f;
}

.nav-fill>.nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified>.nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content>.tab-pane {
  display: none;
}

.tab-content>.active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem 1rem;
}

.navbar .container,
.navbar .container-fluid,
.navbar .container-sm,
.navbar .container-md,
.navbar .container-lg,
.navbar .container-xl {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.navbar-brand {
  display: inline-block;
  padding-top: .40285rem;
  padding-bottom: .40285rem;
  margin-right: 1rem;
  font-size: 1.24988rem;
  line-height: inherit;
  white-space: nowrap;
}

.navbar-brand:hover,
.navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}

.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: .25rem .75rem;
  font-size: 1.24988rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: .25rem;
}

.navbar-toggler:hover,
.navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: 50% / 100% 100% no-repeat;
}

.navbar-nav-scroll {
  max-height: 75vh;
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm>.container,
  .navbar-expand-sm>.container-fluid,
  .navbar-expand-sm>.container-sm,
  .navbar-expand-sm>.container-md,
  .navbar-expand-sm>.container-lg,
  .navbar-expand-sm>.container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: .5rem;
    padding-left: .5rem;
  }

  .navbar-expand-sm>.container,
  .navbar-expand-sm>.container-fluid,
  .navbar-expand-sm>.container-sm,
  .navbar-expand-sm>.container-md,
  .navbar-expand-sm>.container-lg,
  .navbar-expand-sm>.container-xl {
    flex-wrap: nowrap;
  }

  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .navbar-expand-md>.container,
  .navbar-expand-md>.container-fluid,
  .navbar-expand-md>.container-sm,
  .navbar-expand-md>.container-md,
  .navbar-expand-md>.container-lg,
  .navbar-expand-md>.container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: .5rem;
    padding-left: .5rem;
  }

  .navbar-expand-md>.container,
  .navbar-expand-md>.container-fluid,
  .navbar-expand-md>.container-sm,
  .navbar-expand-md>.container-md,
  .navbar-expand-md>.container-lg,
  .navbar-expand-md>.container-xl {
    flex-wrap: nowrap;
  }

  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .navbar-expand-lg>.container,
  .navbar-expand-lg>.container-fluid,
  .navbar-expand-lg>.container-sm,
  .navbar-expand-lg>.container-md,
  .navbar-expand-lg>.container-lg,
  .navbar-expand-lg>.container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: .5rem;
    padding-left: .5rem;
  }

  .navbar-expand-lg>.container,
  .navbar-expand-lg>.container-fluid,
  .navbar-expand-lg>.container-sm,
  .navbar-expand-lg>.container-md,
  .navbar-expand-lg>.container-lg,
  .navbar-expand-lg>.container-xl {
    flex-wrap: nowrap;
  }

  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}

@media (max-width: 1199.98px) {
  .navbar-expand-xl>.container,
  .navbar-expand-xl>.container-fluid,
  .navbar-expand-xl>.container-sm,
  .navbar-expand-xl>.container-md,
  .navbar-expand-xl>.container-lg,
  .navbar-expand-xl>.container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: .5rem;
    padding-left: .5rem;
  }

  .navbar-expand-xl>.container,
  .navbar-expand-xl>.container-fluid,
  .navbar-expand-xl>.container-sm,
  .navbar-expand-xl>.container-md,
  .navbar-expand-xl>.container-lg,
  .navbar-expand-xl>.container-xl {
    flex-wrap: nowrap;
  }

  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}

.navbar-expand>.container,
.navbar-expand>.container-fluid,
.navbar-expand>.container-sm,
.navbar-expand>.container-md,
.navbar-expand>.container-lg,
.navbar-expand>.container-xl {
  padding-right: 0;
  padding-left: 0;
}

.navbar-expand .navbar-nav {
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: .5rem;
  padding-left: .5rem;
}

.navbar-expand>.container,
.navbar-expand>.container-fluid,
.navbar-expand>.container-sm,
.navbar-expand>.container-md,
.navbar-expand>.container-lg,
.navbar-expand>.container-xl {
  flex-wrap: nowrap;
}

.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}

.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: #06f;
}

.navbar-light .navbar-brand:hover,
.navbar-light .navbar-brand:focus {
  color: #06f;
}

.navbar-light .navbar-nav .nav-link {
  color: #1A202C;
}

.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus {
  color: #1045DB;
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0,0,0,0.3);
}

.navbar-light .navbar-nav .show>.nav-link,
.navbar-light .navbar-nav .active>.nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: #06f;
}

.navbar-light .navbar-toggler {
  color: #1A202C;
  border-color: rgba(0,0,0,0.1);
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='%231A202C' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-light .navbar-text {
  color: #1A202C;
}

.navbar-light .navbar-text a {
  color: #06f;
}

.navbar-light .navbar-text a:hover,
.navbar-light .navbar-text a:focus {
  color: #06f;
}

.navbar-dark .navbar-brand {
  color: #fff;
}

.navbar-dark .navbar-brand:hover,
.navbar-dark .navbar-brand:focus {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255,255,255,0.5);
}

.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255,255,255,0.75);
}

.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255,255,255,0.25);
}

.navbar-dark .navbar-nav .show>.nav-link,
.navbar-dark .navbar-nav .active>.nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}

.navbar-dark .navbar-toggler {
  color: rgba(255,255,255,0.5);
  border-color: rgba(255,255,255,0.1);
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255,255,255,0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-dark .navbar-text {
  color: rgba(255,255,255,0.5);
}

.navbar-dark .navbar-text a {
  color: #fff;
}

.navbar-dark .navbar-text a:hover,
.navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0,0,0,0.125);
  border-radius: .375rem;
}

.card>hr {
  margin-right: 0;
  margin-left: 0;
}

.card>.list-group {
  border-top: inherit;
  border-bottom: inherit;
}

.card>.list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(.375rem - 1px);
  border-top-right-radius: calc(.375rem - 1px);
}

.card>.list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(.375rem - 1px);
  border-bottom-left-radius: calc(.375rem - 1px);
}

.card>.card-header+.list-group,
.card>.list-group+.card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: .75rem;
}

.card-subtitle {
  margin-top: -.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}

.card-link+.card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: .75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0,0,0,0.03);
  border-bottom: 1px solid rgba(0,0,0,0.125);
}

.card-header:first-child {
  border-radius: calc(.375rem - 1px) calc(.375rem - 1px) 0 0;
}

.card-footer {
  padding: .75rem 1.25rem;
  background-color: rgba(0,0,0,0.03);
  border-top: 1px solid rgba(0,0,0,0.125);
}

.card-footer:last-child {
  border-radius: 0 0 calc(.375rem - 1px) calc(.375rem - 1px);
}

.card-header-tabs {
  margin-right: -.625rem;
  margin-bottom: -.75rem;
  margin-left: -.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -.625rem;
  margin-left: -.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: calc(.375rem - 1px);
}

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(.375rem - 1px);
  border-top-right-radius: calc(.375rem - 1px);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(.375rem - 1px);
  border-bottom-left-radius: calc(.375rem - 1px);
}

.card-deck .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }

  .card-deck .card {
    flex: 1 0 0%;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.card-group>.card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }

  .card-group>.card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }

  .card-group>.card+.card {
    margin-left: 0;
    border-left: 0;
  }

  .card-group>.card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .card-group>.card:not(:last-child) .card-img-top,
  .card-group>.card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }

  .card-group>.card:not(:last-child) .card-img-bottom,
  .card-group>.card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }

  .card-group>.card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .card-group>.card:not(:first-child) .card-img-top,
  .card-group>.card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }

  .card-group>.card:not(:first-child) .card-img-bottom,
  .card-group>.card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: .75rem;
}

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }

  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion {
  overflow-anchor: none;
}

.accordion>.card {
  overflow: hidden;
}

.accordion>.card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.accordion>.card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.accordion>.card>.card-header {
  border-radius: 0;
  margin-bottom: -1px;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: .375rem;
}

.page-link {
  position: relative;
  display: block;
  padding: .5rem .75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #06f;
  background-color: #fff;
  border: 1px solid #E2E8F0;
}

.page-link:hover {
  z-index: 2;
  color: #0047b3;
  text-decoration: none;
  background-color: #EDF2F7;
  border-color: #E2E8F0;
}

.page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: 0 0 0 0 rgba(0,102,255,0.25);
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: .375rem;
  border-bottom-left-radius: .375rem;
}

.page-item:last-child .page-link {
  border-top-right-radius: .375rem;
  border-bottom-right-radius: .375rem;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #06f;
  border-color: #06f;
}

.page-item.disabled .page-link {
  color: #718096;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #E2E8F0;
}

.pagination-lg .page-link {
  padding: .75rem 1.5rem;
  font-size: 1.24988rem;
  line-height: 1.5;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: .75rem;
  border-bottom-left-radius: .75rem;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: .75rem;
  border-bottom-right-radius: .75rem;
}

.pagination-sm .page-link {
  padding: .25rem .5rem;
  font-size: 1.00012rem;
  line-height: 1.5;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: .1875rem;
  border-bottom-left-radius: .1875rem;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: .1875rem;
  border-bottom-right-radius: .1875rem;
}

.badge {
  display: inline-block;
  padding: .3em .4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .375rem;
  transition: color 0.15s ease-in-out,background-color 0.15s ease-in-out,border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}

a.badge:hover,
a.badge:focus {
  text-decoration: none;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: .6em;
  padding-left: .6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #fff;
  background-color: #06f;
}

a.badge-primary:hover,
a.badge-primary:focus {
  color: #fff;
  background-color: #0052cc;
}

a.badge-primary:focus,
a.badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(0,102,255,0.5);
}

.badge-secondary {
  color: #fff;
  background-color: #76818C;
}

a.badge-secondary:hover,
a.badge-secondary:focus {
  color: #fff;
  background-color: #5e6871;
}

a.badge-secondary:focus,
a.badge-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(118,129,140,0.5);
}

.badge-success {
  color: #fff;
  background-color: #2BCA50;
}

a.badge-success:hover,
a.badge-success:focus {
  color: #fff;
  background-color: #22a03f;
}

a.badge-success:focus,
a.badge-success.focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(43,202,80,0.5);
}

.badge-info {
  color: #fff;
  background-color: #06f;
}

a.badge-info:hover,
a.badge-info:focus {
  color: #fff;
  background-color: #0052cc;
}

a.badge-info:focus,
a.badge-info.focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(0,102,255,0.5);
}

.badge-warning {
  color: #fff;
  background-color: #FCA104;
}

a.badge-warning:hover,
a.badge-warning:focus {
  color: #fff;
  background-color: #cb8102;
}

a.badge-warning:focus,
a.badge-warning.focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(252,161,4,0.5);
}

.badge-danger {
  color: #fff;
  background-color: #F43946;
}

a.badge-danger:hover,
a.badge-danger:focus {
  color: #fff;
  background-color: #ed0d1d;
}

a.badge-danger:focus,
a.badge-danger.focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(244,57,70,0.5);
}

.badge-light {
  color: #1A202C;
  background-color: #EDF2F7;
}

a.badge-light:hover,
a.badge-light:focus {
  color: #1A202C;
  background-color: #cad9e7;
}

a.badge-light:focus,
a.badge-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(237,242,247,0.5);
}

.badge-dark {
  color: #fff;
  background-color: #2D3748;
}

a.badge-dark:hover,
a.badge-dark:focus {
  color: #fff;
  background-color: #191f29;
}

a.badge-dark:focus,
a.badge-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(45,55,72,0.5);
}

.badge-primary-opacity-25 {
  color: #fff;
  background-color: rgba(0,102,255,0.25);
}

a.badge-primary-opacity-25:hover,
a.badge-primary-opacity-25:focus {
  color: #fff;
  background-color: rgba(0,82,204,0.25);
}

a.badge-primary-opacity-25:focus,
a.badge-primary-opacity-25.focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(0,102,255,0.5);
}

.badge-primary-opacity-50 {
  color: #fff;
  background-color: rgba(0,102,255,0.5);
}

a.badge-primary-opacity-50:hover,
a.badge-primary-opacity-50:focus {
  color: #fff;
  background-color: rgba(0,82,204,0.5);
}

a.badge-primary-opacity-50:focus,
a.badge-primary-opacity-50.focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(0,102,255,0.5);
}

.badge-primary-opacity-75 {
  color: #fff;
  background-color: rgba(0,102,255,0.75);
}

a.badge-primary-opacity-75:hover,
a.badge-primary-opacity-75:focus {
  color: #fff;
  background-color: rgba(0,82,204,0.75);
}

a.badge-primary-opacity-75:focus,
a.badge-primary-opacity-75.focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(0,102,255,0.5);
}

.badge-primary-faded {
  color: #1A202C;
  background-color: #CCE0FF;
}

a.badge-primary-faded:hover,
a.badge-primary-faded:focus {
  color: #1A202C;
  background-color: #99c1ff;
}

a.badge-primary-faded:focus,
a.badge-primary-faded.focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(204,224,255,0.5);
}

.badge-secondary-opacity-25 {
  color: #fff;
  background-color: rgba(118,129,140,0.25);
}

a.badge-secondary-opacity-25:hover,
a.badge-secondary-opacity-25:focus {
  color: #fff;
  background-color: rgba(94,104,113,0.25);
}

a.badge-secondary-opacity-25:focus,
a.badge-secondary-opacity-25.focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(118,129,140,0.5);
}

.badge-secondary-opacity-50 {
  color: #fff;
  background-color: rgba(118,129,140,0.5);
}

a.badge-secondary-opacity-50:hover,
a.badge-secondary-opacity-50:focus {
  color: #fff;
  background-color: rgba(94,104,113,0.5);
}

a.badge-secondary-opacity-50:focus,
a.badge-secondary-opacity-50.focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(118,129,140,0.5);
}

.badge-secondary-opacity-75 {
  color: #fff;
  background-color: rgba(118,129,140,0.75);
}

a.badge-secondary-opacity-75:hover,
a.badge-secondary-opacity-75:focus {
  color: #fff;
  background-color: rgba(94,104,113,0.75);
}

a.badge-secondary-opacity-75:focus,
a.badge-secondary-opacity-75.focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(118,129,140,0.5);
}

.badge-success-opacity-25 {
  color: #fff;
  background-color: rgba(43,202,80,0.25);
}

a.badge-success-opacity-25:hover,
a.badge-success-opacity-25:focus {
  color: #fff;
  background-color: rgba(34,160,63,0.25);
}

a.badge-success-opacity-25:focus,
a.badge-success-opacity-25.focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(43,202,80,0.5);
}

.badge-success-opacity-50 {
  color: #fff;
  background-color: rgba(43,202,80,0.5);
}

a.badge-success-opacity-50:hover,
a.badge-success-opacity-50:focus {
  color: #fff;
  background-color: rgba(34,160,63,0.5);
}

a.badge-success-opacity-50:focus,
a.badge-success-opacity-50.focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(43,202,80,0.5);
}

.badge-success-opacity-75 {
  color: #fff;
  background-color: rgba(43,202,80,0.75);
}

a.badge-success-opacity-75:hover,
a.badge-success-opacity-75:focus {
  color: #fff;
  background-color: rgba(34,160,63,0.75);
}

a.badge-success-opacity-75:focus,
a.badge-success-opacity-75.focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(43,202,80,0.5);
}

.badge-success-faded {
  color: #1A202C;
  background-color: #D5F4DC;
}

a.badge-success-faded:hover,
a.badge-success-faded:focus {
  color: #1A202C;
  background-color: #ade9ba;
}

a.badge-success-faded:focus,
a.badge-success-faded.focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(213,244,220,0.5);
}

.badge-info-opacity-25 {
  color: #fff;
  background-color: rgba(0,102,255,0.25);
}

a.badge-info-opacity-25:hover,
a.badge-info-opacity-25:focus {
  color: #fff;
  background-color: rgba(0,82,204,0.25);
}

a.badge-info-opacity-25:focus,
a.badge-info-opacity-25.focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(0,102,255,0.5);
}

.badge-info-opacity-50 {
  color: #fff;
  background-color: rgba(0,102,255,0.5);
}

a.badge-info-opacity-50:hover,
a.badge-info-opacity-50:focus {
  color: #fff;
  background-color: rgba(0,82,204,0.5);
}

a.badge-info-opacity-50:focus,
a.badge-info-opacity-50.focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(0,102,255,0.5);
}

.badge-info-opacity-75 {
  color: #fff;
  background-color: rgba(0,102,255,0.75);
}

a.badge-info-opacity-75:hover,
a.badge-info-opacity-75:focus {
  color: #fff;
  background-color: rgba(0,82,204,0.75);
}

a.badge-info-opacity-75:focus,
a.badge-info-opacity-75.focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(0,102,255,0.5);
}

.badge-info-faded {
  color: #1A202C;
  background-color: #CCE0FF;
}

a.badge-info-faded:hover,
a.badge-info-faded:focus {
  color: #1A202C;
  background-color: #99c1ff;
}

a.badge-info-faded:focus,
a.badge-info-faded.focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(204,224,255,0.5);
}

.badge-warning-opacity-25 {
  color: #fff;
  background-color: rgba(252,161,4,0.25);
}

a.badge-warning-opacity-25:hover,
a.badge-warning-opacity-25:focus {
  color: #fff;
  background-color: rgba(203,129,2,0.25);
}

a.badge-warning-opacity-25:focus,
a.badge-warning-opacity-25.focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(252,161,4,0.5);
}

.badge-warning-opacity-50 {
  color: #fff;
  background-color: rgba(252,161,4,0.5);
}

a.badge-warning-opacity-50:hover,
a.badge-warning-opacity-50:focus {
  color: #fff;
  background-color: rgba(203,129,2,0.5);
}

a.badge-warning-opacity-50:focus,
a.badge-warning-opacity-50.focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(252,161,4,0.5);
}

.badge-warning-opacity-75 {
  color: #fff;
  background-color: rgba(252,161,4,0.75);
}

a.badge-warning-opacity-75:hover,
a.badge-warning-opacity-75:focus {
  color: #fff;
  background-color: rgba(203,129,2,0.75);
}

a.badge-warning-opacity-75:focus,
a.badge-warning-opacity-75.focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(252,161,4,0.5);
}

.badge-warning-faded {
  color: #1A202C;
  background-color: #FEECCD;
}

a.badge-warning-faded:hover,
a.badge-warning-faded:focus {
  color: #1A202C;
  background-color: #fdd99b;
}

a.badge-warning-faded:focus,
a.badge-warning-faded.focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(254,236,205,0.5);
}

.badge-danger-opacity-25 {
  color: #fff;
  background-color: rgba(244,57,70,0.25);
}

a.badge-danger-opacity-25:hover,
a.badge-danger-opacity-25:focus {
  color: #fff;
  background-color: rgba(237,13,29,0.25);
}

a.badge-danger-opacity-25:focus,
a.badge-danger-opacity-25.focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(244,57,70,0.5);
}

.badge-danger-opacity-50 {
  color: #fff;
  background-color: rgba(244,57,70,0.5);
}

a.badge-danger-opacity-50:hover,
a.badge-danger-opacity-50:focus {
  color: #fff;
  background-color: rgba(237,13,29,0.5);
}

a.badge-danger-opacity-50:focus,
a.badge-danger-opacity-50.focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(244,57,70,0.5);
}

.badge-danger-opacity-75 {
  color: #fff;
  background-color: rgba(244,57,70,0.75);
}

a.badge-danger-opacity-75:hover,
a.badge-danger-opacity-75:focus {
  color: #fff;
  background-color: rgba(237,13,29,0.75);
}

a.badge-danger-opacity-75:focus,
a.badge-danger-opacity-75.focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(244,57,70,0.5);
}

.badge-danger-faded {
  color: #1A202C;
  background-color: #FDD7DA;
}

a.badge-danger-faded:hover,
a.badge-danger-faded:focus {
  color: #1A202C;
  background-color: #fba6ad;
}

a.badge-danger-faded:focus,
a.badge-danger-faded.focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(253,215,218,0.5);
}

.alert {
  position: relative;
  padding: .75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: .375rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 4.1875rem;
}

.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: .75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #003585;
  background-color: #cce0ff;
  border-color: #b8d4ff;
}

.alert-primary hr {
  border-top-color: #9fc5ff;
}

.alert-primary .alert-link {
  color: #002152;
}

.alert-secondary {
  color: #3d4349;
  background-color: #e4e6e8;
  border-color: #d9dcdf;
}

.alert-secondary hr {
  border-top-color: #cbcfd3;
}

.alert-secondary .alert-link {
  color: #262a2d;
}

.alert-success {
  color: #16692a;
  background-color: #d5f4dc;
  border-color: #c4f0ce;
}

.alert-success hr {
  border-top-color: #b0ebbd;
}

.alert-success .alert-link {
  color: #0d3f19;
}

.alert-info {
  color: #003585;
  background-color: #cce0ff;
  border-color: #b8d4ff;
}

.alert-info hr {
  border-top-color: #9fc5ff;
}

.alert-info .alert-link {
  color: #002152;
}

.alert-warning {
  color: #835402;
  background-color: #feeccd;
  border-color: #fee5b9;
}

.alert-warning hr {
  border-top-color: #fedca0;
}

.alert-warning .alert-link {
  color: #513401;
}

.alert-danger {
  color: #7f1e24;
  background-color: #fdd7da;
  border-color: #fcc8cb;
}

.alert-danger hr {
  border-top-color: #fbb0b4;
}

.alert-danger .alert-link {
  color: #561418;
}

.alert-light {
  color: #7b7e80;
  background-color: #fbfcfd;
  border-color: #fafbfd;
}

.alert-light hr {
  border-top-color: #e8ecf6;
}

.alert-light .alert-link {
  color: #626466;
}

.alert-dark {
  color: #171d25;
  background-color: #d5d7da;
  border-color: #c4c7cc;
}

.alert-dark hr {
  border-top-color: #b6bac0;
}

.alert-dark .alert-link {
  color: #030406;
}

.alert-primary-opacity-25 {
  color: rgba(0,14,34,0.61);
  background-color: rgba(246,250,255,0.85);
  border-color: rgba(242,247,255,0.79);
}

.alert-primary-opacity-25 hr {
  border-top-color: rgba(217,231,255,0.79);
}

.alert-primary-opacity-25 .alert-link {
  color: rgba(0,0,0,0.61);
}

.alert-primary-opacity-50 {
  color: rgba(0,27,68,0.74);
  background-color: rgba(235,243,255,0.9);
  border-color: rgba(226,237,255,0.86);
}

.alert-primary-opacity-50 hr {
  border-top-color: rgba(201,221,255,0.86);
}

.alert-primary-opacity-50 .alert-link {
  color: rgba(0,7,17,0.74);
}

.alert-primary-opacity-75 {
  color: rgba(0,40,100,0.87);
  background-color: rgba(222,235,255,0.95);
  border-color: rgba(207,226,255,0.93);
}

.alert-primary-opacity-75 hr {
  border-top-color: rgba(182,211,255,0.93);
}

.alert-primary-opacity-75 .alert-link {
  color: rgba(0,20,49,0.87);
}

.alert-primary-faded {
  color: #6a7485;
  background-color: #f5f9ff;
  border-color: #f1f6ff;
}

.alert-primary-faded hr {
  border-top-color: #d8e6ff;
}

.alert-primary-faded .alert-link {
  color: #535b69;
}

.alert-secondary-opacity-25 {
  color: rgba(16,17,19,0.61);
  background-color: rgba(250,251,251,0.85);
  border-color: rgba(248,248,249,0.79);
}

.alert-secondary-opacity-25 hr {
  border-top-color: rgba(234,234,237,0.79);
}

.alert-secondary-opacity-25 .alert-link {
  color: rgba(0,0,0,0.61);
}

.alert-secondary-opacity-50 {
  color: rgba(31,34,37,0.74);
  background-color: rgba(244,245,246,0.9);
  border-color: rgba(239,241,242,0.86);
}

.alert-secondary-opacity-50 hr {
  border-top-color: rgba(225,229,231,0.86);
}

.alert-secondary-opacity-50 .alert-link {
  color: rgba(8,9,9,0.74);
}

.alert-secondary-opacity-75 {
  color: rgba(46,51,55,0.87);
  background-color: rgba(237,239,240,0.95);
  border-color: rgba(229,231,233,0.93);
}

.alert-secondary-opacity-75 hr {
  border-top-color: rgba(215,218,221,0.93);
}

.alert-secondary-opacity-75 .alert-link {
  color: rgba(23,25,27,0.87);
}

.alert-success-opacity-25 {
  color: rgba(6,27,11,0.61);
  background-color: rgba(248,253,249,0.85);
  border-color: rgba(244,252,246,0.79);
}

.alert-success-opacity-25 hr {
  border-top-color: rgba(224,247,230,0.79);
}

.alert-success-opacity-25 .alert-link {
  color: rgba(0,0,0,0.61);
}

.alert-success-opacity-50 {
  color: rgba(11,54,21,0.74);
  background-color: rgba(239,251,242,0.9);
  border-color: rgba(231,249,235,0.86);
}

.alert-success-opacity-50 hr {
  border-top-color: rgba(211,244,218,0.86);
}

.alert-success-opacity-50 .alert-link {
  color: rgba(2,12,5,0.74);
}

.alert-success-opacity-75 {
  color: rgba(17,80,32,0.87);
  background-color: rgba(227,248,232,0.95);
  border-color: rgba(215,245,222,0.93);
}

.alert-success-opacity-75 hr {
  border-top-color: rgba(195,240,205,0.93);
}

.alert-success-opacity-75 .alert-link {
  color: rgba(8,38,15,0.87);
}

.alert-success-faded {
  color: #6f7f72;
  background-color: #f7fdf8;
  border-color: #f3fcf5;
}

.alert-success-faded hr {
  border-top-color: #dff7e4;
}

.alert-success-faded .alert-link {
  color: #57645a;
}

.alert-info-opacity-25 {
  color: rgba(0,14,34,0.61);
  background-color: rgba(246,250,255,0.85);
  border-color: rgba(242,247,255,0.79);
}

.alert-info-opacity-25 hr {
  border-top-color: rgba(217,231,255,0.79);
}

.alert-info-opacity-25 .alert-link {
  color: rgba(0,0,0,0.61);
}

.alert-info-opacity-50 {
  color: rgba(0,27,68,0.74);
  background-color: rgba(235,243,255,0.9);
  border-color: rgba(226,237,255,0.86);
}

.alert-info-opacity-50 hr {
  border-top-color: rgba(201,221,255,0.86);
}

.alert-info-opacity-50 .alert-link {
  color: rgba(0,7,17,0.74);
}

.alert-info-opacity-75 {
  color: rgba(0,40,100,0.87);
  background-color: rgba(222,235,255,0.95);
  border-color: rgba(207,226,255,0.93);
}

.alert-info-opacity-75 hr {
  border-top-color: rgba(182,211,255,0.93);
}

.alert-info-opacity-75 .alert-link {
  color: rgba(0,20,49,0.87);
}

.alert-info-faded {
  color: #6a7485;
  background-color: #f5f9ff;
  border-color: #f1f6ff;
}

.alert-info-faded hr {
  border-top-color: #d8e6ff;
}

.alert-info-faded .alert-link {
  color: #535b69;
}

.alert-warning-opacity-25 {
  color: rgba(34,22,1,0.61);
  background-color: rgba(255,252,246,0.85);
  border-color: rgba(255,250,242,0.79);
}

.alert-warning-opacity-25 hr {
  border-top-color: rgba(255,240,217,0.79);
}

.alert-warning-opacity-25 .alert-link {
  color: rgba(0,0,0,0.61);
}

.alert-warning-opacity-50 {
  color: rgba(67,43,1,0.74);
  background-color: rgba(255,248,236,0.9);
  border-color: rgba(255,244,226,0.86);
}

.alert-warning-opacity-50 hr {
  border-top-color: rgba(255,234,201,0.86);
}

.alert-warning-opacity-50 .alert-link {
  color: rgba(17,11,0,0.74);
}

.alert-warning-opacity-75 {
  color: rgba(99,63,2,0.87);
  background-color: rgba(255,243,222,0.95);
  border-color: rgba(254,237,208,0.93);
}

.alert-warning-opacity-75 hr {
  border-top-color: rgba(253,227,183,0.93);
}

.alert-warning-opacity-75 .alert-link {
  color: rgba(49,31,1,0.87);
}

.alert-warning-faded {
  color: #847b6b;
  background-color: #fffbf5;
  border-color: #fffaf1;
}

.alert-warning-faded hr {
  border-top-color: #fff1d8;
}

.alert-warning-faded .alert-link {
  color: #686154;
}

.alert-danger-opacity-25 {
  color: rgba(33,8,9,0.61);
  background-color: rgba(255,248,249,0.85);
  border-color: rgba(254,245,245,0.79);
}

.alert-danger-opacity-25 hr {
  border-top-color: rgba(252,222,222,0.79);
}

.alert-danger-opacity-25 .alert-link {
  color: rgba(0,0,0,0.61);
}

.alert-danger-opacity-50 {
  color: rgba(65,15,19,0.74);
  background-color: rgba(254,240,241,0.9);
  border-color: rgba(254,232,234,0.86);
}

.alert-danger-opacity-50 hr {
  border-top-color: rgba(253,208,212,0.86);
}

.alert-danger-opacity-50 .alert-link {
  color: rgba(24,5,7,0.74);
}

.alert-danger-opacity-75 {
  color: rgba(96,22,28,0.87);
  background-color: rgba(254,229,231,0.95);
  border-color: rgba(253,218,220,0.93);
}

.alert-danger-opacity-75 hr {
  border-top-color: rgba(252,194,197,0.93);
}

.alert-danger-opacity-75 .alert-link {
  color: rgba(55,12,16,0.87);
}

.alert-danger-faded {
  color: #847071;
  background-color: #fff7f8;
  border-color: #fef4f5;
}

.alert-danger-faded hr {
  border-top-color: #fcdde0;
}

.alert-danger-faded .alert-link {
  color: #685959;
}

.close {
  float: right;
  font-size: 1.6875rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: none;
  opacity: .5;
}

@media (max-width: 1200px) {
  .close {
    font-size: calc(1.29375rem + .525vw);
  }
}

.close:hover {
  color: #000;
  text-decoration: none;
}

.close:not(:disabled):not(.disabled):hover,
.close:not(:disabled):not(.disabled):focus {
  opacity: .75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}

a.close.disabled {
  pointer-events: none;
}

.toast {
  flex-basis: 350px;
  max-width: 350px;
  font-size: .875rem;
  background-color: rgba(255,255,255,0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0,0,0,0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0,0,0,0.1);
  opacity: 0;
  border-radius: .25rem;
}

.toast:not(:last-child) {
  margin-bottom: .75rem;
}

.toast.showing {
  opacity: 1;
}

.toast.show {
  display: block;
  opacity: 1;
}

.toast.hide {
  display: none;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: .25rem .75rem;
  color: #718096;
  background-color: rgba(255,255,255,0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0,0,0,0.05);
  border-top-left-radius: calc(.25rem - 1px);
  border-top-right-radius: calc(.25rem - 1px);
}

.toast-body {
  padding: .75rem;
}

.modal-open {
  overflow: hidden;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: .5rem;
  pointer-events: none;
}

.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  transform: none;
}

.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}

.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}

.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  height: min-content;
  content: "";
}

.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}

.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0,0,0,0.2);
  border-radius: .75rem;
  box-shadow: 0 0.25rem 0.5rem rgba(0,0,0,0.5);
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: .5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #CBD5E0;
  border-top-left-radius: calc(.75rem - 1px);
  border-top-right-radius: calc(.75rem - 1px);
}

.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.556;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: .75rem;
  border-top: 1px solid #CBD5E0;
  border-bottom-right-radius: calc(.75rem - 1px);
  border-bottom-left-radius: calc(.75rem - 1px);
}

.modal-footer>* {
  margin: .25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }

  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }

  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
    height: min-content;
  }

  .modal-content {
    box-shadow: 0 0.5rem 1rem rgba(0,0,0,0.5);
  }

  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "Roboto",sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.556;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 1.00012rem;
  word-wrap: break-word;
  opacity: 0;
}

.tooltip.show {
  opacity: .9;
}

.tooltip .arrow {
  position: absolute;
  display: block;
  width: .8rem;
  height: .4rem;
}

.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top,
.bs-tooltip-auto[x-placement^="top"] {
  padding: .4rem 0;
}

.bs-tooltip-top .arrow,
.bs-tooltip-auto[x-placement^="top"] .arrow {
  bottom: 0;
}

.bs-tooltip-top .arrow::before,
.bs-tooltip-auto[x-placement^="top"] .arrow::before {
  top: 0;
  border-width: .4rem .4rem 0;
  border-top-color: #000;
}

.bs-tooltip-right,
.bs-tooltip-auto[x-placement^="right"] {
  padding: 0 .4rem;
}

.bs-tooltip-right .arrow,
.bs-tooltip-auto[x-placement^="right"] .arrow {
  left: 0;
  width: .4rem;
  height: .8rem;
}

.bs-tooltip-right .arrow::before,
.bs-tooltip-auto[x-placement^="right"] .arrow::before {
  right: 0;
  border-width: .4rem .4rem .4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom,
.bs-tooltip-auto[x-placement^="bottom"] {
  padding: .4rem 0;
}

.bs-tooltip-bottom .arrow,
.bs-tooltip-auto[x-placement^="bottom"] .arrow {
  top: 0;
}

.bs-tooltip-bottom .arrow::before,
.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  bottom: 0;
  border-width: 0 .4rem .4rem;
  border-bottom-color: #000;
}

.bs-tooltip-left,
.bs-tooltip-auto[x-placement^="left"] {
  padding: 0 .4rem;
}

.bs-tooltip-left .arrow,
.bs-tooltip-auto[x-placement^="left"] .arrow {
  right: 0;
  width: .4rem;
  height: .8rem;
}

.bs-tooltip-left .arrow::before,
.bs-tooltip-auto[x-placement^="left"] .arrow::before {
  left: 0;
  border-width: .4rem 0 .4rem .4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: .25rem .5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: .375rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "Roboto",sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.556;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 1.00012rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0,0,0,0.2);
  border-radius: .75rem;
  box-shadow: 0 0.25rem 0.5rem rgba(0,0,0,0.2);
}

.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: .5rem;
  margin: 0 .75rem;
}

.popover .arrow::before,
.popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top,
.bs-popover-auto[x-placement^="top"] {
  margin-bottom: .5rem;
}

.bs-popover-top>.arrow,
.bs-popover-auto[x-placement^="top"]>.arrow {
  bottom: calc(-.5rem - 1px);
}

.bs-popover-top>.arrow::before,
.bs-popover-auto[x-placement^="top"]>.arrow::before {
  bottom: 0;
  border-width: .5rem .5rem 0;
  border-top-color: rgba(0,0,0,0.25);
}

.bs-popover-top>.arrow::after,
.bs-popover-auto[x-placement^="top"]>.arrow::after {
  bottom: 1px;
  border-width: .5rem .5rem 0;
  border-top-color: #fff;
}

.bs-popover-right,
.bs-popover-auto[x-placement^="right"] {
  margin-left: .5rem;
}

.bs-popover-right>.arrow,
.bs-popover-auto[x-placement^="right"]>.arrow {
  left: calc(-.5rem - 1px);
  width: .5rem;
  height: 1rem;
  margin: .75rem 0;
}

.bs-popover-right>.arrow::before,
.bs-popover-auto[x-placement^="right"]>.arrow::before {
  left: 0;
  border-width: .5rem .5rem .5rem 0;
  border-right-color: rgba(0,0,0,0.25);
}

.bs-popover-right>.arrow::after,
.bs-popover-auto[x-placement^="right"]>.arrow::after {
  left: 1px;
  border-width: .5rem .5rem .5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom,
.bs-popover-auto[x-placement^="bottom"] {
  margin-top: .5rem;
}

.bs-popover-bottom>.arrow,
.bs-popover-auto[x-placement^="bottom"]>.arrow {
  top: calc(-.5rem - 1px);
}

.bs-popover-bottom>.arrow::before,
.bs-popover-auto[x-placement^="bottom"]>.arrow::before {
  top: 0;
  border-width: 0 .5rem .5rem .5rem;
  border-bottom-color: rgba(0,0,0,0.25);
}

.bs-popover-bottom>.arrow::after,
.bs-popover-auto[x-placement^="bottom"]>.arrow::after {
  top: 1px;
  border-width: 0 .5rem .5rem .5rem;
  border-bottom-color: #fff;
}

.bs-popover-bottom .popover-header::before,
.bs-popover-auto[x-placement^="bottom"] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left,
.bs-popover-auto[x-placement^="left"] {
  margin-right: .5rem;
}

.bs-popover-left>.arrow,
.bs-popover-auto[x-placement^="left"]>.arrow {
  right: calc(-.5rem - 1px);
  width: .5rem;
  height: 1rem;
  margin: .75rem 0;
}

.bs-popover-left>.arrow::before,
.bs-popover-auto[x-placement^="left"]>.arrow::before {
  right: 0;
  border-width: .5rem 0 .5rem .5rem;
  border-left-color: rgba(0,0,0,0.25);
}

.bs-popover-left>.arrow::after,
.bs-popover-auto[x-placement^="left"]>.arrow::after {
  right: 1px;
  border-width: .5rem 0 .5rem .5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: .5rem .75rem;
  margin-bottom: 0;
  font-size: 1.125rem;
  color: #1A202C;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(.75rem - 1px);
  border-top-right-radius: calc(.75rem - 1px);
}

.popover-header:empty {
  display: none;
}

.popover-body {
  padding: .5rem .75rem;
  color: #4A5568;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: .25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: .75s linear infinite spinner-border;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: .2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }

  50% {
    opacity: 1;
    transform: none;
  }
}

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: .75s linear infinite spinner-grow;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    animation-duration: 1.5s;
  }
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #06f !important;
}

a.bg-primary:hover,
a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0052cc !important;
}

.bg-secondary {
  background-color: #76818C !important;
}

a.bg-secondary:hover,
a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #5e6871 !important;
}

.bg-success {
  background-color: #2BCA50 !important;
}

a.bg-success:hover,
a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #22a03f !important;
}

.bg-info {
  background-color: #06f !important;
}

a.bg-info:hover,
a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #0052cc !important;
}

.bg-warning {
  background-color: #FCA104 !important;
}

a.bg-warning:hover,
a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #cb8102 !important;
}

.bg-danger {
  background-color: #F43946 !important;
}

a.bg-danger:hover,
a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #ed0d1d !important;
}

.bg-light {
  background-color: #EDF2F7 !important;
}

a.bg-light:hover,
a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #cad9e7 !important;
}

.bg-dark {
  background-color: #2D3748 !important;
}

a.bg-dark:hover,
a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #191f29 !important;
}

.bg-primary-opacity-25 {
  background-color: rgba(0,102,255,0.25) !important;
}

a.bg-primary-opacity-25:hover,
a.bg-primary-opacity-25:focus,
button.bg-primary-opacity-25:hover,
button.bg-primary-opacity-25:focus {
  background-color: rgba(0,82,204,0.25) !important;
}

.bg-primary-opacity-50 {
  background-color: rgba(0,102,255,0.5) !important;
}

a.bg-primary-opacity-50:hover,
a.bg-primary-opacity-50:focus,
button.bg-primary-opacity-50:hover,
button.bg-primary-opacity-50:focus {
  background-color: rgba(0,82,204,0.5) !important;
}

.bg-primary-opacity-75 {
  background-color: rgba(0,102,255,0.75) !important;
}

a.bg-primary-opacity-75:hover,
a.bg-primary-opacity-75:focus,
button.bg-primary-opacity-75:hover,
button.bg-primary-opacity-75:focus {
  background-color: rgba(0,82,204,0.75) !important;
}

.bg-primary-faded {
  background-color: #CCE0FF !important;
}

a.bg-primary-faded:hover,
a.bg-primary-faded:focus,
button.bg-primary-faded:hover,
button.bg-primary-faded:focus {
  background-color: #99c1ff !important;
}

.bg-secondary-opacity-25 {
  background-color: rgba(118,129,140,0.25) !important;
}

a.bg-secondary-opacity-25:hover,
a.bg-secondary-opacity-25:focus,
button.bg-secondary-opacity-25:hover,
button.bg-secondary-opacity-25:focus {
  background-color: rgba(94,104,113,0.25) !important;
}

.bg-secondary-opacity-50 {
  background-color: rgba(118,129,140,0.5) !important;
}

a.bg-secondary-opacity-50:hover,
a.bg-secondary-opacity-50:focus,
button.bg-secondary-opacity-50:hover,
button.bg-secondary-opacity-50:focus {
  background-color: rgba(94,104,113,0.5) !important;
}

.bg-secondary-opacity-75 {
  background-color: rgba(118,129,140,0.75) !important;
}

a.bg-secondary-opacity-75:hover,
a.bg-secondary-opacity-75:focus,
button.bg-secondary-opacity-75:hover,
button.bg-secondary-opacity-75:focus {
  background-color: rgba(94,104,113,0.75) !important;
}

.bg-success-opacity-25 {
  background-color: rgba(43,202,80,0.25) !important;
}

a.bg-success-opacity-25:hover,
a.bg-success-opacity-25:focus,
button.bg-success-opacity-25:hover,
button.bg-success-opacity-25:focus {
  background-color: rgba(34,160,63,0.25) !important;
}

.bg-success-opacity-50 {
  background-color: rgba(43,202,80,0.5) !important;
}

a.bg-success-opacity-50:hover,
a.bg-success-opacity-50:focus,
button.bg-success-opacity-50:hover,
button.bg-success-opacity-50:focus {
  background-color: rgba(34,160,63,0.5) !important;
}

.bg-success-opacity-75 {
  background-color: rgba(43,202,80,0.75) !important;
}

a.bg-success-opacity-75:hover,
a.bg-success-opacity-75:focus,
button.bg-success-opacity-75:hover,
button.bg-success-opacity-75:focus {
  background-color: rgba(34,160,63,0.75) !important;
}

.bg-success-faded {
  background-color: #D5F4DC !important;
}

a.bg-success-faded:hover,
a.bg-success-faded:focus,
button.bg-success-faded:hover,
button.bg-success-faded:focus {
  background-color: #ade9ba !important;
}

.bg-info-opacity-25 {
  background-color: rgba(0,102,255,0.25) !important;
}

a.bg-info-opacity-25:hover,
a.bg-info-opacity-25:focus,
button.bg-info-opacity-25:hover,
button.bg-info-opacity-25:focus {
  background-color: rgba(0,82,204,0.25) !important;
}

.bg-info-opacity-50 {
  background-color: rgba(0,102,255,0.5) !important;
}

a.bg-info-opacity-50:hover,
a.bg-info-opacity-50:focus,
button.bg-info-opacity-50:hover,
button.bg-info-opacity-50:focus {
  background-color: rgba(0,82,204,0.5) !important;
}

.bg-info-opacity-75 {
  background-color: rgba(0,102,255,0.75) !important;
}

a.bg-info-opacity-75:hover,
a.bg-info-opacity-75:focus,
button.bg-info-opacity-75:hover,
button.bg-info-opacity-75:focus {
  background-color: rgba(0,82,204,0.75) !important;
}

.bg-info-faded {
  background-color: #CCE0FF !important;
}

a.bg-info-faded:hover,
a.bg-info-faded:focus,
button.bg-info-faded:hover,
button.bg-info-faded:focus {
  background-color: #99c1ff !important;
}

.bg-warning-opacity-25 {
  background-color: rgba(252,161,4,0.25) !important;
}

a.bg-warning-opacity-25:hover,
a.bg-warning-opacity-25:focus,
button.bg-warning-opacity-25:hover,
button.bg-warning-opacity-25:focus {
  background-color: rgba(203,129,2,0.25) !important;
}

.bg-warning-opacity-50 {
  background-color: rgba(252,161,4,0.5) !important;
}

a.bg-warning-opacity-50:hover,
a.bg-warning-opacity-50:focus,
button.bg-warning-opacity-50:hover,
button.bg-warning-opacity-50:focus {
  background-color: rgba(203,129,2,0.5) !important;
}

.bg-warning-opacity-75 {
  background-color: rgba(252,161,4,0.75) !important;
}

a.bg-warning-opacity-75:hover,
a.bg-warning-opacity-75:focus,
button.bg-warning-opacity-75:hover,
button.bg-warning-opacity-75:focus {
  background-color: rgba(203,129,2,0.75) !important;
}

.bg-warning-faded {
  background-color: #FEECCD !important;
}

a.bg-warning-faded:hover,
a.bg-warning-faded:focus,
button.bg-warning-faded:hover,
button.bg-warning-faded:focus {
  background-color: #fdd99b !important;
}

.bg-danger-opacity-25 {
  background-color: rgba(244,57,70,0.25) !important;
}

a.bg-danger-opacity-25:hover,
a.bg-danger-opacity-25:focus,
button.bg-danger-opacity-25:hover,
button.bg-danger-opacity-25:focus {
  background-color: rgba(237,13,29,0.25) !important;
}

.bg-danger-opacity-50 {
  background-color: rgba(244,57,70,0.5) !important;
}

a.bg-danger-opacity-50:hover,
a.bg-danger-opacity-50:focus,
button.bg-danger-opacity-50:hover,
button.bg-danger-opacity-50:focus {
  background-color: rgba(237,13,29,0.5) !important;
}

.bg-danger-opacity-75 {
  background-color: rgba(244,57,70,0.75) !important;
}

a.bg-danger-opacity-75:hover,
a.bg-danger-opacity-75:focus,
button.bg-danger-opacity-75:hover,
button.bg-danger-opacity-75:focus {
  background-color: rgba(237,13,29,0.75) !important;
}

.bg-danger-faded {
  background-color: #FDD7DA !important;
}

a.bg-danger-faded:hover,
a.bg-danger-faded:focus,
button.bg-danger-faded:hover,
button.bg-danger-faded:focus {
  background-color: #fba6ad !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #CBD5E0 !important;
}

.border-top {
  border-top: 1px solid #CBD5E0 !important;
}

.border-right {
  border-right: 1px solid #CBD5E0 !important;
}

.border-bottom {
  border-bottom: 1px solid #CBD5E0 !important;
}

.border-left {
  border-left: 1px solid #CBD5E0 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #06f !important;
}

.border-secondary {
  border-color: #76818C !important;
}

.border-success {
  border-color: #2BCA50 !important;
}

.border-info {
  border-color: #06f !important;
}

.border-warning {
  border-color: #FCA104 !important;
}

.border-danger {
  border-color: #F43946 !important;
}

.border-light {
  border-color: #EDF2F7 !important;
}

.border-dark {
  border-color: #2D3748 !important;
}

.border-primary-opacity-25 {
  border-color: rgba(0,102,255,0.25) !important;
}

.border-primary-opacity-50 {
  border-color: rgba(0,102,255,0.5) !important;
}

.border-primary-opacity-75 {
  border-color: rgba(0,102,255,0.75) !important;
}

.border-primary-faded {
  border-color: #CCE0FF !important;
}

.border-secondary-opacity-25 {
  border-color: rgba(118,129,140,0.25) !important;
}

.border-secondary-opacity-50 {
  border-color: rgba(118,129,140,0.5) !important;
}

.border-secondary-opacity-75 {
  border-color: rgba(118,129,140,0.75) !important;
}

.border-success-opacity-25 {
  border-color: rgba(43,202,80,0.25) !important;
}

.border-success-opacity-50 {
  border-color: rgba(43,202,80,0.5) !important;
}

.border-success-opacity-75 {
  border-color: rgba(43,202,80,0.75) !important;
}

.border-success-faded {
  border-color: #D5F4DC !important;
}

.border-info-opacity-25 {
  border-color: rgba(0,102,255,0.25) !important;
}

.border-info-opacity-50 {
  border-color: rgba(0,102,255,0.5) !important;
}

.border-info-opacity-75 {
  border-color: rgba(0,102,255,0.75) !important;
}

.border-info-faded {
  border-color: #CCE0FF !important;
}

.border-warning-opacity-25 {
  border-color: rgba(252,161,4,0.25) !important;
}

.border-warning-opacity-50 {
  border-color: rgba(252,161,4,0.5) !important;
}

.border-warning-opacity-75 {
  border-color: rgba(252,161,4,0.75) !important;
}

.border-warning-faded {
  border-color: #FEECCD !important;
}

.border-danger-opacity-25 {
  border-color: rgba(244,57,70,0.25) !important;
}

.border-danger-opacity-50 {
  border-color: rgba(244,57,70,0.5) !important;
}

.border-danger-opacity-75 {
  border-color: rgba(244,57,70,0.75) !important;
}

.border-danger-faded {
  border-color: #FDD7DA !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: .1875rem !important;
}

.rounded {
  border-radius: .375rem !important;
}

.rounded-top {
  border-top-left-radius: .375rem !important;
  border-top-right-radius: .375rem !important;
}

.rounded-right {
  border-top-right-radius: .375rem !important;
  border-bottom-right-radius: .375rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: .375rem !important;
  border-bottom-left-radius: .375rem !important;
}

.rounded-left {
  border-top-left-radius: .375rem !important;
  border-bottom-left-radius: .375rem !important;
}

.rounded-lg {
  border-radius: .75rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }

  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }
}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

.embed-responsive::before {
  display: block;
  content: "";
}

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-sm-fill {
    flex: 1 1 auto !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-md-fill {
    flex: 1 1 auto !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-xl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }

  .float-sm-right {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }
}

@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }

  .float-md-right {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }
}

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }

  .float-lg-right {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }

  .float-xl-right {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }
}

.user-select-all {
  -webkit-user-select: all !important;
      -ms-user-select: all !important;
          user-select: all !important;
}

.user-select-auto {
  -webkit-user-select: auto !important;
      -ms-user-select: auto !important;
          user-select: auto !important;
}

.user-select-none {
  -webkit-user-select: none !important;
      -ms-user-select: none !important;
          user-select: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0 3px 0 rgba(74,85,104,0.1),0 1px 2px 0 rgba(74,85,104,0.1) !important;
}

.shadow {
  box-shadow: 0 4px 5px -1px rgba(74,85,104,0.1),0 -1px 5px -1px rgba(74,85,104,0.1) !important;
}

.shadow-lg {
  box-shadow: 0 15px 25px -5px rgba(74,85,104,0.1),0 -2px 15px -5px rgba(74,85,104,0.1) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: .25rem !important;
}

.mt-1,
.my-1 {
  margin-top: .25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: .25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: .25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: .25rem !important;
}

.m-2 {
  margin: .5rem !important;
}

.mt-2,
.my-2 {
  margin-top: .5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: .5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: .5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: .5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: .25rem !important;
}

.pt-1,
.py-1 {
  padding-top: .25rem !important;
}

.pr-1,
.px-1 {
  padding-right: .25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: .25rem !important;
}

.pl-1,
.px-1 {
  padding-left: .25rem !important;
}

.p-2 {
  padding: .5rem !important;
}

.pt-2,
.py-2 {
  padding-top: .5rem !important;
}

.pr-2,
.px-2 {
  padding-right: .5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: .5rem !important;
}

.pl-2,
.px-2 {
  padding-left: .5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -.25rem !important;
}

.m-n2 {
  margin: -.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }

  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }

  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }

  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }

  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }

  .m-sm-1 {
    margin: .25rem !important;
  }

  .mt-sm-1,
  .my-sm-1 {
    margin-top: .25rem !important;
  }

  .mr-sm-1,
  .mx-sm-1 {
    margin-right: .25rem !important;
  }

  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: .25rem !important;
  }

  .ml-sm-1,
  .mx-sm-1 {
    margin-left: .25rem !important;
  }

  .m-sm-2 {
    margin: .5rem !important;
  }

  .mt-sm-2,
  .my-sm-2 {
    margin-top: .5rem !important;
  }

  .mr-sm-2,
  .mx-sm-2 {
    margin-right: .5rem !important;
  }

  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: .5rem !important;
  }

  .ml-sm-2,
  .mx-sm-2 {
    margin-left: .5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }

  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }

  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }

  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }

  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }

  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }

  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }

  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }

  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }

  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }

  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }

  .p-sm-1 {
    padding: .25rem !important;
  }

  .pt-sm-1,
  .py-sm-1 {
    padding-top: .25rem !important;
  }

  .pr-sm-1,
  .px-sm-1 {
    padding-right: .25rem !important;
  }

  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: .25rem !important;
  }

  .pl-sm-1,
  .px-sm-1 {
    padding-left: .25rem !important;
  }

  .p-sm-2 {
    padding: .5rem !important;
  }

  .pt-sm-2,
  .py-sm-2 {
    padding-top: .5rem !important;
  }

  .pr-sm-2,
  .px-sm-2 {
    padding-right: .5rem !important;
  }

  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: .5rem !important;
  }

  .pl-sm-2,
  .px-sm-2 {
    padding-left: .5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }

  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }

  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }

  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }

  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }

  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }

  .m-sm-n1 {
    margin: -.25rem !important;
  }

  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -.25rem !important;
  }

  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -.25rem !important;
  }

  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -.25rem !important;
  }

  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -.25rem !important;
  }

  .m-sm-n2 {
    margin: -.5rem !important;
  }

  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -.5rem !important;
  }

  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -.5rem !important;
  }

  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -.5rem !important;
  }

  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -.5rem !important;
  }

  .m-sm-n3 {
    margin: -1rem !important;
  }

  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important;
  }

  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important;
  }

  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important;
  }

  .m-sm-n4 {
    margin: -1.5rem !important;
  }

  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }

  .m-sm-n5 {
    margin: -3rem !important;
  }

  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important;
  }

  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important;
  }

  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }

  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }

  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }

  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }

  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }

  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }

  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }

  .m-md-1 {
    margin: .25rem !important;
  }

  .mt-md-1,
  .my-md-1 {
    margin-top: .25rem !important;
  }

  .mr-md-1,
  .mx-md-1 {
    margin-right: .25rem !important;
  }

  .mb-md-1,
  .my-md-1 {
    margin-bottom: .25rem !important;
  }

  .ml-md-1,
  .mx-md-1 {
    margin-left: .25rem !important;
  }

  .m-md-2 {
    margin: .5rem !important;
  }

  .mt-md-2,
  .my-md-2 {
    margin-top: .5rem !important;
  }

  .mr-md-2,
  .mx-md-2 {
    margin-right: .5rem !important;
  }

  .mb-md-2,
  .my-md-2 {
    margin-bottom: .5rem !important;
  }

  .ml-md-2,
  .mx-md-2 {
    margin-left: .5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }

  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }

  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }

  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }

  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }

  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }

  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }

  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }

  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }

  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }

  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }

  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }

  .p-md-1 {
    padding: .25rem !important;
  }

  .pt-md-1,
  .py-md-1 {
    padding-top: .25rem !important;
  }

  .pr-md-1,
  .px-md-1 {
    padding-right: .25rem !important;
  }

  .pb-md-1,
  .py-md-1 {
    padding-bottom: .25rem !important;
  }

  .pl-md-1,
  .px-md-1 {
    padding-left: .25rem !important;
  }

  .p-md-2 {
    padding: .5rem !important;
  }

  .pt-md-2,
  .py-md-2 {
    padding-top: .5rem !important;
  }

  .pr-md-2,
  .px-md-2 {
    padding-right: .5rem !important;
  }

  .pb-md-2,
  .py-md-2 {
    padding-bottom: .5rem !important;
  }

  .pl-md-2,
  .px-md-2 {
    padding-left: .5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }

  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }

  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }

  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }

  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }

  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }

  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }

  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }

  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }

  .m-md-n1 {
    margin: -.25rem !important;
  }

  .mt-md-n1,
  .my-md-n1 {
    margin-top: -.25rem !important;
  }

  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -.25rem !important;
  }

  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -.25rem !important;
  }

  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -.25rem !important;
  }

  .m-md-n2 {
    margin: -.5rem !important;
  }

  .mt-md-n2,
  .my-md-n2 {
    margin-top: -.5rem !important;
  }

  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -.5rem !important;
  }

  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -.5rem !important;
  }

  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -.5rem !important;
  }

  .m-md-n3 {
    margin: -1rem !important;
  }

  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important;
  }

  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important;
  }

  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important;
  }

  .m-md-n4 {
    margin: -1.5rem !important;
  }

  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important;
  }

  .m-md-n5 {
    margin: -3rem !important;
  }

  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important;
  }

  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important;
  }

  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }

  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }

  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }

  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }

  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }

  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }

  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }

  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }

  .m-lg-1 {
    margin: .25rem !important;
  }

  .mt-lg-1,
  .my-lg-1 {
    margin-top: .25rem !important;
  }

  .mr-lg-1,
  .mx-lg-1 {
    margin-right: .25rem !important;
  }

  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: .25rem !important;
  }

  .ml-lg-1,
  .mx-lg-1 {
    margin-left: .25rem !important;
  }

  .m-lg-2 {
    margin: .5rem !important;
  }

  .mt-lg-2,
  .my-lg-2 {
    margin-top: .5rem !important;
  }

  .mr-lg-2,
  .mx-lg-2 {
    margin-right: .5rem !important;
  }

  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: .5rem !important;
  }

  .ml-lg-2,
  .mx-lg-2 {
    margin-left: .5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }

  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }

  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }

  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }

  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }

  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }

  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }

  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }

  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }

  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }

  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }

  .p-lg-1 {
    padding: .25rem !important;
  }

  .pt-lg-1,
  .py-lg-1 {
    padding-top: .25rem !important;
  }

  .pr-lg-1,
  .px-lg-1 {
    padding-right: .25rem !important;
  }

  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: .25rem !important;
  }

  .pl-lg-1,
  .px-lg-1 {
    padding-left: .25rem !important;
  }

  .p-lg-2 {
    padding: .5rem !important;
  }

  .pt-lg-2,
  .py-lg-2 {
    padding-top: .5rem !important;
  }

  .pr-lg-2,
  .px-lg-2 {
    padding-right: .5rem !important;
  }

  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: .5rem !important;
  }

  .pl-lg-2,
  .px-lg-2 {
    padding-left: .5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }

  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }

  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }

  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }

  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }

  .m-lg-n1 {
    margin: -.25rem !important;
  }

  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -.25rem !important;
  }

  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -.25rem !important;
  }

  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -.25rem !important;
  }

  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -.25rem !important;
  }

  .m-lg-n2 {
    margin: -.5rem !important;
  }

  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -.5rem !important;
  }

  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -.5rem !important;
  }

  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -.5rem !important;
  }

  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -.5rem !important;
  }

  .m-lg-n3 {
    margin: -1rem !important;
  }

  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important;
  }

  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important;
  }

  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important;
  }

  .m-lg-n4 {
    margin: -1.5rem !important;
  }

  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }

  .m-lg-n5 {
    margin: -3rem !important;
  }

  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important;
  }

  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important;
  }

  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }

  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }

  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }

  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }

  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }

  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }

  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }

  .m-xl-1 {
    margin: .25rem !important;
  }

  .mt-xl-1,
  .my-xl-1 {
    margin-top: .25rem !important;
  }

  .mr-xl-1,
  .mx-xl-1 {
    margin-right: .25rem !important;
  }

  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: .25rem !important;
  }

  .ml-xl-1,
  .mx-xl-1 {
    margin-left: .25rem !important;
  }

  .m-xl-2 {
    margin: .5rem !important;
  }

  .mt-xl-2,
  .my-xl-2 {
    margin-top: .5rem !important;
  }

  .mr-xl-2,
  .mx-xl-2 {
    margin-right: .5rem !important;
  }

  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: .5rem !important;
  }

  .ml-xl-2,
  .mx-xl-2 {
    margin-left: .5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }

  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }

  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }

  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }

  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }

  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }

  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }

  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }

  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }

  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }

  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }

  .p-xl-1 {
    padding: .25rem !important;
  }

  .pt-xl-1,
  .py-xl-1 {
    padding-top: .25rem !important;
  }

  .pr-xl-1,
  .px-xl-1 {
    padding-right: .25rem !important;
  }

  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: .25rem !important;
  }

  .pl-xl-1,
  .px-xl-1 {
    padding-left: .25rem !important;
  }

  .p-xl-2 {
    padding: .5rem !important;
  }

  .pt-xl-2,
  .py-xl-2 {
    padding-top: .5rem !important;
  }

  .pr-xl-2,
  .px-xl-2 {
    padding-right: .5rem !important;
  }

  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: .5rem !important;
  }

  .pl-xl-2,
  .px-xl-2 {
    padding-left: .5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }

  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }

  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }

  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }

  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }

  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }

  .m-xl-n1 {
    margin: -.25rem !important;
  }

  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -.25rem !important;
  }

  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -.25rem !important;
  }

  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -.25rem !important;
  }

  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -.25rem !important;
  }

  .m-xl-n2 {
    margin: -.5rem !important;
  }

  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -.5rem !important;
  }

  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -.5rem !important;
  }

  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -.5rem !important;
  }

  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -.5rem !important;
  }

  .m-xl-n3 {
    margin: -1rem !important;
  }

  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important;
  }

  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important;
  }

  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important;
  }

  .m-xl-n4 {
    margin: -1.5rem !important;
  }

  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }

  .m-xl-n5 {
    margin: -3rem !important;
  }

  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important;
  }

  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important;
  }

  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }

  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }

  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0,0,0,0);
}

.text-monospace {
  font-family: SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }

  .text-sm-right {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }

  .text-md-right {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }

  .text-lg-right {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }

  .text-xl-right {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #06f !important;
}

a.text-primary:hover,
a.text-primary:focus {
  color: #0047b3 !important;
}

.text-secondary {
  color: #76818C !important;
}

a.text-secondary:hover,
a.text-secondary:focus {
  color: #535b63 !important;
}

.text-success {
  color: #2BCA50 !important;
}

a.text-success:hover,
a.text-success:focus {
  color: #1e8b37 !important;
}

.text-info {
  color: #06f !important;
}

a.text-info:hover,
a.text-info:focus {
  color: #0047b3 !important;
}

.text-warning {
  color: #FCA104 !important;
}

a.text-warning:hover,
a.text-warning:focus {
  color: #b17102 !important;
}

.text-danger {
  color: #F43946 !important;
}

a.text-danger:hover,
a.text-danger:focus {
  color: #d50c1a !important;
}

.text-light {
  color: #EDF2F7 !important;
}

a.text-light:hover,
a.text-light:focus {
  color: #b8ccdf !important;
}

.text-dark {
  color: #2D3748 !important;
}

a.text-dark:hover,
a.text-dark:focus {
  color: #101319 !important;
}

.text-primary-opacity-25 {
  color: rgba(0,102,255,0.25) !important;
}

a.text-primary-opacity-25:hover,
a.text-primary-opacity-25:focus {
  color: rgba(0,71,179,0.25) !important;
}

.text-primary-opacity-50 {
  color: rgba(0,102,255,0.5) !important;
}

a.text-primary-opacity-50:hover,
a.text-primary-opacity-50:focus {
  color: rgba(0,71,179,0.5) !important;
}

.text-primary-opacity-75 {
  color: rgba(0,102,255,0.75) !important;
}

a.text-primary-opacity-75:hover,
a.text-primary-opacity-75:focus {
  color: rgba(0,71,179,0.75) !important;
}

.text-primary-faded {
  color: #CCE0FF !important;
}

a.text-primary-faded:hover,
a.text-primary-faded:focus {
  color: #80b2ff !important;
}

.text-secondary-opacity-25 {
  color: rgba(118,129,140,0.25) !important;
}

a.text-secondary-opacity-25:hover,
a.text-secondary-opacity-25:focus {
  color: rgba(83,91,99,0.25) !important;
}

.text-secondary-opacity-50 {
  color: rgba(118,129,140,0.5) !important;
}

a.text-secondary-opacity-50:hover,
a.text-secondary-opacity-50:focus {
  color: rgba(83,91,99,0.5) !important;
}

.text-secondary-opacity-75 {
  color: rgba(118,129,140,0.75) !important;
}

a.text-secondary-opacity-75:hover,
a.text-secondary-opacity-75:focus {
  color: rgba(83,91,99,0.75) !important;
}

.text-success-opacity-25 {
  color: rgba(43,202,80,0.25) !important;
}

a.text-success-opacity-25:hover,
a.text-success-opacity-25:focus {
  color: rgba(30,139,55,0.25) !important;
}

.text-success-opacity-50 {
  color: rgba(43,202,80,0.5) !important;
}

a.text-success-opacity-50:hover,
a.text-success-opacity-50:focus {
  color: rgba(30,139,55,0.5) !important;
}

.text-success-opacity-75 {
  color: rgba(43,202,80,0.75) !important;
}

a.text-success-opacity-75:hover,
a.text-success-opacity-75:focus {
  color: rgba(30,139,55,0.75) !important;
}

.text-success-faded {
  color: #D5F4DC !important;
}

a.text-success-faded:hover,
a.text-success-faded:focus {
  color: #98e4a9 !important;
}

.text-info-opacity-25 {
  color: rgba(0,102,255,0.25) !important;
}

a.text-info-opacity-25:hover,
a.text-info-opacity-25:focus {
  color: rgba(0,71,179,0.25) !important;
}

.text-info-opacity-50 {
  color: rgba(0,102,255,0.5) !important;
}

a.text-info-opacity-50:hover,
a.text-info-opacity-50:focus {
  color: rgba(0,71,179,0.5) !important;
}

.text-info-opacity-75 {
  color: rgba(0,102,255,0.75) !important;
}

a.text-info-opacity-75:hover,
a.text-info-opacity-75:focus {
  color: rgba(0,71,179,0.75) !important;
}

.text-info-faded {
  color: #CCE0FF !important;
}

a.text-info-faded:hover,
a.text-info-faded:focus {
  color: #80b2ff !important;
}

.text-warning-opacity-25 {
  color: rgba(252,161,4,0.25) !important;
}

a.text-warning-opacity-25:hover,
a.text-warning-opacity-25:focus {
  color: rgba(177,113,2,0.25) !important;
}

.text-warning-opacity-50 {
  color: rgba(252,161,4,0.5) !important;
}

a.text-warning-opacity-50:hover,
a.text-warning-opacity-50:focus {
  color: rgba(177,113,2,0.5) !important;
}

.text-warning-opacity-75 {
  color: rgba(252,161,4,0.75) !important;
}

a.text-warning-opacity-75:hover,
a.text-warning-opacity-75:focus {
  color: rgba(177,113,2,0.75) !important;
}

.text-warning-faded {
  color: #FEECCD !important;
}

a.text-warning-faded:hover,
a.text-warning-faded:focus {
  color: #fdd082 !important;
}

.text-danger-opacity-25 {
  color: rgba(244,57,70,0.25) !important;
}

a.text-danger-opacity-25:hover,
a.text-danger-opacity-25:focus {
  color: rgba(213,12,26,0.25) !important;
}

.text-danger-opacity-50 {
  color: rgba(244,57,70,0.5) !important;
}

a.text-danger-opacity-50:hover,
a.text-danger-opacity-50:focus {
  color: rgba(213,12,26,0.5) !important;
}

.text-danger-opacity-75 {
  color: rgba(244,57,70,0.75) !important;
}

a.text-danger-opacity-75:hover,
a.text-danger-opacity-75:focus {
  color: rgba(213,12,26,0.75) !important;
}

.text-danger-faded {
  color: #FDD7DA !important;
}

a.text-danger-faded:hover,
a.text-danger-faded:focus {
  color: #f98e97 !important;
}

.text-body {
  color: #4A5568 !important;
}

.text-muted {
  color: #718096 !important;
}

.text-black-50 {
  color: rgba(0,0,0,0.5) !important;
}

.text-white-50 {
  color: rgba(255,255,255,0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.callout {
  padding: .75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: .375rem;
  border-left-width: 5px;
}

.callout p:last-child {
  margin-bottom: 0;
}

.callout-primary {
  border-color: #e0edff;
  border-left-color: #06f;
  color: #1A202C;
}

.callout-primary a:not(.btn) {
  text-decoration: underline;
}

.callout-primary a:not(.btn):hover {
  text-decoration: none;
}

.callout-primary h4 {
  color: #1A202C;
}

.callout-primary hr {
  border-color: #8fbcff;
}

.callout-secondary {
  border-color: #eff0f1;
  border-left-color: #76818C;
  color: #1A202C;
}

.callout-secondary a:not(.btn) {
  text-decoration: underline;
}

.callout-secondary a:not(.btn):hover {
  text-decoration: none;
}

.callout-secondary h4 {
  color: #1A202C;
}

.callout-secondary hr {
  border-color: #c3c8cc;
}

.callout-success {
  border-color: #e6f9ea;
  border-left-color: #2BCA50;
  color: #1A202C;
}

.callout-success a:not(.btn) {
  text-decoration: underline;
}

.callout-success a:not(.btn):hover {
  text-decoration: none;
}

.callout-success h4 {
  color: #1A202C;
}

.callout-success hr {
  border-color: #a2e8b2;
}

.callout-info {
  border-color: #e0edff;
  border-left-color: #06f;
  color: #1A202C;
}

.callout-info a:not(.btn) {
  text-decoration: underline;
}

.callout-info a:not(.btn):hover {
  text-decoration: none;
}

.callout-info h4 {
  color: #1A202C;
}

.callout-info hr {
  border-color: #8fbcff;
}

.callout-warning {
  border-color: #fff4e1;
  border-left-color: #FCA104;
  color: #1A202C;
}

.callout-warning a:not(.btn) {
  text-decoration: underline;
}

.callout-warning a:not(.btn):hover {
  text-decoration: none;
}

.callout-warning h4 {
  color: #1A202C;
}

.callout-warning hr {
  border-color: #fed691;
}

.callout-danger {
  border-color: #fee7e9;
  border-left-color: #F43946;
  color: #1A202C;
}

.callout-danger a:not(.btn) {
  text-decoration: underline;
}

.callout-danger a:not(.btn):hover {
  text-decoration: none;
}

.callout-danger h4 {
  color: #1A202C;
}

.callout-danger hr {
  border-color: #faa8ae;
}

.callout-light {
  border-color: #fdfdfe;
  border-left-color: #EDF2F7;
  color: #1A202C;
}

.callout-light a:not(.btn) {
  text-decoration: underline;
}

.callout-light a:not(.btn):hover {
  text-decoration: none;
}

.callout-light h4 {
  color: #1A202C;
}

.callout-light hr {
  border-color: #f7f9fb;
}

.callout-dark {
  border-color: #e6e7e9;
  border-left-color: #2D3748;
  color: #1A202C;
}

.callout-dark a:not(.btn) {
  text-decoration: underline;
}

.callout-dark a:not(.btn):hover {
  text-decoration: none;
}

.callout-dark h4 {
  color: #1A202C;
}

.callout-dark hr {
  border-color: #a3a7ae;
}

.callout-primary-opacity-25 {
  border-color: rgba(250,252,255,0.91);
  border-left-color: rgba(0,102,255,0.25);
  color: #1A202C;
}

.callout-primary-opacity-25 a:not(.btn) {
  text-decoration: underline;
}

.callout-primary-opacity-25 a:not(.btn):hover {
  text-decoration: none;
}

.callout-primary-opacity-25 h4 {
  color: #1A202C;
}

.callout-primary-opacity-25 hr {
  border-color: rgba(229,240,255,0.67);
}

.callout-primary-opacity-50 {
  border-color: rgba(244,248,255,0.94);
  border-left-color: rgba(0,102,255,0.5);
  color: #1A202C;
}

.callout-primary-opacity-50 a:not(.btn) {
  text-decoration: underline;
}

.callout-primary-opacity-50 a:not(.btn):hover {
  text-decoration: none;
}

.callout-primary-opacity-50 h4 {
  color: #1A202C;
}

.callout-primary-opacity-50 hr {
  border-color: rgba(202,223,255,0.78);
}

.callout-primary-opacity-75 {
  border-color: rgba(236,243,255,0.97);
  border-left-color: rgba(0,102,255,0.75);
  color: #1A202C;
}

.callout-primary-opacity-75 a:not(.btn) {
  text-decoration: underline;
}

.callout-primary-opacity-75 a:not(.btn):hover {
  text-decoration: none;
}

.callout-primary-opacity-75 h4 {
  color: #1A202C;
}

.callout-primary-opacity-75 hr {
  border-color: rgba(173,206,255,0.89);
}

.callout-primary-faded {
  border-color: #f9fbff;
  border-left-color: #CCE0FF;
  color: #1A202C;
}

.callout-primary-faded a:not(.btn) {
  text-decoration: underline;
}

.callout-primary-faded a:not(.btn):hover {
  text-decoration: none;
}

.callout-primary-faded h4 {
  color: #1A202C;
}

.callout-primary-faded hr {
  border-color: #e9f1ff;
}

.callout-secondary-opacity-25 {
  border-color: rgba(252,253,253,0.91);
  border-left-color: rgba(118,129,140,0.25);
  color: #1A202C;
}

.callout-secondary-opacity-25 a:not(.btn) {
  text-decoration: underline;
}

.callout-secondary-opacity-25 a:not(.btn):hover {
  text-decoration: none;
}

.callout-secondary-opacity-25 h4 {
  color: #1A202C;
}

.callout-secondary-opacity-25 hr {
  border-color: rgba(241,242,243,0.67);
}

.callout-secondary-opacity-50 {
  border-color: rgba(249,250,250,0.94);
  border-left-color: rgba(118,129,140,0.5);
  color: #1A202C;
}

.callout-secondary-opacity-50 a:not(.btn) {
  text-decoration: underline;
}

.callout-secondary-opacity-50 a:not(.btn):hover {
  text-decoration: none;
}

.callout-secondary-opacity-50 h4 {
  color: #1A202C;
}

.callout-secondary-opacity-50 hr {
  border-color: rgba(227,229,231,0.78);
}

.callout-secondary-opacity-75 {
  border-color: rgba(245,245,246,0.97);
  border-left-color: rgba(118,129,140,0.75);
  color: #1A202C;
}

.callout-secondary-opacity-75 a:not(.btn) {
  text-decoration: underline;
}

.callout-secondary-opacity-75 a:not(.btn):hover {
  text-decoration: none;
}

.callout-secondary-opacity-75 h4 {
  color: #1A202C;
}

.callout-secondary-opacity-75 hr {
  border-color: rgba(211,215,218,0.89);
}

.callout-success-opacity-25 {
  border-color: rgba(251,254,252,0.91);
  border-left-color: rgba(43,202,80,0.25);
  color: #1A202C;
}

.callout-success-opacity-25 a:not(.btn) {
  text-decoration: underline;
}

.callout-success-opacity-25 a:not(.btn):hover {
  text-decoration: none;
}

.callout-success-opacity-25 h4 {
  color: #1A202C;
}

.callout-success-opacity-25 hr {
  border-color: rgba(234,250,237,0.67);
}

.callout-success-opacity-50 {
  border-color: rgba(246,253,247,0.94);
  border-left-color: rgba(43,202,80,0.5);
  color: #1A202C;
}

.callout-success-opacity-50 a:not(.btn) {
  text-decoration: underline;
}

.callout-success-opacity-50 a:not(.btn):hover {
  text-decoration: none;
}

.callout-success-opacity-50 h4 {
  color: #1A202C;
}

.callout-success-opacity-50 hr {
  border-color: rgba(211,244,219,0.78);
}

.callout-success-opacity-75 {
  border-color: rgba(239,251,242,0.97);
  border-left-color: rgba(43,202,80,0.75);
  color: #1A202C;
}

.callout-success-opacity-75 a:not(.btn) {
  text-decoration: underline;
}

.callout-success-opacity-75 a:not(.btn):hover {
  text-decoration: none;
}

.callout-success-opacity-75 h4 {
  color: #1A202C;
}

.callout-success-opacity-75 hr {
  border-color: rgba(187,238,199,0.89);
}

.callout-success-faded {
  border-color: #fafefb;
  border-left-color: #D5F4DC;
  color: #1A202C;
}

.callout-success-faded a:not(.btn) {
  text-decoration: underline;
}

.callout-success-faded a:not(.btn):hover {
  text-decoration: none;
}

.callout-success-faded h4 {
  color: #1A202C;
}

.callout-success-faded hr {
  border-color: #edfaf0;
}

.callout-info-opacity-25 {
  border-color: rgba(250,252,255,0.91);
  border-left-color: rgba(0,102,255,0.25);
  color: #1A202C;
}

.callout-info-opacity-25 a:not(.btn) {
  text-decoration: underline;
}

.callout-info-opacity-25 a:not(.btn):hover {
  text-decoration: none;
}

.callout-info-opacity-25 h4 {
  color: #1A202C;
}

.callout-info-opacity-25 hr {
  border-color: rgba(229,240,255,0.67);
}

.callout-info-opacity-50 {
  border-color: rgba(244,248,255,0.94);
  border-left-color: rgba(0,102,255,0.5);
  color: #1A202C;
}

.callout-info-opacity-50 a:not(.btn) {
  text-decoration: underline;
}

.callout-info-opacity-50 a:not(.btn):hover {
  text-decoration: none;
}

.callout-info-opacity-50 h4 {
  color: #1A202C;
}

.callout-info-opacity-50 hr {
  border-color: rgba(202,223,255,0.78);
}

.callout-info-opacity-75 {
  border-color: rgba(236,243,255,0.97);
  border-left-color: rgba(0,102,255,0.75);
  color: #1A202C;
}

.callout-info-opacity-75 a:not(.btn) {
  text-decoration: underline;
}

.callout-info-opacity-75 a:not(.btn):hover {
  text-decoration: none;
}

.callout-info-opacity-75 h4 {
  color: #1A202C;
}

.callout-info-opacity-75 hr {
  border-color: rgba(173,206,255,0.89);
}

.callout-info-faded {
  border-color: #f9fbff;
  border-left-color: #CCE0FF;
  color: #1A202C;
}

.callout-info-faded a:not(.btn) {
  text-decoration: underline;
}

.callout-info-faded a:not(.btn):hover {
  text-decoration: none;
}

.callout-info-faded h4 {
  color: #1A202C;
}

.callout-info-faded hr {
  border-color: #e9f1ff;
}

.callout-warning-opacity-25 {
  border-color: rgba(255,253,250,0.91);
  border-left-color: rgba(252,161,4,0.25);
  color: #1A202C;
}

.callout-warning-opacity-25 a:not(.btn) {
  text-decoration: underline;
}

.callout-warning-opacity-25 a:not(.btn):hover {
  text-decoration: none;
}

.callout-warning-opacity-25 h4 {
  color: #1A202C;
}

.callout-warning-opacity-25 hr {
  border-color: rgba(255,246,230,0.67);
}

.callout-warning-opacity-50 {
  border-color: rgba(255,251,244,0.94);
  border-left-color: rgba(252,161,4,0.5);
  color: #1A202C;
}

.callout-warning-opacity-50 a:not(.btn) {
  text-decoration: underline;
}

.callout-warning-opacity-50 a:not(.btn):hover {
  text-decoration: none;
}

.callout-warning-opacity-50 h4 {
  color: #1A202C;
}

.callout-warning-opacity-50 hr {
  border-color: rgba(254,235,203,0.78);
}

.callout-warning-opacity-75 {
  border-color: rgba(255,248,236,0.97);
  border-left-color: rgba(252,161,4,0.75);
  color: #1A202C;
}

.callout-warning-opacity-75 a:not(.btn) {
  text-decoration: underline;
}

.callout-warning-opacity-75 a:not(.btn):hover {
  text-decoration: none;
}

.callout-warning-opacity-75 h4 {
  color: #1A202C;
}

.callout-warning-opacity-75 hr {
  border-color: rgba(254,225,175,0.89);
}

.callout-warning-faded {
  border-color: #fffdf9;
  border-left-color: #FEECCD;
  color: #1A202C;
}

.callout-warning-faded a:not(.btn) {
  text-decoration: underline;
}

.callout-warning-faded a:not(.btn):hover {
  text-decoration: none;
}

.callout-warning-faded h4 {
  color: #1A202C;
}

.callout-warning-faded hr {
  border-color: #fff7e9;
}

.callout-danger-opacity-25 {
  border-color: rgba(255,251,251,0.91);
  border-left-color: rgba(244,57,70,0.25);
  color: #1A202C;
}

.callout-danger-opacity-25 a:not(.btn) {
  text-decoration: underline;
}

.callout-danger-opacity-25 a:not(.btn):hover {
  text-decoration: none;
}

.callout-danger-opacity-25 h4 {
  color: #1A202C;
}

.callout-danger-opacity-25 hr {
  border-color: rgba(254,235,236,0.67);
}

.callout-danger-opacity-50 {
  border-color: rgba(255,246,247,0.94);
  border-left-color: rgba(244,57,70,0.5);
  color: #1A202C;
}

.callout-danger-opacity-50 a:not(.btn) {
  text-decoration: underline;
}

.callout-danger-opacity-50 a:not(.btn):hover {
  text-decoration: none;
}

.callout-danger-opacity-50 h4 {
  color: #1A202C;
}

.callout-danger-opacity-50 hr {
  border-color: rgba(253,214,217,0.78);
}

.callout-danger-opacity-75 {
  border-color: rgba(254,240,241,0.97);
  border-left-color: rgba(244,57,70,0.75);
  color: #1A202C;
}

.callout-danger-opacity-75 a:not(.btn) {
  text-decoration: underline;
}

.callout-danger-opacity-75 a:not(.btn):hover {
  text-decoration: none;
}

.callout-danger-opacity-75 h4 {
  color: #1A202C;
}

.callout-danger-opacity-75 hr {
  border-color: rgba(251,192,196,0.89);
}

.callout-danger-faded {
  border-color: #fffafb;
  border-left-color: #FDD7DA;
  color: #1A202C;
}

.callout-danger-faded a:not(.btn) {
  text-decoration: underline;
}

.callout-danger-faded a:not(.btn):hover {
  text-decoration: none;
}

.callout-danger-faded h4 {
  color: #1A202C;
}

.callout-danger-faded hr {
  border-color: #feedef;
}

date-input-polyfill {
  background: #fff;
  color: #000;
  text-shadow: none;
  border: 0;
  padding: 0;
  height: auto;
  width: auto;
  line-height: normal;
  border-radius: 0;
  font-family: sans-serif;
  font-size: 14px;
  position: absolute !important;
  text-align: center;
  box-shadow: 0 7px 8px -4px rgba(0,0,0,0.2),0 12px 17px 2px rgba(0,0,0,0.14),0 5px 22px 4px rgba(0,0,0,0.12);
  cursor: default;
  z-index: 1;
}

date-input-polyfill[data-open="false"] {
  display: none;
}

date-input-polyfill[data-open="true"] {
  display: block;
}

date-input-polyfill select,
date-input-polyfill table,
date-input-polyfill th,
date-input-polyfill td {
  background: #fff;
  color: #000;
  text-shadow: none;
  border: 0;
  padding: 0;
  height: auto;
  width: auto;
  line-height: normal;
  border-radius: 0;
  font-family: sans-serif;
  font-size: 14px;
  box-shadow: none;
}

date-input-polyfill select,
date-input-polyfill button {
  border: 0;
  border-bottom: 1px solid #E0E0E0;
  height: 24px;
  vertical-align: top;
}

date-input-polyfill select {
  width: 50%;
}

date-input-polyfill select:first-of-type {
  border-right: 1px solid #E0E0E0;
  width: 30%;
}

date-input-polyfill button {
  padding: 0;
  width: 20%;
  background: #E0E0E0;
}

date-input-polyfill table {
  border-collapse: collapse;
}

date-input-polyfill th,
date-input-polyfill td {
  width: 32px;
  padding: 4px;
  text-align: center;
}

date-input-polyfill td[data-day] {
  cursor: pointer;
}

date-input-polyfill td[data-day]:hover {
  background: #E0E0E0;
}

date-input-polyfill [data-selected] {
  font-weight: bold;
  background: #D8EAF6;
}

input[data-has-picker]::-ms-clear {
  display: none;
}

.container-password-strength div.strength {
  width: 25%;
  float: left;
  height: 4px;
}

.container-password-strength div.strength>div {
  transition: all 300ms;
  height: 4px;
  margin-left: 1px;
  margin-right: 1px;
}

.activity-timeline .activity-timeline-day .activity-timeline-day-item:last-child hr {
  display: none;
}

.toast {
  z-index: 100;
  position: absolute;
  top: 15px;
  right: 15px;
}

.validation-summary-errors ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.profile-side-bar a.active {
  background-color: #EDF2F7;
}

.account {
  background-color: #f6fafc;
  display: block;
}

button.account {
  cursor: pointer;
  width: 100%;
}

button.account:hover {
  background-color: #fff5e0;
  text-decoration: none;
}

.privacy-policy-wrapper {
  overflow-y: scroll;
  height: 20vh;
}

.privacy-policy-wrapper h2 {
  font-size: 1.2rem;
}

.privacy-policy-wrapper p,
.privacy-policy-wrapper li {
  font-size: 0.8rem;
}

@media (min-width: 992px) {
  .status-messsage-padding {
    padding-top: 80px;
  }
}

#header-navigation.header-navigation_transparent {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-color: transparent;
}

#header-navigation .navigation {
  position: relative;
  z-index: 1025;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  background-color: transparent;
  padding: 1rem;
}

#header-navigation .navigation__container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

#header-navigation .navigation__brand {
  display: inline-block;
  margin-right: 1rem;
  line-height: inherit;
  white-space: nowrap;
}

#header-navigation .navigation__list {
  display: flex;
  flex-direction: row;
  flex-basis: auto;
  align-items: center;
  list-style: none;
  padding-left: 0px;
  margin-bottom: 0px;
}

#header-navigation .navigation__item {
  position: relative;
  display: block;
  padding: 0 1rem;
}

#header-navigation .navigation__item:hover .navigation__dropdown {
  opacity: 1;
  visibility: visible;
}

#header-navigation .navigation__item.active .navigation__dropdown {
  opacity: 1;
  visibility: visible;
}

#header-navigation .navigation__link {
  color: #1A202C;
  text-decoration: none;
  font-size: 16px;
}

#header-navigation .navigation__link:hover {
  color: #1045DB;
}

#header-navigation .navigation__dropdown {
  position: absolute;
  top: 25px;
  transition: opacity .25s ease-in-out;
  opacity: 0;
  visibility: hidden;
}

#header-navigation .navigation__dropdown::before {
  content: '';
  height: 15px;
  width: 100%;
  display: block;
}

#header-navigation .navigation__dropdown_support {
  left: -20px;
  width: 200px;
}

#header-navigation .navigation__dropdown_account {
  left: -140px;
  width: 250px;
}

#header-navigation .navigation__dropdown_login {
  left: -100px;
  width: 215px;
}

#header-navigation .navigation__dropdown_minicart {
  left: -220px;
  width: 280px;
}

#header-navigation .navigation__btn {
  display: block;
  padding: 0rem;
}

#header-navigation .menu {
  position: relative;
  z-index: 1024;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  background-color: rgba(207,218,248,0.06);
  padding: 1rem;
}

#header-navigation .menu__container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

#header-navigation .menu__list {
  display: flex;
  flex-direction: row;
  flex-basis: auto;
  align-items: center;
  list-style: none;
  padding-left: 0px;
  margin-bottom: 0px;
}

#header-navigation .menu__item {
  position: relative;
  display: block;
}

#header-navigation .menu__item:hover .menu__dropdown {
  opacity: 1;
  visibility: visible;
}

#header-navigation .menu__item.active .menu__dropdown {
  opacity: 1;
  visibility: visible;
}

#header-navigation .menu__link {
  color: #1A202C;
  text-decoration: none;
  padding: 1rem;
}

#header-navigation .menu__link:hover {
  color: #1045DB;
}

#header-navigation .menu__dropdown {
  position: absolute;
  top: 25px;
  transition: opacity .25s ease-in-out;
  opacity: 0;
  visibility: hidden;
}

#header-navigation .menu__dropdown::before {
  content: '';
  height: 15px;
  width: 100%;
  display: block;
}

#header-navigation .menu__dropdown_domainnames {
  left: -75px;
  width: 600px;
}

#header-navigation .menu__dropdown_hosting {
  left: 0px;
  width: 850px;
}

#header-navigation .menu__dropdown_email {
  left: -200px;
  width: 700px;
}

#header-navigation .menu__dropdown_managedservices {
  left: -405px;
  width: 1100px;
}

#header-navigation .menu__dropdown_search {
  left: -270px;
  width: 360px;
}

#header-navigation .dropdown {
  background-color: #fff;
  border: 1px solid #E2E8F0;
  border-radius: 0.375rem;
  position: relative;
}

#header-navigation .dropdown__arrow::before {
  content: '';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border: 10px solid transparent;
  border-bottom: 10px solid #E2E8F0;
  top: -20px;
}

#header-navigation .dropdown__arrow::after {
  content: '';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border: 9px solid transparent;
  border-bottom: 9px solid #fff;
  top: -18px;
}

#header-navigation .dropdown__arrow_left::before {
  left: 28px;
}

#header-navigation .dropdown__arrow_left::after {
  left: 29px;
}

#header-navigation .dropdown__arrow_right::before {
  right: 28px;
}

#header-navigation .dropdown__arrow_right::after {
  right: 29px;
}

#header-navigation .dropdown__arrow_hosting::before {
  left: 28px;
}

#header-navigation .dropdown__arrow_hosting::after {
  left: 29px;
}

#header-navigation .dropdown__arrow_domainnames::before {
  left: 145px;
}

#header-navigation .dropdown__arrow_domainnames::after {
  left: 146px;
}

#header-navigation .dropdown__arrow_email::before {
  left: 268px;
}

#header-navigation .dropdown__arrow_email::after {
  left: 269px;
}

#header-navigation .dropdown__arrow_managedservices::before {
  left: 485px;
}

#header-navigation .dropdown__arrow_managedservices::after {
  left: 486px;
}

#header-navigation .dropdown__header {
  background-color: #F7FAFC;
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem;
}

#header-navigation .dropdown__aside {
  background-color: #F7FAFC;
  height: 100%;
}

#header-navigation .dropdown__footer {
  background-color: #F7FAFC;
  border-bottom-left-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}

#header-navigation .dropdown__title {
  font-family: "Poppins",sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 0.875rem;
  color: #2D3748;
  margin-bottom: 0px;
}

#header-navigation .dropdown__subtitle {
  font-family: "Poppins",sans-serif;
  font-weight: bold;
  font-size: 0.875rem;
  color: #2D3748;
  margin-bottom: 0px;
}

#header-navigation .dropdown__link {
  display: block;
  color: #4A5568;
  font-size: 16px;
  line-height: 1.8;
}

#header-navigation .dropdown__link:hover {
  color: #06f;
}

#header-navigation .dropdown__link:hover .dropdown__icon {
  color: #06f;
}

#header-navigation .dropdown__icon {
  font-size: 1.125rem;
  margin-right: 8px;
  line-height: 1.125;
  top: 1px;
  position: relative;
  color: #718096;
}

#header-navigation .minicart__table {
  width: 100%;
  background-color: transparent;
  margin-bottom: 0px;
}

#header-navigation .minicart__thead tr {
  border-bottom: 1px solid #F2F4F6;
}

#header-navigation .minicart__thead tr th {
  font-size: 14px;
  background-color: #F7FAFC;
  padding: 1rem;
}

#header-navigation .minicart__thead tr th:first-child {
  border-top-left-radius: 0.375rem;
}

#header-navigation .minicart__thead tr th:last-child {
  border-top-right-radius: 0.375rem;
}

#header-navigation .minicart__tbody tr {
  border-bottom: 1px solid #F2F4F6;
}

#header-navigation .minicart__tbody tr:nth-of-type(even) {
  background-color: #F7FAFC;
}

#header-navigation .minicart__product {
  font-size: 13px;
  padding: 0.5rem 0.25rem 0.5rem 1rem;
  word-break: break-all;
}

#header-navigation .minicart__price {
  font-size: 13px;
  padding: 0.5rem 1rem 0.5rem 0.25rem;
  text-align: right;
}

#header-navigation .minicart__more {
  font-size: 14px;
  padding: 0.5rem 1rem;
}

#header-mobile-navigation .navigation {
  position: relative;
  z-index: 1025;
  background-color: transparent;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  padding: 1rem 0rem;
}

#header-mobile-navigation .navigation__support {
  position: relative;
  z-index: 1024;
}

#header-mobile-navigation .navigation__container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

#header-mobile-navigation .navigation__brand {
  display: inline-block;
  line-height: inherit;
  white-space: nowrap;
}

#header-mobile-navigation .navigation__list {
  display: flex;
  flex-direction: row;
  flex-basis: auto;
  align-items: center;
  list-style: none;
  padding-left: 0px;
  margin-bottom: 0px;
}

#header-mobile-navigation .navigation__item {
  position: relative;
  display: block;
  padding-top: 0.425rem;
  padding-right: 0.5rem;
  padding-bottom: 0.425rem;
  padding-left: 0.5rem;
}

#header-mobile-navigation .navigation__item:not(.navigation__item_search):hover .navigation__dropdown {
  opacity: 1;
  visibility: visible;
}

#header-mobile-navigation .navigation__item.active .navigation__dropdown {
  opacity: 1;
  visibility: visible;
}

#header-mobile-navigation .navigation__link {
  color: #1A202C;
  text-decoration: none;
}

#header-mobile-navigation .navigation__link:hover {
  color: #051594;
}

#header-mobile-navigation .navigation__btn {
  display: block;
  padding: 0rem;
}

#header-mobile-navigation .menu {
  background-color: #fff;
  border: 1px solid #F7FAFC;
  border-radius: 0.375rem;
}

#header-mobile-navigation .menu__list {
  list-style: none;
  padding-left: 0px;
  margin-bottom: 0px;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

#header-mobile-navigation .menu__item {
  display: block;
  border-bottom: 1px solid #F7FAFC;
}

#header-mobile-navigation .menu__item_search {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

#header-mobile-navigation .menu__link {
  display: block;
  color: #1A202C;
  padding-top: 1rem;
  padding-bottom: 1rem;
  width: 100%;
}

#header-mobile-navigation .menu__link:hover {
  color: #1045DB;
}

#header-mobile-navigation .menu__link:hover .menu__icon {
  color: #1045DB;
}

#header-mobile-navigation .menu__icon {
  font-size: 1.125rem;
  margin-right: 8px;
  line-height: 1.125;
  top: 1px;
  position: relative;
  color: #718096;
}

#header-mobile-navigation .menu__dropdown {
  display: none;
}

#header-mobile-navigation .menu__dropdown-link {
  display: block;
  width: 100%;
}

#header-mobile-navigation .menu__dropdown-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

#header-mobile-navigation .menu__dropdown-toggle {
  cursor: pointer;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-left: 1px solid #F7FAFC;
}

#header-mobile-navigation .dropdown {
  background-color: #F7FAFC;
}

#header-mobile-navigation .dropdown__title {
  font-family: "Poppins",sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 0.875rem;
  color: #2D3748;
  margin-bottom: 0px;
}

#header-mobile-navigation .dropdown__subtitle {
  font-family: "Poppins",sans-serif;
  font-weight: bold;
  font-size: 0.875rem;
  color: #2D3748;
  margin-bottom: 0px;
}

#header-mobile-navigation .dropdown__link {
  display: block;
  color: #4A5568;
  font-size: 16px;
  line-height: 1.8;
}

#header-mobile-navigation .dropdown__link:hover {
  color: #06f;
}

.spinner {
  visibility: hidden;
  display: none;
}

.loading .spinner {
  visibility: visible;
  display: inline-block !important;
}

.btn {
  position: relative;
  transition: all 0.2s ease-in-out;
  font-weight: 500;
}

.btn__sub,
.btn small {
  font-size: 0.875rem;
  font-weight: normal;
}

.btn-link {
  box-shadow: none;
}

.btn-link:hover,
.btn-link:active,
.btn-link:focus {
  box-shadow: none !important;
  transform: none;
}

.btn-inner-icon {
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-link-custom {
  line-height: 20px;
  padding: 10px 25px;
}

.btn-cta {
  color: #fff;
  background-color: #fca104;
  border-color: #fca104;
  box-shadow: 0 4px 6px rgba(74,85,104,0.11),0 1px 3px rgba(0,0,0,0.08);
}

.btn-cta:hover {
  color: #fff;
  background-color: #d78903;
  box-shadow: 0 7px 14px rgba(74,85,104,0.1),0 3px 6px rgba(0,0,0,0.08);
  border-color: #cb8102;
}

.btn-cta:not(:disabled):not(.disabled).active,
.btn-cta:not(:disabled):not(.disabled):active,
.show>.btn-cta.dropdown-toggle {
  color: #fff;
  background-color: #cb8102;
  border-color: #be7902;
}

.btn-cta:not(:disabled):not(.disabled).active:focus,
.btn-cta:not(:disabled):not(.disabled):active:focus,
.show>.btn-cta.dropdown-toggle:focus {
  box-shadow: 0 2px 3px rgba(74,85,104,0.11);
}

.fs-12 {
  font-size: .75rem;
}

.fs-14 {
  font-size: .778rem;
}

.fs-16 {
  font-size: 1rem;
}

.fs-18 {
  font-size: 1.125rem;
}

.fs-20 {
  font-size: 1.25rem;
}

.fs-22 {
  font-size: 1.375rem;
}

@media (max-width: 1200px) {
  .fs-22 {
    font-size: calc(1.2625rem + .15vw);
  }
}

.fs-24 {
  font-size: 1.5rem;
}

@media (max-width: 1200px) {
  .fs-24 {
    font-size: calc(1.275rem + .3vw);
  }
}

.fs-26 {
  font-size: 1.625rem;
}

@media (max-width: 1200px) {
  .fs-26 {
    font-size: calc(1.2875rem + .45vw);
  }
}

.fs-28 {
  font-size: 1.75rem;
}

@media (max-width: 1200px) {
  .fs-28 {
    font-size: calc(1.3rem + .6vw);
  }
}

body {
  background-color: #EDF2F7;
}

body.profile-body {
  background-color: #ffffff;
}

.profile-container {
  background-color: #EDF2F7;
}

footer {
  border-top: 1px solid #e2e8f0;
}

.form-group label {
  color: #718096;
  font-size: 16px;
}

.nav-pills .nav-link {
  padding: .5rem .75rem;
}

.card {
  box-shadow: 0 0 3px 0 rgba(74,85,104,0.1),0 1px 2px 0 rgba(74,85,104,0.1);
  border-color: #E2E8F0 !important;
}

.card .card-header {
  background-color: #F7FAFC !important;
  border-color: #E2E8F0 !important;
}

.card .card-header .card-title {
  font-size: 1.25rem;
  margin-bottom: 0.375rem;
}

.card .card-footer {
  background-color: #F7FAFC !important;
  border-color: #E2E8F0 !important;
}

.bg-gray-200 {
  background-color: #EDF2F7;
}

.btn-sm {
  padding: .35rem .5rem;
  border-radius: .25rem;
}

.text-green {
  color: #2bca50 !important;
}
